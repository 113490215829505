import React, { Component } from 'react'
import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Header from './Header';
import ScrollingHeader from './ScrollingHeader/ScrollingHeader';
import Footer from './Footer'
import './Layout.css'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import API from "../../API"
import { UserContext } from '../../authentication/pages/UserContext';


import Home from '../pages/Home/Home';
import AboutUs from '../pages/AboutUs/AboutUs';
import Shop from '../pages/Shop/Shop';
import Cart from '../pages/Cart/Cart';
import CheckoutAddress from '../pages/CheckoutAddress/CheckoutAddress';
import Orders from '../pages/Orders/Orders';
import Profile from '../pages/Profile/Profile';
import Addresses from '../pages/Addresses/Addresses';
import FAQContact from '../pages/FAQContact/FAQContact';
import Catfish from '../pages/Catfish/Catfish';
import Catmeat from '../pages/Catmeat/Catmeat';
import ProductView from '../pages/ProductView/ProductView';
import CheckoutPayment from '../pages/CheckoutPayment/CheckoutPayment';
import Wishlist from '../pages/Wishlist/Wishlist';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import UpcomingDeliveries from '../pages/UpcomingDeliveries/UpcomingDeliveries';


import { Navigate, useNavigate } from 'react-router-dom';
import Refund from '../pages/RefundForm/RefundForm';


const Layout = () => {
const navigate=useNavigate()
  
  const [showCookieBar, setShowCookieBar] = useState(true)
  const [userData, setUserData] = useState(null);
  const [notificationData, setNotificationData] = useState(null);

  const [navSelected, setNavSelected] = useState(null)

  const [isNotificatioShown, setNotificationShown] = useState(false)

  const [isNotificationSettingsShow, setNotificationSettingsShow] = useState(false)
  const [isSecurityModalShow, setSecurityModalShow] = useState(false)
  
  const [navOpen, setnavOpen] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false)
  const { isLoggedIn, login, logout } = useContext(UserContext);

  const [categorySelected, selectCategory] = useState(null);
  const [subCategorySelected, selectSubCategory] = useState(null);
  const [brandSelected, selectBrand] = useState(null);
  const [tripwiseAvailability, setTripwiseAvailability]=useState(null);
  const [searchKey, setSearchKey] = useState(null);
  
  const [activePage, setActivePage] = useState("home");




  const navLeft = () => {
      setNavSelected(null)

  }
useEffect(()=>{

},[])
  const loadUserData = () => {
      API.get(`/users/${window.localStorage.getItem('userID')}`)
          .then(response => {
              
                      setUserData(response.data)
                 
          })
          .catch(error => {
              console.error(error);
          });


  }

  const loadNotificatiosData = () => {

      API.get(`notifications/${window.localStorage.getItem('userID')}/`)
          .then(response => {
              setNotificationData(response.data)
              console.log("notification data from layout", response.data)
          }
          )

          .catch(error => {
              console.error(error);
          })
  }


  useEffect(() => {
      loadUserData();
      loadNotificatiosData()
  }, [])


 
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      responsive: [{
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    };



    return (
      
      <React.Fragment>
        <div className='w-100 ff-mon pad-100 ' style={{paddingTop:"60px"}}>
          
        <ScrollingHeader></ScrollingHeader>
        <Header userData={userData} isLoggedIn={isLoggedIn} setSearchKey={setSearchKey} selectCategory={selectCategory}></Header>

          <Routes>
          <Route index element={<Navigate to="/home" />} />
          <Route path="home" element={<Home selectCategory={selectCategory} setTripwiseAvailability={setTripwiseAvailability} userData={userData} loadUserData={loadUserData}/>} />
          <Route path="shop" element={<Shop searchKey={searchKey} setSearchKey={setSearchKey} categorySelected={categorySelected} selectCategory={selectCategory} subCategorySelected={subCategorySelected} selectSubCategory={selectSubCategory} brandSelected={brandSelected} selectBrand={selectBrand} tripwiseAvailability={tripwiseAvailability} setTripwiseAvailability={setTripwiseAvailability} loadUserData={loadUserData} userData={userData}/>} />
          <Route path="upcoming-deliveries" element={<UpcomingDeliveries  setTripwiseAvailability={setTripwiseAvailability}/>} />

          <Route path="about-us" element={<AboutUs selectCategory={selectCategory} />} />
          
          {/* <Route path="addcart/:Id" element={<Addcart />} /> */}
          <Route path="view-product" element={<ProductView  loadUserData={loadUserData}/>} />
          <Route path="cart" element={<Cart/>} />
          <Route path="checkout-address" element={<CheckoutAddress />} />
          <Route path="orders" element={<Orders />} />
          <Route path="profile" element={<Profile />} />
          <Route path="addresses" element={<Addresses />} />
          <Route path="sssss" element={<Catfish />} />
          <Route path="catmeat" element={<Catmeat />} />
          <Route path="faq-contact" element={<FAQContact />} />
          <Route path="checkout-payment" element={<CheckoutPayment userEmail={userData && userData.email} />} />
          <Route path="wishlist" element={<Wishlist  loadUserData={loadUserData}/>} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="refund" element={<Refund />} />


          </Routes>
          
          <Footer></Footer>

        </div>

      </React.Fragment>
    )
  }


export default Layout