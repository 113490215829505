import React, { Component } from 'react'
import './Filemanager.css'

import $ from 'jquery';

export class Filemanager extends Component {
  componentDidMount() {
    $("#search").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      $("#table tr").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        return true
      });
    });
  }
  myFunction() {
      var x = document.getElementById("alltypedropdown");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    
  }
  render() {
    return (
      <div className='filemanager'>
        <div className='mb-4 d-flex flex-column flex-lg-row  justify-content-between w-100 relative'>
          <div className='d-flex flex-column flex-lg-row'>
            <div className='relative'>
              <input className='search-inp f-xs fw-600' placeholder='Search' id='search'></input>
              <button className='search-i-position me-3'><i className="fa-solid fa-magnifying-glass"></i></button>
            </div>
            <div className='d-flex  my-3 my-lg-0'>
              <div className='mx-0 mx-md-3 me-2'>
                <input type='date' className='f-xs px-2 py-1 date-inp'></input>
              </div>
              <button onClick={this.myFunction} className='px-2 f-xs alltype'>All type<i className="fa-solid fa-caret-down ms-2 brick-clr"></i></button>
            </div>
            <div className='w-50 p-3 absolute' id='alltypedropdown'>
              <div className='w-100 d-flex mb-2'>
                <div className='filetypeborder px-1 w-50 py-2 me-2'>
                  <img src="/images/Filemanager/xls.svg" alt='' className='fileimgs'></img><span className='fw-500 f-xs ms-3'>Excel</span>
                </div>
                <div className='filetypeborder px-1 w-50 py-2'>
                  <img src="/images/Filemanager/pdf.svg" alt='' className='fileimgs'></img><span className='fw-500 f-xs ms-3'>PDF</span>
                </div>
              </div>
              <div className='w-100 d-flex mb-2'>
                <div className='filetypeborder px-1 w-50 py-2 me-2'>
                  <img src="/images/Filemanager/photoshop.svg" alt='' className='fileimgs'></img><span className='fw-500 ms-3 f-xs'>Photoshop cc</span>
                </div>
                <div className='filetypeborder px-1 w-50 py-2'>
                  <img src="/images/Filemanager/images.svg" alt='' className='fileimgs'></img><span className='fw-500 ms-3 f-xs'>Images</span>
                </div>
              </div>
              <div className='w-100 d-flex mb-2'>
                <div className='filetypeborder px-1 w-50 py-2 me-2'>
                  <img src="/images/Filemanager/illustrator.svg" alt='' className='fileimgs'></img><span className='fw-500 f-xs ms-3'>Illustrator</span>
                </div>
                <div className='filetypeborder px-1 w-50 py-2'>
                  <img src="/images/Filemanager/cdr.svg" alt='' className='fileimgs'></img><span className='fw-500 f-xs ms-3'>Corel draw</span>
                </div>
              </div>
              <div className='d-flex justify-content-end mt-3'>
                <button className='f-xs white-btn me-2 px-4 fw-500'>Clear</button>
                <button className='f-xs lightbrick-btn px-4 fw-500'>Apply</button>
              </div>

            </div>
          </div>
          <button className='lightbrick-btn f-xs px-3'>Upload<i className="fa-solid fa-upload ms-1"></i></button>
          
        </div>

        <div className='w-100 filemanager-table'>
        <table className="rwd-table mb-2 catalog-table">
            <tbody id='table'>
              <tr>
                  <th>File Name</th>
                  <th>Size</th>
                  <th>Type</th>
                  <th>Modified</th>
                  <th>Shared</th>
                  <th>More</th>
              </tr>
              <tr>
                  <td data-th="File Name" className='f-xs'>
                  Meat list
                  </td>
                  <td data-th="Size" className='f-xs'>
                  2.1 GB
                  </td>
                  <td data-th="Type">
                  <img src="/images/Filemanager/word.svg" alt='' className='fileimgs'></img>
                  </td>
                  <td data-th="Modified" className='f-xs'>
                  22 Jan 2023
                  </td>
                  <td data-th="Shared">
                    <button className='f-12 fw-600 round-lightbrick-btn-outline' data-toggle="modal" data-target="#exampleModal">Shared with <i className="fa-solid fa-share"></i></button>
                  </td>
                  <td data-th="More">
                    <button><i className="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i className="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i className="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
              <tr>
                  <td data-th="File Name" className='f-xs'>
                  Meat list
                  </td>
                  <td data-th="Size" className='f-xs'>
                  2.1 GB
                  </td>
                  <td data-th="Type">
                  <img src="/images/Filemanager/pdf.svg"  alt='' className='fileimgs'></img>
                  </td>
                  <td data-th="Modified" className='f-xs'>
                  22 Jan 2023
                  </td>
                  <td data-th="Shared">
                    <button className='f-12 fw-600 round-lightbrick-btn-outline' data-toggle="modal" data-target="#exampleModal">Shared with <i className="fa-solid fa-share"></i></button>
                  </td>
                  <td data-th="More">
                    <button><i className="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i className="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i className="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
              <tr>
                  <td data-th="File Name" className='f-xs'>
                  Meat list
                  </td>
                  <td data-th="Size" className='f-xs'>
                  2.1 GB
                  </td>
                  <td data-th="Type">
                  <img src="/images/Filemanager/xls.svg" alt='' className='fileimgs'></img>
                  </td>
                  <td data-th="Modified" className='f-xs'>
                  22 Jan 2023
                  </td>
                  <td data-th="Shared">
                    <button className='f-12 fw-600 round-lightbrick-btn-outline' data-toggle="modal" data-target="#exampleModal">Shared with <i className="fa-solid fa-share"></i></button>
                  </td>
                  <td data-th="More">
                    <button><i className="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i className="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i className="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
              <tr>
                  <td data-th="File Name" className='f-xs'>
                  Meat list
                  </td>
                  <td data-th="Size" className='f-xs'>
                  2.1 GB
                  </td>
                  <td data-th="Type">
                  <img src="/images/Filemanager/illustrator.svg" alt='' className='fileimgs'></img>
                  </td>
                  <td data-th="Modified" className='f-xs'>
                  22 Jan 2023
                  </td>
                  <td data-th="Shared">
                    <button className='f-12 fw-600 round-lightbrick-btn-outline' data-toggle="modal" data-target="#exampleModal">Shared with <i className="fa-solid fa-share"></i></button>
                  </td>
                  <td data-th="More">
                    <button><i className="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i className="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i className="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
              
           
            
           
             
            </tbody>
          </table> 
        </div>

        {/* Popup Modal */}

<div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
     
      <div className="modal-body">
        <div className='d-flex justify-content-between w-100 mb-3'>
            <div className='f-sm brick-clr fw-600 mb-3'>File share with</div>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i className="fa-regular fa-circle-xmark"></i></span>
            </button>
        </div>
        <div>
          <div className='d-flex justify-content-between align-items-center border-bottom-dashed py-2'>
            <div><img src="/images/userimg.png" alt='' className='sharedpersonimg'></img></div>
            <div>
              <div className='f-xs fw-600'>Lucian Obrien</div>
              <div className='f-xxs clr-565B67'>ashlynn_ohara62@gmail.com</div>
            </div>
            <button className='brick-clr f-md'><i className="fa-solid fa-pen"></i></button>
          </div>
          <div className='d-flex justify-content-between align-items-center border-bottom-dashed py-2'>
            <div><img src="/images/userimg.png" alt='' className='sharedpersonimg'></img></div>
            <div>
              <div className='f-xs fw-600'>Lucian Obrien</div>
              <div className='f-xxs clr-565B67'>ashlynn_ohara62@gmail.com</div>
            </div>
            <button className='brick-clr f-md'><i className="fa-regular fa-eye"></i></button>
          </div>
          <div className='d-flex justify-content-between align-items-center border-bottom-dashed py-2'>
            <div><img src="/images/userimg.png" alt='' className='sharedpersonimg'></img></div>
            <div>
              <div className='f-xs fw-600'>Lucian Obrien</div>
              <div className='f-xxs clr-565B67'>ashlynn_ohara62@gmail.com</div>
            </div>
            <button className='brick-clr f-md'><i className="fa-solid fa-pen"></i></button>
          </div>
        </div>
        <button className='f-xs lightbrick-btn w-100 mt-4'><i className="fa-solid fa-trash-can me-2"></i>Delete</button>
        
      <div>

        </div>
      </div>
    </div>
  </div>
</div>
      </div>
    )
  }
}

export default Filemanager
