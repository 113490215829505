import React from "react";
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

<script src="https://accounts.google.com/gsi/client" async defer></script>

const View = () => {




    return (
        <>
            <div className='auth-container'>

<div className='card auth-alert-page-card'>

                            <div className="body">
                            <Link to={"/sign-up"} className="back-btn"><img src="/images/authentication-screens/back-icon.svg" ></img></Link>
                                <img src="/images/authentication-screens/email-confirmation.svg"></img>
                                <h2 className="title">Check your mail</h2>
                                <span>We have sent an confirmation email </span>
                                

                                <Link to={"/home"}><span>Skip, I’ll confirm later</span></Link>
                                <div className="footer">
                                    <span className="bold-span">Didn’t receive the email ?</span>
                                    <span>Check your spam filter or try another email address</span>
                                </div>
                            </div>
                        </div>
                    </div>


        </>
    )
}

export default View;