import React, { useState, useEffect,useContext} from 'react'

import { useNavigate, Link } from 'react-router-dom'


import API from '../../../API';
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import DeleteConfirmModal from "../../../DeleteConfirmModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner";
import Empty from "../../../Empty"

import Pagination from "../../../Pagination";
import LoginPopup from "../../common-component/LoginPopup/LoginPopup.js"
import { UserContext } from '../../../authentication/pages/UserContext.js';
import $ from 'jquery';
import AddToCartTripSelectionPopup from '../../common-component/AddToCartTripSelectionPopup.js'

import "./Wishlist.css";
const Wishlist = ({loadUserData}) => {
  const { isLoggedIn, login, logout } = useContext(UserContext);

const navigate=useNavigate()

   const [data, setData] = useState(null);

   const [message, setMessage] = useState(null);
   const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
   const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
   const [isDeleteConfModalOpen, setIsDeleteConfModalOpen] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const [page, setPage] = useState(1);
   const[pageSize,selectPageSize]=useState(12);


   const [categories, setCategories] = useState(["", ""])
   const [brands, setBrands] = useState([]);
   const [units, setUnits] = useState();

   
   const [idSelected, setIdSelected] = useState(0);

   const [isLoginPopupShown, showLoginPopup] = useState(false)
   const [isAddToCartTripListPopupShown, showAddToCartTripListPopup] = useState(false);


   useEffect(() => {
      $(function() {
    $(window).scrollTop(0);
    });
    }, []) 

   const loadData = () => {

      API.get(`/wishlist/${window.localStorage.getItem("userID")}/?page_size=${pageSize}&page=${page}`)

         .then(response => {

            setData(response.data)

         }
         )
         .catch(error => {
            console.log(error.message)
         }

         )
   }


   useEffect(()=>{
      loadData();
   }, [page]);

   useEffect(() => {


      API.get(`/categories/`)
         .then(response => {
            setCategories(response.data)


         })
         .catch(error => {
            console.error(error);
         });
         setBrands([])
         API.get(`/brands/`)
            .then(response => {
               setBrands(response.data)
            })
            .catch(error => {
               console.error(error);
            });

      setUnits([])
      API.get(`/units/`)
         .then(response => {
            setUnits(response.data)
         })
         .catch(error => {
            console.error(error);
         });


   }, [])

   

   const RemoveFromWishList = (ID) => {
  
      setIsLoading(true)

      API.post(`/remove-from-wishlist/${localStorage.getItem("userID")}/${ID}/`)
         .then(response => {
            setMessage("Item Removed successfully");
            setIsMessageModalOpen(true)
            setIsLoading(false)
            loadData()
         })
         .catch(error => {
            setMessage(error.message);
            setIsErrorModalOpen(true);
            setIsLoading(false)
         });
  
    
   
  }

  
    return (
      <div>
        <div className='w-100 banner-section wishlist-banner'>
              <div className=''>
                <div className='heading mb-1 mb-lg-3'>
                  <span className='heading'>Satisfy your cravings 
with the<br/> freshest catch and finest cuts 
</span>
                </div>
                <div className='subheading fw-500'>
                Shop your wish listed products now on <span className='brick-clr'>Sibu Fish and Meat!</span>
                 </div>
                <div className='text-center text-md-start'>
                  <button className='lightbrick-btn ff-poppins px-3 f-14 fw-500 mt-3' onClick={() => {navigate('/shop')}}>Explore Now <i className="fa-solid fa-chevron-right f-xxs fw-600"></i></button>
                </div>
              </div>
              <div><img src="/images/wishlist/banner-inner.png" alt='' className='bannerimg'></img></div>

        </div>


        <div className='w-100 radius-11 ps-3'>
          <div className='bg-white py-2 px-3 f-sm fw-600 radius-11 w-100 d-flex justify-content-center my-3'>Wishlist</div>
          <div className="grid-container bestofferlist mb-4">

            {data &&
              data.products.length > 0 && data.products.map((item, index) => {
                return (
                  <div className="grid-item" >
                    <div className='product-card' key={index}>
                <div className='prod-img-bg'>
                  <img src={`https://sibufishnmeat.co.uk${item.images[0].url}`} alt=''  onClick={() => { window.localStorage.setItem("productId", item.id); navigate("/view-product"); window.location.reload() }} role='button'/>
                  <div className='absolute heartposition'>
                 
                            <img src='/images/add-to-wishlist-icon-filled.svg' role='button' onClick={() => RemoveFromWishList(item.id)}></img>
                           
                  </div>
                </div>
                <div className='prod-name'>{item.name}</div>
                {/* <div className='prod-desc'>ബീഫ് ഹൃദയം</div> */}
                <div className='bg-f8f8f8 mt-1 mb-2 py-2'>
                  <div className='brick-clr fw-700 f-14'>£{item.price} <span className=''>(£{Math.round(((item.price / item.quantity) + Number.EPSILON) * 100) / 100}/kg)</span></div>
                </div>
                <div className='d-flex justify-content-center fontsize'>
                  <Link className='lightbrick-btn underline-none px-3 py-1 linkbtn h-0' onClick={() => { if (isLoggedIn) {setIdSelected(item.id); showAddToCartTripListPopup(true) } else { showLoginPopup(true) } }}>Add to bag</Link>
                  <div className='prod-kilo'>{item.quantity}Kg</div>
                </div>
                <div></div>
              </div>
                  </div>

                )
              })}
          </div>

          {data && data.products.length > 1 &&
                     <Pagination
                        totalItems={data.count}
                        pageSize={pageSize}
                        currentPage={page}
                        setCurrentPage={setPage}

                        selectPageSize={selectPageSize}>

                     </Pagination>
                  }
        </div>
      {isLoading && <FixedOverlayLoadingSpinner />}

        <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}
      {isAddToCartTripListPopupShown && <AddToCartTripSelectionPopup productId={idSelected} productCount={1} setterFunction={showAddToCartTripListPopup}  loadUserData={loadUserData}></AddToCartTripSelectionPopup>}

      </div>
    )
  
}

export default Wishlist