import React, { Component, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import API from '../../../API';
import LoadingSpinner from "../../../LoadingSpinner";
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import CancelTripPopup from "../../common-components/CancelTripPopup";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner";
import Pagination from '../../../Pagination'
import { convertTo12HourTime, getCategoryName } from '../../../GeneralFunctions'
import "../../common-components/AdminListPage.css"
import "./RoutesList.css";
import $ from 'jquery';


import ReminderNotificationConfirmPopup from '../../common-components/ReminderNotificationConfirmPopup'
import { fabClasses } from '@mui/material';
import DeleteConfirmModal from '../../../DeleteConfirmModal';
const TodaysDelivery = () => {

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({
    county: null,
    page: 1,
    pageSize: 10,
  });


  const [searchKey, setSearchKey] = useState(null);


  const [counties, setCounties] = useState(null);

  const [idSelected, setIdSelected] = useState(0);
  const [itemSelected, setItemSelected] = useState(null);

  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isDeleteConfModalOpen, setIsDeleteConfModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPostCodesListPopupShown, showPostCodesListPopup] = useState(false)











  useEffect(() => {
    loadTableData();
    getCountyList();
  }, [filters]);


  const loadTableData = () => {
    let apiUrl = `/routes/?page=${filters.page}&page_size=${filters.pageSize}`;
    for (let filter in filters) {
      if (filters[filter] !== null) {
        apiUrl += `&${filter}=${filters[filter]}`;
      }
    }
    setIsLoading(true)
    API.get(apiUrl)
      .then(response => {
        setData(response.data);
        setIsLoading(false)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false)

      });
  }

  const getCountyList = () => {
    setIsLoading(true)
    API.get('/counties/')
      .then(response => {
        setCounties(response.data)
        setIsLoading(false)


      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false)

      });
  }



  const deleteRoute = () => {
    API.delete(`/routes/${idSelected}/`)
      .then(response => {
        setMessage("The Route is Deteted successfully");
        setIsMessageModalOpen(true)
      })
      .catch(error => {
        setMessage(error.message);
        setIsErrorModalOpen(true);
      });
  }


  return (
    <div className='container full-screen-table-container'>
      <div className='row justify-content-between mb-3 d-block d-md-flex'>
        <h3 className=' mb-2 mb-md-0'>Route List </h3>
        <div className='d-flex justify-content-end'>

          <Link to='/admin/routes/add' className='lightbrick-btn f-xs px-4 add-prod-link me-2 create-route-btn'><i className="fa-solid fa-plus me-2"></i>Create Route</Link>
        </div>
      </div>

      <div className='row align-items-start my-3'>

        <div className='d-block d-md-flex align-items-center'>
          <p className='f-sm mb-0 fw-600'>County:&nbsp;</p>
          <select name="cars" id="cars" className='form-select form-select-md f-13 county-filter mb-3 mb-md-0 ' onChange={(e) => setFilters({ ...filters, county: e.target.value })}>

            <option value={""}>All </option>
            {counties && counties.results.length > 0 && counties.results.map((county, index) => {
              return (
                <option value={county.id} key={index}>{county.name}</option>
              )
            })}
          </select>


        </div>
      </div>

      <div className='table-box'>
        <table className='w-100 rwd-table'>
          <thead>
            <tr>
              <th className='fw-600 f-13'>ID</th>
              <th className='fw-600 f-13'>ROUTE NAME</th>
              <th className='fw-600 f-13'>COUNTY</th>
              <th className='fw-600 f-13'>POSTCODES LIST</th>
              <th className='fw-600 f-13'>ACTION</th>
            </tr>
          </thead>
          <tbody id='table'>


            {
              data && data.results && data.results.map((item) => (
                <tr key={item.id} >
                  <td data-th="Id" onClick={() => { }} >{item.id}</td>
                  <td data-th="Route Name">{item.name}</td>
                  <td data-th="County">{item.county_name}</td>
                  <td data-th="PostCodes List" onClick={() => { setItemSelected(item); showPostCodesListPopup(true) }} role='button'><u>{item.postcodes.length} Postcodes</u></td>

                  <td data-th="Action">
                    <div className='action-btn-box'>
                      <button onClick={() => { localStorage.setItem("itemSelectedId", item.id); navigate("/admin/routes/edit") }}><i className="fa-solid fa-pen clr-33363F me-3"></i></button>
                      <button onClick={() => { setIdSelected(item.id); setIsDeleteConfModalOpen(true) }}><img src='/images/admin-panel/table-delete-icon.svg' /></button>

                    </div>
                  </td>
                </tr>
              ))
            }




          </tbody>
        </table>
        <Pagination
          totalItems={data && data.count}
          pageSize={filters.pageSize}
          selectPageSize={(PS) => setFilters({ ...filters, pageSize: PS })}
          currentPage={filters.page}
          setCurrentPage={(CP) => setFilters({ ...filters, page: CP })}>
        </Pagination>
      </div>
      {/* Popup Modal */}

      {isPostCodesListPopupShown &&
        <div className='custom-modal customers-modal'>
          <div className='card'>


            <div className='main-screen'>
              <h1>Postcodes</h1>
              {itemSelected && itemSelected.postcodes.length > 0 && itemSelected.postcodes.map((item, index) => {
                return (
                  <div key={index}>
                    <p>{item}</p>
                  </div>
                );
              })}
              <div className='footer single-button-footer'>
                <button type='button' className='ok-button' onClick={() => showPostCodesListPopup(false)} >Close</button>
              </div>
            </div>
          </div>
        </div>
      }

      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { navigate("/admin/routes/list") }} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { { navigate("/admin/routes/list"); window.location.reload() } }} />}
      {isLoading && <FixedOverlayLoadingSpinner />}
      {isDeleteConfModalOpen && <DeleteConfirmModal resourceName={'Route'} setterFunction={setIsDeleteConfModalOpen} onDeleteFunction={deleteRoute}></DeleteConfirmModal>}
    </div>
  )

}

export default TodaysDelivery