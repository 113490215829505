import React, { Component } from 'react'
import './Topsellingfish.css'

export class Topsellingfish extends Component {
  render() {
    return (
      <div className='py-4 pad-lr-95 bg-f8f8f8'>
        <div className='w-100 d-flex justify-content-between flex-column flex-md-row mb-5'>
          <div className='w-50-resp-mob d-flex justify-content-center relative mb-3 mb-lg-0'>
            <img src="/images/Topselling/topsellingfish.jpg" alt='' className='topsellingfish'></img>
            <div className='absolute text-over-img'>Top Selling -Fish</div>
          </div>
          <div className='w-50-resp-mob d-flex justify-content-center relative'>
            <img src="/images/Topselling/topsellingmeat.webp" alt='' className='topsellingfish'></img>
            <div className='absolute text-over-img'>Top Selling -Meat</div>
          </div>
        </div>

        <div className="grid-container">
          <div className="grid-item">
            <div className='bg-white box-shadow px-3 py-4 radius-7 text-center'>
                <div className='mb-1 relative'>
                <img src="/images/Topselling/topsellingfish2.jpg'" alt='' className='topsellingfish-items'></img>
                <div className='absolute heartposition'><i className="fa-regular fa-heart f-md"></i></div>
                </div>
                <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                <div className='f-xxs clr-565B67 fw-500'>A quality British fish</div>
                <div className='d-flex justify-content-center mb-3 mt-2 align-items-center price-outline'>
                  <div className='bg-darknavy me-2 f-xxs radius-7 p-1 clr-E7E7E7'>2<span>Kg</span></div>
                  <div className='fw-600 f-xs'><i className="fa-solid fa-sterling-sign"></i>12.50</div>
                </div>
                <div>
                  <button className='brick-btn f-12 me-2'>Buy Now</button>
                  <button className='white-btn f-12 me-2'>Add to Cart</button>
                  <button className='clr-33363F f-sm'><i className="fa-solid fa-pen"></i></button>
                </div>
            </div>
          </div>
          <div className="grid-item">
            <div className='bg-white box-shadow px-3 py-4 radius-7 text-center'>
                <div className='mb-1 relative'>
                <img src="/images/Topselling/topsellingfish2.jpg'" alt='' className='topsellingfish-items'></img>
                <div className='absolute heartposition'><i className="fa-regular fa-heart f-md"></i></div>
                </div>
                <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                <div className='f-xxs clr-565B67 fw-500'>A quality British fish</div>
                <div className='d-flex justify-content-center mb-3 mt-2 align-items-center price-outline'>
                  <div className='bg-darknavy me-2 f-xxs radius-7 p-1 clr-E7E7E7'>2<span>Kg</span></div>
                  <div className='fw-600 f-xs'><i className="fa-solid fa-sterling-sign"></i>12.50</div>
                </div>
                <div>
                  <button className='brick-btn f-12 me-2'>Buy Now</button>
                  <button className='white-btn f-12 me-2'>Add to Cart</button>
                  <button className='clr-33363F f-sm'><i className="fa-solid fa-pen"></i></button>
                </div>
            </div>
          </div>
          <div className="grid-item">
            <div className='bg-white box-shadow px-3 py-4 radius-7 text-center'>
                <div className='mb-1 relative'>
                <img src="/images/Topselling/topsellingfish2.jpg'" alt='' className='topsellingfish-items'></img>
                <div className='absolute heartposition'><i className="fa-regular fa-heart f-md"></i></div>
                </div>
                <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                <div className='f-xxs clr-565B67 fw-500'>A quality British fish</div>
                <div className='d-flex justify-content-center mb-3 mt-2 align-items-center price-outline'>
                  <div className='bg-darknavy me-2 f-xxs radius-7 p-1 clr-E7E7E7'>2<span>Kg</span></div>
                  <div className='fw-600 f-xs'><i className="fa-solid fa-sterling-sign"></i>12.50</div>
                </div>
                <div>
                  <button className='brick-btn f-12 me-2'>Buy Now</button>
                  <button className='white-btn f-12 me-2'>Add to Cart</button>
                  <button className='clr-33363F f-sm'><i className="fa-solid fa-pen"></i></button>
                </div>
            </div>
          </div>
          <div className="grid-item">
            <div className='bg-white box-shadow px-3 py-4 radius-7 text-center'>
                <div className='mb-1 relative'>
                <img src="/images/Topselling/topsellingfish2.jpg'" alt='' className='topsellingfish-items'></img>
                <div className='absolute heartposition'><i className="fa-regular fa-heart f-md"></i></div>
                </div>
                <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                <div className='f-xxs clr-565B67 fw-500'>A quality British fish</div>
                <div className='d-flex justify-content-center mb-3 mt-2 align-items-center price-outline'>
                  <div className='bg-darknavy me-2 f-xxs radius-7 p-1 clr-E7E7E7'>2<span>Kg</span></div>
                  <div className='fw-600 f-xs'><i className="fa-solid fa-sterling-sign"></i>12.50</div>
                </div>
                <div>
                  <button className='brick-btn f-12 me-2'>Buy Now</button>
                  <button className='white-btn f-12 me-2'>Add to Cart</button>
                  <button className='clr-33363F f-sm'><i className="fa-solid fa-pen"></i></button>
                </div>
            </div>
          </div>
          <div className="grid-item">
            <div className='bg-white box-shadow px-3 py-4 radius-7 text-center'>
                <div className='mb-1 relative'>
                <img src="/images/Topselling/topsellingfish2.jpg'" alt='' className='topsellingfish-items'></img>
                <div className='absolute heartposition'><i className="fa-regular fa-heart f-md"></i></div>
                </div>
                <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                <div className='f-xxs clr-565B67 fw-500'>A quality British fish</div>
                <div className='d-flex justify-content-center mb-3 mt-2 align-items-center price-outline'>
                  <div className='bg-darknavy me-2 f-xxs radius-7 p-1 clr-E7E7E7'>2<span>Kg</span></div>
                  <div className='fw-600 f-xs'><i className="fa-solid fa-sterling-sign"></i>12.50</div>
                </div>
                <div>
                  <button className='brick-btn f-12 me-2'>Buy Now</button>
                  <button className='white-btn f-12 me-2'>Add to Cart</button>
                  <button className='clr-33363F f-sm'><i className="fa-solid fa-pen"></i></button>
                </div>
            </div>
          </div>
          <div className="grid-item">
            <div className='bg-white box-shadow px-3 py-4 radius-7 text-center'>
                <div className='mb-1 relative'>
                <img src="/images/Topselling/topsellingfish2.jpg'" alt='' className='topsellingfish-items'></img>
                <div className='absolute heartposition'><i className="fa-regular fa-heart f-md"></i></div>
                </div>
                <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                <div className='f-xxs clr-565B67 fw-500'>A quality British fish</div>
                <div className='d-flex justify-content-center mb-3 mt-2 align-items-center price-outline'>
                  <div className='bg-darknavy me-2 f-xxs radius-7 p-1 clr-E7E7E7'>2<span>Kg</span></div>
                  <div className='fw-600 f-xs'><i className="fa-solid fa-sterling-sign"></i>12.50</div>
                </div>
                <div>
                  <button className='brick-btn f-12 me-2'>Buy Now</button>
                  <button className='white-btn f-12 me-2'>Add to Cart</button>
                  <button className='clr-33363F f-sm'><i className="fa-solid fa-pen"></i></button>
                </div>
            </div>
          </div>
          <div className="grid-item">
            <div className='bg-white box-shadow px-3 py-4 radius-7 text-center'>
                <div className='mb-1 relative'>
                <img src="/images/Topselling/topsellingfish2.jpg'" alt='' className='topsellingfish-items'></img>
                <div className='absolute heartposition'><i className="fa-regular fa-heart f-md"></i></div>
                </div>
                <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                <div className='f-xxs clr-565B67 fw-500'>A quality British fish</div>
                <div className='d-flex justify-content-center mb-3 mt-2 align-items-center price-outline'>
                  <div className='bg-darknavy me-2 f-xxs radius-7 p-1 clr-E7E7E7'>2<span>Kg</span></div>
                  <div className='fw-600 f-xs'><i className="fa-solid fa-sterling-sign"></i>12.50</div>
                </div>
                <div>
                  <button className='brick-btn f-12 me-2'>Buy Now</button>
                  <button className='white-btn f-12 me-2'>Add to Cart</button>
                  <button className='clr-33363F f-sm'><i className="fa-solid fa-pen"></i></button>
                </div>
            </div>
          </div>
          <div className="grid-item">
            <div className='bg-white box-shadow px-3 py-4 radius-7 text-center'>
                <div className='mb-1 relative'>
                <img src="/images/Topselling/topsellingfish2.jpg'" alt='' className='topsellingfish-items'></img>
                <div className='absolute heartposition'><i className="fa-regular fa-heart f-md"></i></div>
                </div>
                <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                <div className='f-xxs clr-565B67 fw-500'>A quality British fish</div>
                <div className='d-flex justify-content-center mb-3 mt-2 align-items-center price-outline'>
                  <div className='bg-darknavy me-2 f-xxs radius-7 p-1 clr-E7E7E7'>2<span>Kg</span></div>
                  <div className='fw-600 f-xs'><i className="fa-solid fa-sterling-sign"></i>12.50</div>
                </div>
                <div>
                  <button className='brick-btn f-12 me-2'>Buy Now</button>
                  <button className='white-btn f-12 me-2'>Add to Cart</button>
                  <button className='clr-33363F f-sm'><i className="fa-solid fa-pen"></i></button>
                </div>
            </div>
          </div>
          <div className="grid-item">
            <div className='bg-white box-shadow px-3 py-4 radius-7 text-center'>
                <div className='mb-1 relative'>
                <img src="/images/Topselling/topsellingfish2.jpg'" alt='' className='topsellingfish-items'></img>
                <div className='absolute heartposition'><i className="fa-regular fa-heart f-md"></i></div>
                </div>
                <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                <div className='f-xxs clr-565B67 fw-500'>A quality British fish</div>
                <div className='d-flex justify-content-center mb-3 mt-2 align-items-center price-outline'>
                  <div className='bg-darknavy me-2 f-xxs radius-7 p-1 clr-E7E7E7'>2<span>Kg</span></div>
                  <div className='fw-600 f-xs'><i className="fa-solid fa-sterling-sign"></i>12.50</div>
                </div>
                <div>
                  <button className='brick-btn f-12 me-2'>Buy Now</button>
                  <button className='white-btn f-12 me-2'>Add to Cart</button>
                  <button className='clr-33363F f-sm'><i className="fa-solid fa-pen"></i></button>
                </div>
            </div>
          </div>
          <div className="grid-item">
            <div className='bg-white box-shadow px-3 py-4 radius-7 text-center'>
                <div className='mb-1 relative'>
                <img src="/images/Topselling/topsellingfish2.jpg'" alt='' className='topsellingfish-items'></img>
                <div className='absolute heartposition'><i className="fa-regular fa-heart f-md"></i></div>
                </div>
                <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                <div className='f-xxs clr-565B67 fw-500'>A quality British fish</div>
                <div className='d-flex justify-content-center mb-3 mt-2 align-items-center price-outline'>
                  <div className='bg-darknavy me-2 f-xxs radius-7 p-1 clr-E7E7E7'>2<span>Kg</span></div>
                  <div className='fw-600 f-xs'><i className="fa-solid fa-sterling-sign"></i>12.50</div>
                </div>
                <div>
                  <button className='brick-btn f-12 me-2'>Buy Now</button>
                  <button className='white-btn f-12 me-2'>Add to Cart</button>
                  <button className='clr-33363F f-sm'><i className="fa-solid fa-pen"></i></button>
                </div>
            </div>
          </div>
          <div className="grid-item">
            <div className='bg-white box-shadow px-3 py-4 radius-7 text-center'>
                <div className='mb-1 relative'>
                <img src="/images/Topselling/topsellingfish2.jpg'" alt='' className='topsellingfish-items'></img>
                <div className='absolute heartposition'><i className="fa-regular fa-heart f-md"></i></div>
                </div>
                <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                <div className='f-xxs clr-565B67 fw-500'>A quality British fish</div>
                <div className='d-flex justify-content-center mb-3 mt-2 align-items-center price-outline'>
                  <div className='bg-darknavy me-2 f-xxs radius-7 p-1 clr-E7E7E7'>2<span>Kg</span></div>
                  <div className='fw-600 f-xs'><i className="fa-solid fa-sterling-sign"></i>12.50</div>
                </div>
                <div>
                  <button className='brick-btn f-12 me-2'>Buy Now</button>
                  <button className='white-btn f-12 me-2'>Add to Cart</button>
                  <button className='clr-33363F f-sm'><i className="fa-solid fa-pen"></i></button>
                </div>
            </div>
          </div>
          <div className="grid-item">
            <div className='bg-white box-shadow px-3 py-4 radius-7 text-center'>
                <div className='mb-1 relative'>
                <img src="/images/Topselling/topsellingfish2.jpg'" alt='' className='topsellingfish-items'></img>
                <div className='absolute heartposition'><i className="fa-regular fa-heart f-md"></i></div>
                </div>
                <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                <div className='f-xxs clr-565B67 fw-500'>A quality British fish</div>
                <div className='d-flex justify-content-center mb-3 mt-2 align-items-center price-outline'>
                  <div className='bg-darknavy me-2 f-xxs radius-7 p-1 clr-E7E7E7'>2<span>Kg</span></div>
                  <div className='fw-600 f-xs'><i className="fa-solid fa-sterling-sign"></i>12.50</div>
                </div>
                <div>
                  <button className='brick-btn f-12 me-2'>Buy Now</button>
                  <button className='white-btn f-12 me-2'>Add to Cart</button>
                  <button className='clr-33363F f-sm'><i className="fa-solid fa-pen"></i></button>
                </div>
            </div>
          </div>
          
          
          
        </div>

      </div>
    )
  }
}

export default Topsellingfish

