import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import ErrorModal from "../../ErrorModal";
import FixedOverlayLoadingSpinner from "../../FixedOverlayLoadingSpinner"

const View = () => {

    let navigate = useNavigate();

    const [isActivated, setIsActivated] = useState(false);

    const [message, setMessage] = useState(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        const url = new URL(window.location.href);

        // Get the 'token' parameter from the URL
        const token = url.searchParams.get('token');

        // Now 'token' contains the value of the 'token' parameter from the URL
        console.log('Token:', token);

        setIsLoading(true)
        API.put(`/activate/?token=${token}`)
            .then(response => {
                console.log("response", response);
                setIsLoading(false)
                setIsActivated(true)
                
            }
            )
            
            .catch(error => {
                setMessage("something went wrong, try again")
                // setIsErrorModalOpen(true)
                setIsLoading(false)
                setIsActivated(true)
            }

            )

    }, [])



    return (
        <>
            <div className='auth-container mail-confirmed'>

                {isActivated &&
                    <div className='card auth-alert-page-card'>


                        <div className="body">
                            <img src="/images/app-logo.svg"></img>
                            <h1 className="title">Hurray!</h1>
                            <span className="bold-span">Welcome to our online grocery store!<br></br>
                                Shop fresh, quality groceries from home."</span>
                            <button className="btn btn-secondary" onClick={() => { localStorage.setItem("userRoleRequest", "user"); navigate("/login") }}>Start shopping</button>

                        </div>
                    </div>
                }
            </div>


            <ErrorModal message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { }} />
            {isLoading && <FixedOverlayLoadingSpinner />}
        </>
    )
}

export default View;