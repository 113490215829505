import React, { useState, useEffect } from 'react'
import "react-step-progress-bar/styles.css";
import './CheckoutPayment.css'
import { ProgressBar, Step } from "react-step-progress-bar";
import { useNavigate } from 'react-router-dom'
import API from '../../../API'
import FixedOverlayLoadingSpinner from '../../../FixedOverlayLoadingSpinner'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import OrderPlacedPopup from '../../common-component/OrderPlacedPopup'
import './CheckoutPayment.css'
import $ from 'jquery';

const CheckoutPayment = ({userEmail}) => {
  const navigate = useNavigate()
  const [orderData, setOrderData] = useState(null)
  const [orderPlacedResponse, setOrderPlacedResponse] = useState(null);
  const[isOrderPlacedPopupShown, showOrderPlacedPopup]=useState(false)
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    $(function() {
  $(window).scrollTop(0);
});
}, [])
 useEffect(() => {
    setOrderData(
      {
        order_id: window.localStorage.getItem("createOrderResponse_order_id"),
        total: window.localStorage.getItem("createOrderResponse_total")
      }
    )
  }, [])


  return (
    <>

      {orderData &&
        <div className='checkout'>
          <div className='my-5 mx-0 mx-lg-5 d-flex justify-content-center'>
            <ProgressBar percent={100}>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                  >
                    {index + 1}

                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                  >
                    {index + 1}
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                  >
                    {index + 1}
                  </div>
                )}
              </Step>
            </ProgressBar>
          </div>

          <div className='d-flex flex-column flex-md-row w-100 mt-4'>
            <div className='w-60-resp-mob radius-11 box-shadow p-3 me-0 me-md-3 bg-white mb-3 mb-lg-0'>

              <div className='fw-600 brick-clr f-xs mb-2'>Payment Option</div>
              <div className='fw-500 f-xs mb-2'>Choose an option</div>
              <PayPalScriptProvider options={{ "client-id": "AdGpzWMYWY4YpYCXGs85XRu-ZblJmuStYhxia6WCcy8HM0hMCWw146b_zXBLdxMBksNFclxoYRpq7rE5", currency: "GBP" }}>
                <PayPalButtons
                  style={{
                    layout: "vertical",
                    color: "gold",
                    shape: "rect",
                    label: "pay",
                    tagline: false,
                  }}
                  createOrder={(data, actions) => {
                    return actions.order
                      .create({
                        purchase_units: [
                          {
                            amount: {

                              value: parseFloat(orderData.total
                              )
                            },
                          },
                        ],
                      })
                      .then((orderId) => {
                        // Your code here after create the order
                        return orderId;
                      });
                  }}



                  onApprove={function (data, actions) {
                    return actions.order.capture().then(function () {
                      console.log(data)

                      const bodyData = {
                        payment_reference_number: data.orderID,
                        paypal_payment_type: "1",
                        payment_date: new Date(),
                        payment_method: "0"

                      }

                      let placeurl = ""
                      if (window.localStorage.getItem("buyMode") === "buy-now") {
                        placeurl = `place_order/${orderData.order_id}/${localStorage.getItem("userID")}/?mode=buy_now`
                      }
                      else {
                        placeurl = `place_order/${orderData.order_id}/${localStorage.getItem("userID")}/?mode=cart`
                      }
                      setIsLoading(true)
                      API.put(placeurl, bodyData)

                        .then(response => {


                          setOrderPlacedResponse(response.data);
                          window.localStorage.setItem("selectedItemId", orderData.order_id);
                         showOrderPlacedPopup(true)
                        }
                        )
                        .catch(error => {
                          setIsLoading(false)
                        }

                        )

                    });
                  }}


                >


                </PayPalButtons>
              </PayPalScriptProvider>

             
              <div className='f-xxs clr-898989 my-3 fw-600'>Order confirmation email will be sent to <span className='lightbrick underline'>{userEmail}</span></div>

            </div>



            <div className='w-40-resp-mob radius-11 box-shadow p-3 bg-white'>
              <div className='scootyman fw-500 f-lg d-flex align-items-center bb-dashed'>
                <strong>
                  Ordering with ease,<br></br>
                  delivering with <br></br>
                  <span className='brick-clr'>pleasure</span>
                </strong>
              </div>
              <div className='py-3 px-4 px-md-1 px-lg-5'>
                <div className='mb-2'>


                </div>
                <div className='mb-2'>
                  <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                    <div className='w-50 clr-565B67'>Total</div>
                    <div className='w-50'>£{orderData.total}</div>
                  </div>
                </div>
                <div className='mb-2'>
                  <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                    <div className='w-50 clr-565B67'>Discount</div>
                    <div className='w-50'>No Discount</div>
                  </div>
                </div>
                <div className='mb-2'>
                  <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                    <div className='w-50 clr-565B67'>Convinience Fee</div>
                    <div className='w-50'>Free</div>
                  </div>
                </div>
                <div className='mb-2'>
                  <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                    <div className='w-50 clr-565B67'>Total Amount</div>
                    <div className='w-50 brick-clr'><strong>£{orderData.total}</strong></div>
                  </div>
                </div>
              </div>


              <div className='clr-898989 fw-500 f-xs mt-2 d-flex justify-content-center'>Need help? <span className='underline' onClick={() => navigate('/faq-contact')} role="button">Contact Us</span></div>

            </div>
          </div>
      {isOrderPlacedPopupShown && <OrderPlacedPopup setterFunction={showOrderPlacedPopup}></OrderPlacedPopup>}

        </div>
      }

    </>
  )

}

export default CheckoutPayment
