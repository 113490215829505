import React from "react";
import {  useState } from "react";

import { useNavigate } from 'react-router-dom';

import './SearchSuggestinDropDown.css';
import { useEffect } from "react";
import API from '../../API';


const SearchCategoryMenu = ({list,setSuggestDropDownOpen, setSearchKey}) => {


    const [brands, setBrands] = useState([]);

    let navigate = useNavigate();
    

    useEffect(()=>{
        setBrands([])
        API.get(`/brands/`)
           .then(response => {
              setBrands(response.data)
           })
           .catch(error => {
              console.error(error);
           });
    },[])
    const getBrandName = (brandId) => {
        if (brands && brands.results && brands.results.length > 0) {
           let temp = brands.results.find(x => x.id === brandId);
           if (temp) {
              return (temp.brand_name)
           }
           else {
              return ""
           }
        }
        else {
           return ""
        }
     }
    return (
        <div className="search-seggestion-dropdown">
            <div className="body">
                <ul style={{margin:"0",padding:"15px"}}>

                   {list.map((item, index)=>{
                    console.log(item.name)
                    return(
                        <li key={index} onClick={()=>{setSearchKey(item.name); navigate("/shop"); setSuggestDropDownOpen(false)}} ><img src={item.images[0]&&item.images[0].url&&`https://sibufishnmeat.co.uk${item.images[0].url}`}></img>{item.name} </li>
                    )
                   })}
                    
                </ul>

            </div>

           
        </div>
    )
}

export default SearchCategoryMenu;