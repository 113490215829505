import React, { Component } from 'react'
import './Createpurchase.css'

export class Createpurchase extends Component {
    render() {
        return (
          <div className='createpurchase'>
            <div className='fw-600 f-sm mb-3'>Create Purchase</div>
            <div className='bg-F9F9F9 radius-11 p-3 box-shadow mb-4'>
                <div className='fw-600 f-xs mb-3'>Customer Details</div>
                <div className='region-table mt-3'>
                            <table className="rwd-table mb-2">
                                <tbody>
                                <tr>
                                    <th>Order No</th>
                                    <th>Location</th>
                                    <th>Supplier</th>
                                    <th>Purchase Date</th>
                                    <th>Delivery Date</th>
                                    <th>Order Date</th>
                                    <th>Purchased Cost</th>
                                </tr>
                                <tr>
                                    <td data-th="Order No">
                                    #02A45
                                    </td>
                                    <td data-th="Location">
                                    Manchester
                                    </td>
                                    <td data-th="Supplier">
                                    <input className='f-13 px-2 fw-500 w-100-resp-40-mob inp-EFEFEF' value='Alex Samuel'></input>
                                    </td>
                                    <td data-th="Purchase Date">
                                    <input type='date' className='f-13 w-100-resp-40-mob px-2 fw-500 inp-EFEFEF'></input>
                                    </td>
                                    <td data-th="Delivery Date">
                                    <input type='date' className='f-13 w-100-resp-40-mob px-2 fw-500 inp-EFEFEF'></input>
                                    </td>
                                    <td data-th="Order Date">
                                    23/05/2023
                                    </td>
                                    <td data-th="Purchased Cost">
                                    <input className='f-13 px-2 fw-500 inp-EFEFEF w-100-resp-40-mob' value='£ 50.6'></input>
                                    </td>
                                </tr>
                             
                               
                                </tbody>
                            </table>
                </div>
            </div>
    
            <div className='bg-F9F9F9 radius-11 py-2 box-shadow mb-3'>
                <div className='bb-dashed py-4'>
                    <div className='fw-600 f-xs brick-clr mb-2 px-4'>Product 1</div>
                    <div className='d-flex flex-column flex-lg-row w-100 px-4 mb-2 mb-lg-3'>
                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            <div className='fw-500 f-xs me-2 mb-1'>Product: </div>
                            <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='Seabass'></input>
                        </div>
                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            <div className='fw-500 f-xs mb-1'>Cust Name:  </div>
                            <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='Jack Thomas'></input>
                        </div>
                    </div>
                    <div className='d-flex flex-column flex-lg-row px-4 w-100 mb-2 mb-lg-3'>
                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            <div className='fw-500 f-xs me-2 mb-1'>Ordered: </div>
                            <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='2kg'></input>
                        </div>
                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            <div className='fw-500 f-xs me-2 mb-1'>Available: </div>
                            <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='2kg'></input>
                        </div>
                    </div>
                    <div className='d-flex flex-column flex-lg-row px-4 w-100 mb-2 mb-lg-3'>
                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            <div className='fw-500 f-xs me-2 mb-1'>Price: </div>
                            <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='£ 50.6'></input>
                        </div>
                        <div className='py-2'>
                        <div><span className='fw-600 brick-clr f-xs me-3'>Total Quantity :</span><span className='fw-600 f-xs'>4 Kg</span></div>
                        <div><span className='fw-600 brick-clr f-xs me-3'>Total Amount :</span><span className='fw-600 f-xs'>£ 450.3</span></div>
                    </div>
                    </div>
                    
                    
                </div>
                <div className='bb-dashed py-4'>
                    <div className='fw-600 f-xs brick-clr mb-2 px-4'>Product 2</div>
                    <div className='d-flex flex-column flex-lg-row w-100 px-4 mb-2 mb-lg-3'>
                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            <div className='fw-500 f-xs me-2 mb-1'>Product: </div>
                            <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='Seabass'></input>
                        </div>
                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            <div className='fw-500 f-xs mb-1'>Cust Name:  </div>
                            <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='Jack Thomas'></input>
                        </div>
                    </div>
                    <div className='d-flex flex-column flex-lg-row px-4 w-100 mb-2 mb-lg-3'>
                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            <div className='fw-500 f-xs me-2 mb-1'>Ordered: </div>
                            <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='2kg'></input>
                        </div>
                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            <div className='fw-500 f-xs me-2 mb-1'>Available: </div>
                            <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='2kg'></input>
                        </div>
                    </div>
                    <div className='d-flex flex-column flex-lg-row px-4 w-100 mb-2 mb-lg-3'>
                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            <div className='fw-500 f-xs me-2 mb-1'>Price: </div>
                            <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='£ 50.6'></input>
                        </div>
                        <div className='py-2'>
                        <div><span className='fw-600 brick-clr f-xs me-3'>Total Quantity :</span><span className='fw-600 f-xs'>4 Kg</span></div>
                        <div><span className='fw-600 brick-clr f-xs me-3'>Total Amount :</span><span className='fw-600 f-xs'>£ 450.3</span></div>
                    </div>
                    </div>
                    
                    
                </div>
              
            </div>
    
            <div className='d-flex justify-content-end'>
                <div className='w-25-resp-mob'>
                    <div className='py-3 d-flex text-center w-100'>
                        <button className='lightbrick-btn f-xs w-50 me-2'>Save</button>
                        <button className='white-btn f-xs w-50'>Cancel</button>
                    </div>
                </div>
            </div>
          </div>
        );
      }
}

export default Createpurchase
