import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './FAQContact.css'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import $ from 'jquery';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
export class Faq extends Component {


  show1() {
    var content1 = document.getElementById('faq');
    var content2 = document.getElementById('contactus');
    content1.style.display = "block";
    content2.style.display = "none";

    document.getElementById('tab-btn1').classList.add("active");
    document.getElementById('tab-btn2').classList.remove("active");
  }
  show2() {
    var content1 = document.getElementById('faq');
    var content2 = document.getElementById('contactus');
    content1.style.display = "none";
    content2.style.display = "block";
    document.getElementById('tab-btn2').classList.add("active");
    document.getElementById('tab-btn1').classList.remove("active");
  }
  componentDidMount() {
    $(window).scrollTop(0);
  }

  render() {

    return (
      <div className='userorderpage faq-contact-page'>

        <div className='w-100 banner-section mb-5'>
          <div className=''>
            <div className='heading mb-1 mb-lg-3'>
              <b className='f-40 black-clr'>Discover the best in </b><br></br>
              <span className='brick-clr f-40 ff-carousaly'><b>fresh meats and</b></span><br></br>
              <span className='brick-clr f-40 ff-carousaly' me-2><b>seafood</b></span><span className='f-40 black-clr ms-3'><b>at our shop</b></span>
            </div>
            <div className='subheading fw-500'>
              <div className='f-sm clr-868790 fw-500'>
                Lorem ipsum dolor sit amet. Vel illum maiores hic<br></br> dolore qui reprehenderit odit aut dolores illum.
              </div>
            </div>
          </div>
          <div><img src="/images/wishlist/banner-inner.png" alt='' className='bannerimg'></img></div>

        </div>
        <div className='w-100 d-flex'>
          <div onClick={this.show1} className='w-50 me-3  text-center fw-500 py-2  tab-btn  active' id='tab-btn1'>FAQ</div>
          <div onClick={this.show2} className='w-50 fw-500 text-center py-2  tab-btn' id='tab-btn2'>Contact Us</div>
        </div>
        <div className='w-75-resp-mob mt-4 bg-white box-shadow p-3 radius-7 mx-auto' id='faq'>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                Do you offer fresh fish and meat delivery? 
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className='f-xs clr-33363F'>
                Yes, we specialise in delivering fresh fish and meat directly to your doorstep. Our products are sourced from trusted suppliers to ensure the highest quality and freshness. 
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                Are your products halal? 
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className='f-xs clr-33363F'>
                Yes, we offer a selection of halal products to accommodate diverse dietary preferences and requirements. To identify these products, look for the relevant labels and descriptions on our website. 
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                What regions do you deliver to? 
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className='f-xs clr-33363F'>
                Currently, we offer delivery within the UK. Please check our website for the most up-to-date information on delivery areas. 
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                What is the delivery schedule? 
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className='f-xs clr-33363F'>
                Our delivery schedule varies depending on your location. We strive to offer flexible delivery options to accommodate our customers' needs. During the checkout process, you can select your preferred delivery date and time. 
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                Do you offer any discounts or promotions? 
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className='f-xs clr-33363F'>
                Yes, we frequently offer discounts and promotions to our valued customers. Follow us on social media to stay updated on the latest deals and special offers. 
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                What payment methods do you accept? 
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className='f-xs clr-33363F'>
                We accept various payment methods, including credit/debit cards, PayPal, and other secure online payment options. During the checkout process, you can select your preferred payment method. 
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>

        </div>

        <div className='w-75-resp-mob mt-4 bg-white box-shadow p-3 radius-7 mx-auto' id='contactus'>
          <div className='d-flex justify-content-between bg-FOFOFO p-3  radius-7 mb-2'>
            <div className='fw-500 f-xs'><i className="fa-solid fa-headphones brick-clr f-md me-2"></i>Customer Service</div>
            <Link to={"/"}> <div><i className="fa-solid fa-arrow-up-right-from-square" ></i></div></Link>
          </div>
          <div className='d-flex justify-content-between bg-FOFOFO p-3  radius-7 mb-2'>
            <div className='fw-500 f-xs'><i className="fa-brands fa-facebook-f brick-clr me-2 f-md"></i>Facebook</div>
            <Link to={"https://www.facebook.com/sibufishandmeat"}> <div><i className="fa-solid fa-arrow-up-right-from-square" ></i></div></Link>

          </div>
          {/* <div className='d-flex justify-content-between bg-FOFOFO p-3  radius-7 mb-2'>
            <div className='fw-500 f-xs'><i className="fa-brands fa-whatsapp brick-clr me-2 f-md"></i>Whatsapp</div>
            <Link to={"https://www.instagram.com/sibufishnmeat_uk/"}> <div><i className="fa-solid fa-arrow-up-right-from-square" ></i></div></Link>

          </div> */}
          {/* <div className='d-flex justify-content-between bg-FOFOFO p-3  radius-7 mb-2'>
            <div className='fw-500 f-xs'><i className="fa-brands fa-twitter brick-clr me-2 f-md"></i>Twitter</div>
           <Link to={"https://www.instagram.com/sibufishnmeat_uk/"}> <div><i className="fa-solid fa-arrow-up-right-from-square" ></i></div></Link>
          </div> */}
          <div className='d-flex justify-content-between bg-FOFOFO p-3  radius-7 mb-2'>
            <div className='fw-500 f-xs'><i className="fa-brands fa-instagram brick-clr me-2 f-md"></i>Instagram</div>
            <Link to={"https://www.instagram.com/sibufishnmeat_uk/"}> <div><i className="fa-solid fa-arrow-up-right-from-square" ></i></div></Link>

          </div>
        </div>



        {/* Popup Modal */}

        <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">

              <div className="modal-body bg-F9F9F9 text-center">
                <div className='bg-gradlightbrick m-auto mb-2 w-h-100 radius-50 d-flex justify-content-center align-items-center'><i className="fa-solid fa-cart-shopping fa-3x white-clr"></i></div>
                <div className='fw-500 f-xs'>Are you sure you want send a cancellation request?</div>
                <div className='f-xxs clr-565B67 fw-500'>Your request will be sent to our team</div>
                <button className='f-xs plain-lightbrick-btn w-100 mt-3'>Proceed</button>
                <button className='f-xs lightbrick-btn w-100 mt-2'><i className="fa-solid fa-trash-can me-2"></i>Delete</button>
                <VerticalTimeline>

                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="15:20 PM"
                    iconStyle={{ background: '#E05A67', color: '#fff' }}
                  >
                    <div className="vertical-timeline-element-title f-xxs fw-500">Order in Placed-Feb 14</div>
                    <div className="vertical-timeline-element-subtitle f-xxs clr-898989">32 Manchester Ave Ringgold, GA 30736</div>

                  </VerticalTimelineElement>
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="16:20 PM"
                    iconStyle={{ background: '#E05A67', color: '#fff' }}

                  >
                    <div className="vertical-timeline-element-title f-xxs fw-500">Orders are Transit- Feb 14</div>
                    <div className="vertical-timeline-element-subtitle f-xxs clr-898989">32 Manchester Ave Ringgold, GA 30736</div>
                  </VerticalTimelineElement>
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="11:20 AM"
                    iconStyle={{ background: '#E05A67', color: '#fff' }}

                  >
                    <div className="vertical-timeline-element-title f-xxs fw-500">Order Canceled -Feb 15</div>
                    <div className="vertical-timeline-element-subtitle f-xxs clr-898989">32 Manchester Ave Ringgold, GA 30736</div>
                  </VerticalTimelineElement>


                </VerticalTimeline>
                <div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="trackOrder" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">

              <div className="modal-body radius-11 bg-F9F9F9 text-center">
                <div className='fw-500 f-xs mb-2 bb-dashed pb-2'>Order Status Details</div>
                <VerticalTimeline>

                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date=""
                    iconStyle={{ background: '#E05A67', color: '#fff' }}

                  >
                    <div className="vertical-timeline-element-title f-xxs fw-500">Order in Placed-Feb 14</div>
                    <div className="vertical-timeline-element-subtitle f-xxs clr-898989">32 Manchester Ave Ringgold, GA 30736</div>
                    <div className="vertical-timeline-element-subtitle f-xxs black-clr fw-600">15:20 PM</div>

                  </VerticalTimelineElement>
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date=""
                    iconStyle={{ background: '#E05A67', color: '#fff' }}

                  >
                    <div className="vertical-timeline-element-title f-xxs fw-500">Orders are Transit- Feb 14</div>
                    <div className="vertical-timeline-element-subtitle f-xxs clr-898989">32 Manchester Ave Ringgold, GA 30736</div>
                    <div className="vertical-timeline-element-subtitle f-xxs black-clr fw-600">15:20 PM</div>
                  </VerticalTimelineElement>
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date=""
                    iconStyle={{ background: '#E05A67', color: '#fff' }}

                  >
                    <div className="vertical-timeline-element-title f-xxs fw-500">Order Canceled -Feb 15</div>
                    <div className="vertical-timeline-element-subtitle f-xxs clr-898989">32 Manchester Ave Ringgold, GA 30736</div>
                    <div className="vertical-timeline-element-subtitle f-xxs black-clr fw-600">15:20 PM</div>

                  </VerticalTimelineElement>


                </VerticalTimeline>
                <div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Faq