import React, { Component, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import Cookies from 'js-cookie';
import axios from 'axios';
import API from '../../../API';
import LoadingSpinner from "../../../LoadingSpinner";
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import DeleteConfirmModal from "../../../DeleteConfirmModal";

import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"

import { getCategoryName, getProductName } from '../../../GeneralFunctions';


const Tripbooking = ({ mode }) => {

  const navigate = useNavigate();

  const [data, setData] = useState(
    {
      location: 0,
      delivery_date: "",
      trip_start_time: "",
      trip_end_time: "",
      items: [

      ]
    }
  )
  const [searchKey, setSearchKey] = useState(null);


  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [categorySelected, selectCategory] = useState(null);
  const [subCategorySelected, selectSubCategory] = useState(null);

  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [filteredProducts, setFilteredProducts] = useState(0);

  const [formErrors, setFormErrors] = useState([])
  const [counties, setCounties] = useState(null);
  const [countySelected, selectCounty] = useState(null);
  const [filteredRoutes, setFilteredRoutes] = useState(0);

  
  useEffect(() => {
    setIsLoading(true)
    API.get('/counties')
      .then(response => {
        setCounties(response.data);
        setIsLoading(false)
      })
      .catch(error => {
        setMessage(error.message);
        setIsErrorModalOpen(true);
        setIsLoading(false)
      });
  }, [])

useEffect(()=>{
 
    setIsLoading(true)
    API.get(`/routes/?county=${countySelected}`)
      .then(response => {
        setFilteredRoutes(response.data);
        setIsLoading(false)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false)

      });
}, [countySelected])

  useEffect(() => {

    if (mode === "edit") {
      API.get(`/trips/${localStorage.getItem("itemSelectedId")}/`)
        .then((response) => {
          setData(response.data)
        })
    }
  }, [])

  useEffect(() => {

    API.get('/categories')
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {

        setMessage(error.message);
        setIsErrorModalOpen(true);

      });


  }, [])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const temp = { ...data }
    temp[name] = value
    setData(temp);
  };

  useEffect(() => {

    if (categorySelected) {
      setIsLoading(true)
      API.get(`/subcategories/?category=${categorySelected}`)
        .then(response => {
          setIsLoading(false)
          setSubCategories(response.data);
        })
        .catch(error => {
          setIsLoading(false)
          setMessage(error.message);
          setIsErrorModalOpen(true);
        });
    }
  }, [categorySelected])

  useEffect(() => {
    if (subCategorySelected) {
      setIsLoading(true)
      API.get(`/published-products/?subcategory=${subCategorySelected}`)
        .then(response => {
          setFilteredProducts(response.data);
          setIsLoading(false)

        })
        .catch(error => {
          setIsLoading(false)

          setMessage(error.message);
          setIsErrorModalOpen(true);
        });
    }
  }, [subCategorySelected])


  const validateForm = (data) => {
    const errors = {};

    // Validate each field and add errors if necessary
    if (parseInt(data.location) == 0) {
      errors.location = "Please Choose a Location.";
    }

    if (!data.delivery_date) {
      errors.delivery_date = "Please choose a delivery date.";
    }


    if (!data.trip_start_time) {
      errors.trip_start_time = "Please choose a time.";
    }
    if (!data.trip_end_time) {
      errors.trip_end_time = "Please choose a time.";
    }

    if (!data.delivery_date) {
      errors.delivery_date = "Please choose a delivery date.";
    }
    if (data.items.length == 0) {
      errors.product_list = "Please add atleast one product"
    }


    return errors;
  };
  const handleSubmit = async (e) => {

    const validationErrors = validateForm(data);
    setFormErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {

      e.preventDefault();
      const date = data.delivery_date





      setIsLoading(true);
      API.post('/save_trip/', data)
        .then(response => {

          setMessage("Trip is Created and the Notification is sent to the customers in this Location!")
          setIsMessageModalOpen(true)
          setIsLoading(false)
        })
        .catch(error => {
          setIsLoading(false)
          console.error('Error fetching data:', error);
        });
    }
  }

  const onAddProduct = async (e) => {
    setIsLoading(true)
    let item = {}
    item.productCategory = $('#product_category').val()
    item.productId = $('#product_id').val()
    item.productQuantity = $('#product_quantity').val()
    item.poductPrice = $('#poduct_price').val()

    if (item.productId !== 0 && item.productQuantity && item.poductPrice) {
      var categoryName=""
      if(categories!==null){
        categoryName= getCategoryName(categorySelected,categories)
      }
      // Replace 1 with the actual category ID
      const productName = await getProductName($('#product_id').val());   // Replace 1 with the actual product ID

      var temp = { ...data };

      const obj1 = {

        "products": $('#product_id').val(),
        "day_opening_stock": $('#product_quantity').val(),
        "price": $('#poduct_price').val(),

        "product_name": productName,// not needed for backend. just to display here
        "category_name": categoryName,// not needed for backend. just to display here
      }

      temp.items.push(obj1)

      setData(temp)
      setIsLoading(false)

      //clearing fields
      selectCategory(0)
      selectSubCategory(0)
      $('#product_id').val(0)
      $('#product_quantity').val(null)
      $('#poduct_price').val(null)
    }
    else
      alert("Please fill all entries in correct format")
    setIsLoading(false)
  }
  const onRemoveProduct = (e, index) => {
    var temp = { ...data }
    temp.items.splice(index, 1)
    setData(temp)
  }


  return (
    <div className='m-4 bg-white p-5 radius-7'>
      <div className='f-md text-center mb-3 fw-600'>Product Delivery Booking</div>
      <div className='row justify-content-between w-100 mb-4'>
      <div className='col-12 col-md-6 col-lg-3 mb-3'>
          <div className='f-13 fw-500'>County</div>
          <select className='inp-transparent p-2 f-13 w-100'
            name='county'
            id='county'
            value={data.county && data.county}
            onChange={(e)=>selectCounty(e.target.value)}>
            <option value={0}>Choose..</option>
            {counties&&counties.results.length>0&&counties.results.map((item, index) => {
              return (
                <option value={item.id} key={index}>{item.name}</option>
              )
            })}
          </select>
          {formErrors.location && <div className="text-danger">{formErrors.location}</div>}
        </div>
        <div className='col-12 col-md-6 col-lg-3 mb-3'>
          <div className='f-13 fw-500'>Route</div>
          <select className='inp-transparent p-2 f-13 w-100'
            name='location'
            id='location'
            value={data.location && data.location}
            onChange={handleInputChange}>
            <option value={0}>Choose..</option>
            {filteredRoutes&&filteredRoutes.results.length>0&&filteredRoutes.results.map((item, index) => {
              return (
                <option value={item.id} key={index}>{item.name}</option>
              )
            })}
          </select>
          {formErrors.location && <div className="text-danger">{formErrors.location}</div>}
        </div>
        <div className='col-12 col-md-6 col-lg-3 mb-3'>
          <div className='f-13 fw-500'>Delivery Date</div>
          <input type='date' className='inp-transparent f-13 w-100'
            name='delivery_date'
            value={data.delivery_date}
            onChange={handleInputChange}></input>
          {formErrors.delivery_date && <div className="text-danger">{formErrors.delivery_date}</div>}

        </div>



        <div className='col-12 col-md-6 col-lg-3 mb-3'>
          <div className='f-13 fw-500'>Delivery Start Time</div>
          <input type="time" className='form-control form-control'
            name='trip_start_time'
            value={data.trip_start_time}
            onChange={handleInputChange}></input>
          {formErrors.trip_start_time && <div className="text-danger">{formErrors.trip_start_time}</div>}

        </div>

        <div className='col-12 col-md-6 col-lg-3 mb-3'>
          <div className='f-13 fw-500'>Delivery End Time</div>
          <input type="time" step="60" className='form-control form-control'
            name='trip_end_time'
            value={data.trip_end_time}
            onChange={handleInputChange}></input>
          {formErrors.trip_end_time && <div className="text-danger">{formErrors.trip_end_time}</div>}

        </div>

      </div>



      <div className='f-13 fw-500'>Add Product</div>




      <div className='row mb-1 d-flex'>
        <div className='col-12 col-md-6 col-lg-4 mb-3'>
          <select className='inp-transparent f-13 w-100' id="product_category" name='product_category' value={categorySelected} onChange={(e) => selectCategory(e.target.value)}>

            <option value={0}>Choose Category..</option>
            {categories && categories.results.map((item, index) => {
              return (
                <option value={item.id} key={index}>{item.category_name}</option>
              )
            })}
          </select>

        </div>

        <div className='col-12 col-md-6 col-lg-4 mb-3'>
          <select className='inp-transparent f-13 w-100' id="product_subcategory" name='product_subcategory' value={subCategorySelected} onChange={(e) => selectSubCategory(e.target.value)} disabled={categorySelected === null}>

            <option value={0}>Choose Sub Category..</option>
            {subCategories && subCategories.results.map((item, index) => {
              return (
                <option value={item.id} key={index}>{item.sub_category_name}</option>
              )
            })}
          </select>

        </div>

        <div className='col-12 col-md-6 col-lg-4 mb-3'>
          <select className='inp-transparent f-13 w-100' id="product_id" name='product_id' disabled={subCategorySelected === null}>

            <option value={0}>Choose Product..</option>
            {filteredProducts && filteredProducts.results.length > 0 && filteredProducts.results.map((item, index) => {
              return (
                <option value={item.id} key={index}>{item.name}</option>
              )
            })}
          </select>
        </div>
        <div className='col-12 col-md-6 col-lg-4 mb-3'>
          <div className='d-flex align-items-center'>
            <input className='inp-D9D9D9 w-100 f-13' placeholder='Day Opening Stock' min={0} id="product_quantity" name='product_quantity' type='number' ></input>
            <span className='f-xxs ms-2'>Kg.</span>
          </div>

        </div>
        <div className='col-12 col-md-6 col-lg-4 mb-3'>
          <div className='d-flex align-items-center'>
            <input className='inp-D9D9D9 w-100 f-13' type="number" min={0} placeholder="Price Per Bag" id="poduct_price" name='product_price' />
            <span className='f-xxs ms-2'>Per Bag</span>
          </div> </div>
        <div className='col-12 col-md-6 col-lg-4 mb-3'>
          <button className="btn-add f-xs lightbrick-btn mb-3 w-100" type="button" style={{borderRadius:"30px"}} onClick={onAddProduct}>
            <i className="fa-solid fa-square-plus"></i> Add
          </button>
        </div>
      </div>













      <div className='w-100 product-table'>
        <table className="rwd-table mb-4">
          <tbody id='table'>
            <tr>
              <th>Category</th>
              <th>Product</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Actions</th>
            </tr>

            {data && data.items && data.items.map((item, index) => {
              return (
                <tr key={index}>
                  <td data-th="Product Category">
                    {item.category_name}
                  </td>
                  <td data-th="Product Id">
                    {item.product_name}

                  </td>
                  <td data-th="Product Quantity">
                    {item.day_opening_stock} Kg.
                  </td>
                  <td data-th="Product Price">
                    £ {item.price}
                  </td>
                  <td data-th="Actions">
                    <button className="btn-add brick-clr" type="button" onClick={event => onRemoveProduct(event, index)}>
                      <i className="fa-solid fa-trash"></i> Remove
                    </button>
                  </td>

                </tr>
              )
            })}





          </tbody>
        </table>
        {formErrors.product_list && <div className="text-danger">{formErrors.product_list}</div>}

        {data.length === 0 &&
          <h6 className='mb-3'>
            No products added yet
          </h6>
        }

        <div className='d-flex justify-content-end w-100'>
          <button type='button' onClick={handleSubmit} className='f-xs lightbrick-btn mb-3 px-4 ' style={{height:"50px"}}>Save & Send Notification</button>

        </div>
      </div>


      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { navigate("/admin/trips/list") }} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { navigate("/admin/trips/list") }} />}
      {isLoading && <FixedOverlayLoadingSpinner />}


    </div >
  )

}

export default Tripbooking