import React, { Component, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import API from '../../../API';
import axios from 'axios';
import '../../common-component/ImageUploader.css'
import './RefundForm.css'
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"

const Refund = () => {
	const navigate = useNavigate()
	const [message, setMessage] = useState(null);
	const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
	const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

	const [imageError, setImageError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isImgLoading, setIsImgLoading] = useState(false);

	useEffect(() => {
		$(function () {
			$(window).scrollTop(0);
		});
	}, [])

	const [formData, setFormData] = useState({
		"order_item": "",
		"ticket_type": "refund_request",
		"reason": "",
	});
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const [errors, setErrors] = useState({});


	const validateForm = (data) => {
		const errors = {};
		if (!data.reason.trim()) {
			errors.reason = "Required.";
		}
		if (!data.order_item.trim()) {
			errors.order_item = "Required.";
		}
		return errors;
	};
	const handleSubmit = async (e) => {
		const validationErrors = validateForm(formData);
		setErrors(validationErrors);
		if (Object.keys(validationErrors).length !== 0) {
			return;
		}
		setIsLoading(true);
		API.post('/tickets/', formData)
			.then(response => {
				setMessage("Your request has been submitted!")
				setIsMessageModalOpen(true)
				setIsLoading(false)
			})
			.catch(error => {
				setIsLoading(false);
				setMessage(error.response?error.response.data.message:error.message)
				setIsErrorModalOpen(true);
				console.error('Error fetching data:', error);
			});
	}


	return (
		<div>
			<div className='refund-container'>
				<div className='w-100 brick-clr  heading1'>Important information </div>
				<div className='w-100 heading2'> How do I process a refund for an order placed online? </div>
				<p>
					If you need to request a refund for an item that was part of an online order then you can now do so below. In some instances you may still need to speak to one of our colleagues to complete the refund request.
					<br />
					You'll need to provide your order number, and once we've found your order details we'll need to confirm some other details so please have your order email or paperwork to hand.
				</p>
				<div className='refund-form  radius-11 p-5  mb-lg-0'>
					<div className='brick-clr fw-600 mb-3 f-lg'>Refund request form</div>

					<div className='w-100 d-block d-md-flex '>
						<div className='w-100 me-2 mb-3'>
							<div className='f-15 clr-898989 mb-1'>Order Item ID</div>
							<input id="first_name" placeholder='' value={formData.order_item} name="order_item" className='inp-D9D9D9 f-xs fw-500 w-100' required onChange={handleInputChange} ></input>
							{errors.order_item && <div className="invalid-feedback">{errors.order_item}</div>}
						</div>
					</div>
					<div className='w-100 d-block d-md-flex '>
						<div className='w-100 me-2 mb-3'>
							<div className='f-15 clr-898989 mb-1'>Reason</div>
							<select className="form-select" id="reason" name="reason" onChange={handleInputChange}  >
								<option value={''}>Select</option>
								<option value={'Incorrect items received'}>Incorrect items received</option>
								<option value={'Late delivery'}>Late delivery</option>
								<option value={'Order canceled but still charged'}>Order canceled but still charged</option>
							</select>
							{errors.reason && <div className="invalid-feedback">{errors.reason}</div>}
						</div>
					</div>

					<div className='d-flex justify-content-center mt-3 button-box'>
						<button className='f-xs fw-500 lightbrick-btn px-4 me-3' onClick={handleSubmit} >Submit</button>
					</div>
				</div>
			</div>
			<ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => setIsErrorModalOpen(false)} />
			{isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => window.location.reload()} />}
			{isLoading && <FixedOverlayLoadingSpinner />}
		</div>
	)

}

export default Refund