import React, { Component } from 'react'
import './Location.css'


export class Location extends Component {
  render() {
    return (
      <div className='px-3 px-lg-5 py-4 location'>
        <div className='d-flex w-100 justify-content-between align-items-center mb-4'>
            <div className='black-clr'><b>Location</b></div>
            <div className='d-flex'>
                <button type="button" className='lightbrick-btn f-xs me-2 px-2' data-toggle="modal" data-target="#exampleModal">Add New Location</button>
                <button className='lightbrick-btn px-3'><i className="fa-solid fa-bell"></i></button>
            </div>
        </div>
        <div className='black-clr ff-dmsans f-xs my-3'>MORNING DELIVERY</div>

        <div>
            <div className='box-shadow radius-11 py-3 px-3 px-lg-5 w-100'>
            <button className='w-100 d-flex justify-content-end'><i className="fa-solid fa-pen"></i></button>

            <div className='d-flex align-items-center flex-column-reverse relative flex-lg-row'>

                <div className='w-60-resp-mob'>

                    <div className='brick-clr mb-2 mb-lg-4 text-center text-lg-start'><b>Today’s Delivery</b></div>
                    <div className='w-100 d-flex'>
                        <div className='w-30 f-xs black-clr fw-600'><i className="fa-solid fa-location-dot brick-clr me-2"></i>Location</div>
                        <div className='w-60 clr-898989 f-13 fw-500'>63 East Street Maple beck, NG22 6ND 63 East Street Maple beck, NG22 6ND 63 East Street Maple beck, NG22 6ND </div>
                    </div>
                    <div className='w-100 d-flex my-3'>
                        <div className='w-30 f-xs black-clr fw-600'><i className="fa-solid fa-clock brick-clr me-2"></i>Time</div>
                        <div className='w-60 clr-898989 f-13 fw-500'><span>10:00AM</span><span className='mx-2'>TO</span><span>12:00 PM</span></div>
                    </div>
                    <div className='w-100 d-flex align-items-center'>
                        <div className='w-30 f-xs black-clr fw-600'><i className="fa-solid fa-circle-user brick-clr me-2"></i>Customers</div>
                        <button className='f-12 fw-600 round-lightbrick-btn-outline' data-toggle="modal" data-target="#exampleModalsharedwith">Shared with <i className="fa-solid fa-share"></i></button>
                    </div>


                </div>
                <div className='w-40-resp-mob text-center'>
                    <img src="/images/todsysdelivery.svg" alt='' className='delivery-img mb-2 mb-lg-0' width='250px' ></img>
                </div>
            </div>
            </div>
        </div>
        <div className='black-clr ff-dmsans f-xs my-4'>EVENING DELIVERY</div>
        <div>
            <div className='box-shadow radius-11 py-3 px-3 px-lg-5 w-100'>
            <button className='w-100 d-flex justify-content-end'><i className="fa-solid fa-pen"></i></button>

            <div className='d-flex align-items-center flex-column-reverse relative flex-lg-row'>

                <div className='w-60-resp-mob'>

                    <div className='brick-clr mb-2 mb-lg-4 text-center text-lg-start'><b>Today’s Delivery</b></div>
                    <div className='w-100 d-flex'>
                        <div className='w-30 f-xs black-clr fw-600'><i className="fa-solid fa-location-dot brick-clr me-2"></i>Location</div>
                        <div className='w-60 clr-898989 f-13 fw-500'>63 East Street Maple beck, NG22 6ND 63 East Street Maple beck, NG22 6ND 63 East Street Maple beck, NG22 6ND </div>
                    </div>
                    <div className='w-100 d-flex my-3'>
                        <div className='w-30 f-xs black-clr fw-600'><i className="fa-solid fa-clock brick-clr me-2"></i>Time</div>
                        <div className='w-60 clr-898989 f-13 fw-500'><span>10:00AM</span><span className='mx-2'>TO</span><span>12:00 PM</span></div>
                    </div>
                    <div className='w-100 d-flex align-items-center'>
                        <div className='w-30 f-xs black-clr fw-600'><i className="fa-solid fa-circle-user brick-clr me-2"></i>Customers</div>
                        <button className='f-12 fw-600 round-lightbrick-btn-outline' data-toggle="modal" data-target="#exampleModalsharedwith">Shared with <i className="fa-solid fa-share"></i></button>
                    </div>


                </div>
                <div className='w-40-resp-mob text-center'>
                    <img src="/images/todsysdelivery.svg" alt='' className='delivery-img mb-2 mb-lg-0' width='250px' ></img>
                </div>
            </div>
            </div>
        </div>
        <div className='black-clr ff-dmsans f-xs my-4'>UPCOMING DELIVERY</div>
        <div>
            <div className='box-shadow radius-11 py-3 px-3 px-lg-5 w-100'>
            <button className='w-100 d-flex justify-content-end'><i className="fa-solid fa-pen"></i></button>

            <div className='d-flex align-items-center flex-column-reverse relative flex-lg-row'>

                <div className='w-60-resp-mob'>

                    <div className='brick-clr mb-2 mb-lg-4 text-center text-lg-start'><b>Next Week Delivery</b></div>
                    <div className='w-100 d-flex'>
                        <div className='w-30 f-xs black-clr fw-600'><i className="fa-solid fa-location-dot brick-clr me-2"></i>Location</div>
                        <div className='w-60 clr-898989 f-13 fw-500'>63 East Street Maple beck, NG22 6ND 63 East Street Maple beck, NG22 6ND 63 East Street Maple beck, NG22 6ND </div>
                    </div>
                    <div className='w-100 d-flex my-3'>
                        <div className='w-30 f-xs black-clr fw-600'><i className="fa-solid fa-clock brick-clr me-2"></i>Time</div>
                        <div className='w-60 clr-898989 f-13 fw-500'><span>10:00AM</span><span className='mx-2'>TO</span><span>12:00 PM</span></div>
                    </div>
                    <div className='w-100 d-flex align-items-center'>
                        <div className='w-30 f-xs black-clr fw-600'><i className="fa-solid fa-circle-user brick-clr me-2"></i>Customers</div>
                        <button className='f-12 fw-600 round-lightbrick-btn-outline' data-toggle="modal" data-target="#exampleModalsharedwith">Shared with <i className="fa-solid fa-share"></i></button>
                    </div>


                </div>
                <div className='w-40-resp-mob text-center'>
                    <img src="/images/todsysdelivery.svg" alt='' className='delivery-img mb-2 mb-lg-0' width='250px' ></img>
                </div>
            </div>
            </div>
        </div>


  



{/* Popup Modal */}

<div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
     
      <div className="modal-body">
        <div className='d-flex justify-content-between w-100'>
            <div className='f-sm brick-clr fw-600 mb-3'>New Location</div>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i className="fa-regular fa-circle-xmark"></i></span>
            </button>
        </div>
        <div className='mb-3'>
            <div><i className="fa-solid fa-location-dot brick-clr me-2 mb-2"></i><span className='black-clr fw-500'>Location</span></div>
            <input className='inp-F0F0F0 w-100 p-2'></input>
        </div>
        <div>
            <div><i className="fa-solid fa-clock brick-clr me-2 mb-2"></i><span className='black-clr fw-500'>Time</span></div>
            <input type='time' className='inp-F0F0F0 w-100 p-2'></input>
        </div>
        <button className='f-12 fw-600 round-lightbrick-btn-outline mt-4 px-4' data-toggle="modal" data-target="#exampleModalsharedwith"> Customers List  <i className="fa-solid fa-share"></i></button>

        <div className='d-flex justify-content-end mt-4'>
            <button className='brick-btn px-3 me-2 '>Save</button>
            <button className='white-btn px-3' data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="exampleModalsharedwith" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
     
      <div className="modal-body">
        <div className='d-flex justify-content-between w-100 mb-3'>
            <div className='f-sm brick-clr fw-600 mb-3'>File share with</div>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i className="fa-regular fa-circle-xmark"></i></span>
            </button>
        </div>
        <div>
          <div className='d-flex justify-content-between align-items-center border-bottom-dashed py-2'>
            <div><img src="/images/userimg.png" alt='' className='sharedpersonimg'></img></div>
            <div>
              <div className='f-xs fw-600'>Lucian Obrien</div>
              <div className='f-xxs clr-565B67'>ashlynn_ohara62@gmail.com</div>
            </div>
            <button className='brick-clr f-md'><i className="fa-solid fa-pen"></i></button>
          </div>
          <div className='d-flex justify-content-between align-items-center border-bottom-dashed py-2'>
            <div><img src="/images/userimg.png" alt='' className='sharedpersonimg'></img></div>
            <div>
              <div className='f-xs fw-600'>Lucian Obrien</div>
              <div className='f-xxs clr-565B67'>ashlynn_ohara62@gmail.com</div>
            </div>
            <button className='brick-clr f-md'><i className="fa-regular fa-eye"></i></button>
          </div>
          <div className='d-flex justify-content-between align-items-center border-bottom-dashed py-2'>
            <div><img src="/images/userimg.png" alt='' className='sharedpersonimg'></img></div>
            <div>
              <div className='f-xs fw-600'>Lucian Obrien</div>
              <div className='f-xxs clr-565B67'>ashlynn_ohara62@gmail.com</div>
            </div>
            <button className='brick-clr f-md'><i className="fa-solid fa-pen"></i></button>
          </div>
        </div>
        <button className='f-xs lightbrick-btn w-100 mt-4'><i className="fa-solid fa-trash-can me-2"></i>Delete</button>
        
      <div>

        </div>
      </div>
    </div>
  </div>
</div>

      </div>
    )
  }
}

export default Location