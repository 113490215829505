import React, { Component, useEffect, useState, useContext, useRef } from 'react'
import './Home.css'

import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';

import API from "../../../API"
import LoadingSpinner from "../../../LoadingSpinner";
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import DeleteConfirmModal from "../../../DeleteConfirmModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"
import $ from 'jquery';

import { UserContext } from '../../../authentication/pages/UserContext';
import { Link } from 'react-router-dom'
// import latestdelivery from '../../../../public/images/Userhome/latestdelivery.svg'

import Slider from 'react-slick'
import LocationCollectionPopup from "../../common-component/LocationCollectionPopup"
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import LoginPopup from "../../common-component/LoginPopup/LoginPopup.js"
import HomePageDeliveryPopup from "../../common-component/HomePageDeliveryPopup.js"

import AddToCartTripSelectionPopup from '../../common-component/AddToCartTripSelectionPopup'

const Userhome = ({ selectCategory, setTripwiseAvailability, userData, loadUserData }) => {
  const navigate = useNavigate();

  const scrollerRef = useRef(null);
  const cloneRef = useRef(null);


  const [isLocationPopupShown, showLocationPopup] = useState(false)

  const { isLoggedIn, login, logout } = useContext(UserContext);


  const [categories, setCategories] = useState(null);
  const [todaysTrip, setTodaysTrip] = useState(null);
  const [recentTrips, setRecentTrips] = useState(null);
  const [tabSelected, setTabSelected] = useState([0, null]);

  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [categoriesSectionProducts, setCategoriesSectionProducts] = useState(null);
  const [newArrivals, setNewArrivals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [upcomingTrips, setUpcomingTrips] = useState(null)
  const [isUpcomingDeliveryPopupShown, showUpcomingDeliveryPopup] = useState(false)

  const [isLoginPopupShown, showLoginPopup] = useState(false)

  const [idSelected, setIdSelected] = useState(null);

  const [isAddToCartTripListPopupShown, showAddToCartTripListPopup] = useState(false);
  const [selectedLocation, selectLocation] = useState(null)


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])

  useEffect(() => {
    setTripwiseAvailability(null)
    loadCategories();
    loadTodaysTripData();
    loadRecentTripsData();
    loadNewArrivals()
    loadUpcomingTrips()
  }, [])

  useEffect(() => {
    if (isLoggedIn && userData && userData.location && (userData.location == null)) {
      setTimeout(() => {
        showLocationPopup(true)
      }, 5000)
    }
  }, [isLoggedIn, userData])



  const loadCategories = () => {
    API.get('/categories')
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {

        setMessage(error.message);
        setIsErrorModalOpen(true);

      });
  }
  const loadTodaysTripData = () => {
    API.get(`/trip_today/`)
      .then(response => {
        setTodaysTrip(response.data)
      })
      .catch(error => {
        console.error(error);
      });


  }
  const loadRecentTripsData = () => {
    API.get(`/trips/?page=1&pageSize=10`)
      .then(response => {

        setRecentTrips(response.data)

      })
      .catch(error => {
        console.error(error);
      });


  }


  const loadCategoriesSectionProducts = () => {
    setCategoriesSectionProducts([])
    let url;
    if (tabSelected[1] === null) {
      url = `/published-products/?page=1&page_size=10`
    }
    else {
      url = `/published-products/?page=1&page_size=10&category=${tabSelected[1]}`
    }
    API.get(url)
      .then(response => {
        setCategoriesSectionProducts(response.data)


      })
      .catch(error => {
        console.error(error);
      });
  }

  const loadUpcomingTrips = () => {
    setUpcomingTrips([])
    let url;

    API.get('/upcoming_trips/')
      .then(response => {
        setUpcomingTrips(response.data)
      })
      .catch(error => {
        console.error(error);
      });
  }
  const sendFullMenuCard = () => {

    const email = $("#catelogToEmail").val()

    if (!email.trim()) {
      setMessage("Email is required.")
      setIsErrorModalOpen(true)
    }

    else if (!/\S+@\S+\.\S+/.test(email)) {
      setMessage("Invalid email address.")
      setIsErrorModalOpen(true)

    }

    else {
      setIsLoading(true)
      API.post(`/send-full-menu-card/${email}/`)
        .then(response => {
          setIsLoading(false);
          setMessage("An email with our full catelogue is sent to your Email!")
          setIsMessageModalOpen(true)
        }
        )
        .catch(error => {
          console.log("error")
          setIsLoading(false)
        })
    }
  }



  const loadNewArrivals = () => {
    setNewArrivals([])
    API.get(`/new-arrivals/`)
      .then(response => {
        setNewArrivals(response.data)
      })
      .catch(error => {
        console.error(error);
      });


  }
  useEffect(() => {
    $(".tab-btn").removeClass("active");
    $(".tab-btn").eq(tabSelected[0]).addClass("active");
    loadCategoriesSectionProducts()


  }, [tabSelected])

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [{
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 2,
        initialSlide: 2
      }
    }]
  };

  const upcomingDeliverySliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [{
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 2,
        initialSlide: 2
      }
    }]
  };

  const settings2 = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000
  };


  const addOrRemoveWishList = (ID, isWishlisted) => {
    if (isWishlisted) {
      setIsLoading(true)

      API.post(`/remove-from-wishlist/${localStorage.getItem("userID")}/${ID}/`)
        .then(response => {
          setMessage("Item Removed from your Wishlist successfully");
          setIsMessageModalOpen(true)
          setIsLoading(false)
          loadCategoriesSectionProducts()
          loadRecentTripsData();
          loadNewArrivals()

        })
        .catch(error => {
          setMessage(error.message);
          setIsErrorModalOpen(true);
          setIsLoading(false)
        });

    }
    else {
      if (isLoggedIn) {
        console.log("id from add to wishlist", ID)
        setIsLoading(true)
        API.post(`users/${window.localStorage.getItem("userID")}/products/${ID}/add-to-wishlist/`)
          .then(response => {
            setMessage("Item added to your wishlist successfully");
            setIsMessageModalOpen(true)
            setIsLoading(false)
            loadRecentTripsData();
            loadNewArrivals()
            loadUpcomingTrips();
            loadCategoriesSectionProducts()

          })
          .catch(error => {
            setMessage(error.response.data.message)
            setIsErrorModalOpen(true);
            setIsLoading(false)
          });
      }
      else {
        setMessage("Please login to add your favorite items to your wishlist!!")
        setIsErrorModalOpen(true)
      }
    }
  }


  return (
    <div className='userhome'>


      <div className='mb-5 home-carosal'>
        <Carousel interval={null}>
          <Carousel.Item>
            <div className='w-100 banner-section'>
              <div className=''>
                <div className='heading mb-1 mb-md-2  mb-lg-3'>
                  <span className='brick-clr'><b>Whose doorstep are<br></br> we coming today?</b></span>
                </div>
                <div className='subheading fw-500'>
                  Don't wait; book now and fill your bag if it's your turn!
                </div>
                <div className='location-box'>
                  <div className='black-box'>
                    <p>Today we Deliver at:</p>
                    {todaysTrip && todaysTrip.results.length > 0 ?
                      <div className="scroller-container">
                        <div className="scroller" ref={scrollerRef}>
                          <div>
                            {todaysTrip.results.map((item, index) => (
                              <div className="scroller-item" key={index}>
                                {item.location.location_name}
                              </div>
                            ))}
                          </div>
                          <div ref={cloneRef}>
                            {todaysTrip.results.map((item, index) => (
                              <div className="scroller-item" key={index}>
                                {item.location.location_name}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      // (todaysTrip.results[0].location.location_name) 

                      : "No Delivery Today"}

                  </div>
                  <button className='brick-btn ff-poppins f-14 fw-500' onClick={() => {
                    if (todaysTrip && todaysTrip.results.length == 1) {
                      setTripwiseAvailability(todaysTrip.results[0].trip_id);
                    }
                    navigate('/shop')
                  }}>ORDER&nbsp;NOW<svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.0466 13.3651C14.558 13.8533 14.558 14.6448 15.0466 15.1329C15.5352 15.6211 16.3274 15.6211 16.816 15.1329L23.0535 8.90233C23.5421 8.41418 23.5421 7.62272 23.0535 7.13457L16.7967 0.867108C16.3081 0.378952 15.5159 0.378952 15.0272 0.867108C14.5386 1.35526 14.5386 2.14672 15.0272 2.63487L19.1482 6.76845L2.10158 6.76845C1.44897 6.76845 0.919921 7.3281 0.919921 8.01845C0.919921 8.70881 1.44897 9.26845 2.10158 9.26845L19.1482 9.26845L15.0466 13.3651Z" fill="white" />
                    </svg>
                  </button>
                </div>
              </div>
              <div><img src="/images/carousalmain.png" alt='' className='bannerimg'></img></div>

            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className='w-100 banner-section'>
              <div className=''>
                <div className='heading mb-1 mb-md-2  mb-lg-3'>
                  <span className='brick-clr'><b>Everything Fresh Here </b></span>
                </div>
                <div className='subheading fw-500 d-none d-md-block'>Discover a world of deliciousness with our carefully chosen selection <br />of fresh fish and meat delivered to your doorstep.  </div>
                <div className='subheading fw-500 d-block d-md-none'>Discover a world of deliciousness with our carefully chosen selection of fresh fish and meat delivered to your doorstep.  </div>
                <div className='text-center text-md-start'>
                  <button className='lightbrick-btn ff-poppins px-3 f-14 fw-500 mt-3' onClick={() => { selectCategory(8); navigate('/shop') }}>Explore Now <i className="fa-solid fa-chevron-right f-xxs fw-600"></i></button>
                </div>
              </div>
              <div><img src={"/images/bannerfish.png"} alt='' className='bannerimg'></img></div>

            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className='w-100 banner-section'>
              <div className=''>
                <div className='heading mb-1 mb-md-2 mb-lg-3'>
                  <span className='brick-clr'><b>Exquisite Seafood <br />and Meat Cuts </b></span>
                </div>
                <div className='subheading fw-500 d-none d-md-block'>Discover new culinary delights that will tantalise <br />your taste buds and elevate your dining experience.  </div>
                <div className='subheading fw-500 d-block d-md-none'>Discover new culinary delights that will tantalise your taste buds and elevate your dining experience.  </div>
                <div className='text-center text-md-start'>
                  <button className='lightbrick-btn ff-poppins px-3 f-14 fw-500 mt-3' onClick={() => { selectCategory(2); navigate('/shop') }}>Explore Now <i className="fa-solid fa-chevron-right f-xxs fw-600"></i></button>
                </div>
              </div>
              <div><img src={'../../images/carousalmeat.png'} alt='' className='bannerimg'></img></div>

            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className='w-100 banner-section'>
              <div className=''>
                <div className='heading mb-1 mb-md-2 mb-lg-3'>
                  <span className='brick-clr'><b>Convenient and <br />Reliable Delivery</b></span>
                </div>
                <div className='subheading fw-500 d-none d-md-block'>Expertly handpicked and packaged with<br /> care ensuring freshness every time.</div>
                <div className='subheading fw-500 d-block d-md-none'>Expertly handpicked and packaged with care ensuring freshness every time.</div>
                <div className='text-center text-md-start'>
                  <button className='lightbrick-btn ff-poppins px-3 f-14 fw-500 mt-3' onClick={() => { selectCategory(3); navigate('/shop') }}>Explore Now <i className="fa-solid fa-chevron-right f-xxs fw-600"></i></button>
                </div>
              </div>
              <div><img src={'../../images/carousalprawns.png'} alt='' className='bannerimg'></img></div>

            </div>
          </Carousel.Item>

        </Carousel>
      </div>


      <div className='text-center upcoming-deliveries'>

        <div className='black-clr f-xxl fw-700 my-5 brick-clr'>Upcoming Deliveries</div>


        {upcomingTrips && upcomingTrips.results && upcomingTrips.results.length > 0 &&

          (upcomingTrips.results.length > 4 ?

            <Slider ref={slider => (slider = slider)} {...upcomingDeliverySliderSettings}>

              {upcomingTrips && upcomingTrips.results && upcomingTrips.results.length > 0 && upcomingTrips.results.map((item, index) => {
                return (
                  <div key={index} className={'card p-4 d-flex flex-column align-items-center justify-content-center bg-white mb-4 mb-lg-0'}>
                    <div className='bg-lightbrick location'>
                      <span className="material-symbols-outlined white-clr f-70">
                        distance
                      </span>
                    </div>

                    <div className='fw-500 f-14 black-clr mt-4'>
                      {item.route_name}
                    </div>
                    <div className='fw-500 f-14 black-clr mb-1'>
                      {item.delivery_date}
                    </div>
                    <button className='radius-7 black-btn f-sm px-3 mt-2' onClick={() => { selectLocation(item); showUpcomingDeliveryPopup(true) }}>View</button>
                  </div>
                )
              })}

            </Slider>
            :
            <div className='d-flex flex-column flex-md-row justify-content-around align-items-center w-100'>
              {upcomingTrips && upcomingTrips.results && upcomingTrips.results.length > 0 && upcomingTrips.results.map((item, index) => {
                return (
                  <div key={index} className={'card p-4 d-flex flex-column align-items-center justify-content-center bg-white mb-4 mb-lg-0'}>
                    <div className='bg-lightbrick location'>
                      <span className="material-symbols-outlined white-clr f-70">
                        distance
                      </span>
                    </div>

                    <div className='fw-500 f-14 black-clr mt-4'>
                      {item.route_name}
                    </div>
                    <div className='fw-500 f-14 black-clr mb-1'>
                      {item.delivery_date}
                    </div>
                    <button className='radius-7 black-btn f-sm px-3 mt-2' onClick={() => { selectLocation(item); showUpcomingDeliveryPopup(true) }}>View</button>
                  </div>
                )
              })}
            </div>

          )
        }
        <div className='d-md-flex justify-content-center w-100 mt-5'>
          <button className='bg-lightbrick  radius-7 px-3 py-2 white-clr fw-600 f-md' onClick={() => navigate("/upcoming-deliveries")}>
            See All Upcoming Deliveries
            <svg className="svg-icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" style={{ height: '25px', marginLeft: "10px" }}>
              <path d="M469.34016 170.65984q17.67424 0 30.0032 12.32896l298.65984 298.65984q12.67712 12.67712 12.67712 30.33088t-12.67712 30.33088l-298.65984 298.65984q-12.32896 12.32896-30.0032 12.32896t-30.16704-12.4928-12.4928-30.16704q0-18.00192 12.32896-30.33088l268.32896-268.32896-268.32896-268.32896q-12.32896-12.32896-12.32896-30.33088 0-18.3296 12.16512-30.49472t30.49472-12.16512zM213.34016 170.65984q17.67424 0 30.0032 12.32896l298.65984 298.65984q12.67712 12.67712 12.67712 30.33088t-12.67712 30.33088l-298.65984 298.65984q-12.32896 12.32896-30.0032 12.32896t-30.16704-12.4928-12.4928-30.16704q0-18.00192 12.32896-30.33088l268.32896-268.32896-268.32896-268.32896q-12.32896-12.32896-12.32896-30.33088 0-18.3296 12.16512-30.49472t30.49472-12.16512z" style={{ fill: 'white' }} />
            </svg>
          </button>
        </div>
      </div>

      <div className='d-flex flex-column flex-lg-row w-100 bestfeatures'>

        <div className='d-flex w-50-resp-tab mb-3 mb-lg-0'>
          <div className='box-shadow w-100 px-4 py-5 radius-7 d-flex bg-white justify-content-center align-items-center me-3 flex-column text-center flex-lg-row text-lg-start box'>
            <div className='mb-2 mb-lg-0'><img src="/images/Userhome/fastdelivery.svg" alt=''></img></div>
            <div className='ms-2 black-clr fw-600 f-md text'>
              <div>Fast Delivery</div>
              <div>Start from <i className="fa-solid fa-sterling-sign"></i>10</div>
            </div>
          </div>
          <div className='box-shadow w-100 px-4 py-5 radius-7 d-flex bg-white justify-content-center align-items-center me-0 me-lg-3 flex-column flex-lg-row text-center text-lg-start box'>
            <div className='mb-2 mb-lg-0'><img src="/images/Userhome/money.svg" alt=''></img></div>
            <div className='ms-2 black-clr fw-600 f-md text'>
              <div>7 Day</div>
              <div>Money-Back Guarantee</div>
            </div>
          </div>
        </div>

        <div className='d-flex w-50-resp-tab'>
          <div className='box-shadow w-100 px-4 py-5 radius-7 d-flex bg-white justify-content-center align-items-center me-3 flex-column flex-lg-row text-center text-lg-start box'>
            <div className='mb-2 mb-lg-0'><img src="/images/Userhome/time.svg" alt=''></img></div>
            <div className='ms-2 black-clr fw-600 f-md text'>
              <div>2 Days</div>
              <div>For Free Return</div>
            </div>
          </div>
          <div className='box-shadow w-100 px-4 py-5 radius-7 bg-white d-flex justify-content-center align-items-center flex-column flex-lg-row text-center text-lg-start box'>
            <div className='mb-2 mb-lg-0'><img src="/images/Userhome/payment.svg" alt=''></img></div>
            <div className='ms-2 black-clr fw-600 f-md text'>
              <div >Secure</div>
              <div>Payment System</div>
            </div>
          </div>
        </div>

      </div>

      {categories && categories.results.length > 2 &&

        <div className='ourbestdeal my-5'>
          <div className='f-xxl text-center black-clr fw-700 mb-3'>Products Categories</div>
          <div className='my-4 text-center d-flex flex-column flex-md-row justify-content-center'>


            <button className={0 == tabSelected[0] ? 'round-lightbrick-btn-outline f-xs px-4 me-2 mb-3 mb-lg-0 active' : 'round-lightbrick-btn-outline f-md px-4 me-2 mb-3 mb-lg-0'} onClick={(e) => setTabSelected([0, null])}>All</button>

            {categories.results.map((item, index) => {
              return (
                <button className={(index + 1) == tabSelected[0] ? 'round-lightbrick-btn-outline f-md px-4 me-2 mb-3 mb-lg-0 active' : 'round-lightbrick-btn-outline f-md px-4 me-2 mb-3 mb-lg-0'} onClick={(e) => setTabSelected([index + 1, categories.results[index].id])}>{item.category_name}</button>

              )
            })}
          </div>




        </div>

      }

      {categoriesSectionProducts && categoriesSectionProducts.results && categoriesSectionProducts.results.length > 5 &&
        <>
          <Slider ref={slider => (slider = slider)} {...settings} className='categories-slider' >

            {categoriesSectionProducts.results.map((item, index) => {
              return (
                <div className='product-card' key={index}>
                  <div className='prod-img-bg'>
                    <img src={`https://sibufishnmeat.co.uk${item.images[0].url}`} alt='' onClick={() => { window.localStorage.setItem("productId", item.id); navigate("/view-product"); window.location.reload() }} role='button' />
                    <div className='absolute heartposition'>
                      {item.is_wishlisted ?
                        <img src='/images/add-to-wishlist-icon-filled.svg' role='button' onClick={() => addOrRemoveWishList(item.id, item.is_wishlisted)}></img>
                        :
                        <img src='/images/add-to-wishlist-icon.svg' role='button' onClick={() => addOrRemoveWishList(item.id, item.is_wishlisted)} ></img>
                      }
                    </div>

                    <div className='absolute halal'>
                      {item.halal &&
                        <img src='/images/halal-logo.svg' role='button'></img>
                      }
                    </div>
                  </div>
                  <div className='prod-name'>{item.name}</div>
                  {/* <div className='prod-desc'>ബീഫ് ഹൃദയം</div> */}
                  <div className='bg-f8f8f8 mt-1 mb-3 py-2'>
                    <div className='brick-clr fw-700 f-14'>£{item.price} <span className=''>(£{Math.round(((item.price / item.quantity) + Number.EPSILON) * 100) / 100}/kg)</span></div>
                  </div>
                  <div className='d-flex justify-content-center fontsize'>
                    <Link className='lightbrick-btn underline-none px-3 py-1 linkbtn ' onClick={() => { if (isLoggedIn) { setIdSelected(item.id); showAddToCartTripListPopup(true) } else { showLoginPopup(true) } }}>Add to bag</Link>
                    <div className='prod-kilo'>{item.quantity}Kg</div>
                  </div>
                  <div></div>
                </div>
              )
            })}

          </Slider>
          <Slider ref={slider => (slider = slider)} {...settings}>

            {categoriesSectionProducts.results.slice().reverse().map((item, index) => {
              return (
                <div className='product-card' key={index}>
                  <div className='prod-img-bg'>
                    <img src={`https://sibufishnmeat.co.uk${item.images[0].url}`} alt='' onClick={() => { window.localStorage.setItem("productId", item.id); navigate("/view-product"); window.location.reload() }} role='button' />
                    <div className='absolute heartposition'>
                      {item.is_wishlisted ?
                        <img src='/images/add-to-wishlist-icon-filled.svg' role='button' onClick={() => addOrRemoveWishList(item.id, item.is_wishlisted)}></img>
                        :
                        <img src='/images/add-to-wishlist-icon.svg' role='button' onClick={() => addOrRemoveWishList(item.id, item.is_wishlisted)} ></img>
                      }
                    </div>

                    <div className='absolute halal'>
                      {item.halal &&
                        <img src='/images/halal-logo.svg' role='button'></img>
                      }
                    </div>
                  </div>
                  <div className='prod-name'>{item.name}</div>
                  {/* <div className='prod-desc'>ബീഫ് ഹൃദയം</div> */}
                  <div className='bg-f8f8f8 mt-1 mb-3 py-2'>
                    <div className='brick-clr fw-700 f-14'>£{item.price} <span className=''>(£{Math.round(((item.price / item.quantity) + Number.EPSILON) * 100) / 100}/kg)</span></div>
                  </div>
                  <div className='d-flex justify-content-center fontsize'>
                    <Link className='lightbrick-btn underline-none px-3 py-1 linkbtn ' onClick={() => { if (isLoggedIn) { setIdSelected(item.id); showAddToCartTripListPopup(true) } else { showLoginPopup(true) } }}>Add to bag</Link>
                    <div className='prod-kilo'>{item.quantity}Kg</div>
                  </div>
                  <div></div>
                </div>
              )
            })}

          </Slider>
        </>



      }

      {categoriesSectionProducts && categoriesSectionProducts.results && categoriesSectionProducts.results.length <= 5 &&
        <div className='low-item-count-message'>
          Not enough items to display under this category
        </div>
      }
      {/* --------- why choose us start ----------- */}

      <div className='bg-F7EBEC d-none d-md-flex radius-11 align-items-center relative pl-110 whychooseus'>
        <div>
          <div className='black-clr f-xl f-md-xxl mb-3'><b>Why Choose Us?</b></div>
          <div className='clr-898989 f-md mb-4'>
            With our commitment to freshness, convenience, and<br /> exceptional service, we are your trusted choice for a reliable<br />fish and meat delivery experience.
          </div>
          <button className='round-lightbrick-btn-outline solid px-3 py-2 f-14 underline-none' onClick={() => navigate('/about-us')} role='button'>Learn more</button>

        </div>
        <div className='absolute whychooseuspositionimg'>
          <img src="/images/Userhome/whychooseus.png" alt='' className='whychooseusimg'></img>
        </div>
      </div>

      <div className='bg-F7EBEC d-block d-md-none text-center pb-5 pt-3 radius-11 d-flex flex-column relative align-items-center pl-110 whychooseus'>
        <div className=''>
          <img src="/images/Userhome/whychooseus.png" alt='' className='whychooseusimg'></img>
        </div>
        <div>
          <div className='black-clr f-xl f-md-xxl mb-3 fw-700'><b>Why You Choose Us</b></div>
          <div className='clr-898989 f-xs mb-4 px-3'>
            Savor the taste of quality and freshness in <br></br>
            every bite at our meat and fish shop. <br></br>
            Choose us for unparalleled taste and selection
          </div>
          <button className='round-lightbrick-btn-outline solid px-3 py-2 f-14 underline-none' onClick={() => navigate('/about-us')} role='button'>Learn More</button>

        </div>
      </div>

      {/* --------- why choose us end ------------ */}


      <div className='text-center weeklyspecial pt-4'>
        <div className='black-clr f-xxl fw-700 mb-5'>New Arrivals</div>


        <div className='w-100 pb-4 scroll-outer-box'>
          <div className='scroll-box'>
            {newArrivals && newArrivals.length > 0 && newArrivals.map((item, index) => {
              return (
                <div className='product-card' key={index}>
                  <div className='prod-img-bg'>
                    <img src={`https://sibufishnmeat.co.uk${item.images[0].url}`} alt='' onClick={() => { window.localStorage.setItem("productId", item.id); navigate("/view-product"); window.location.reload() }} role='button' />
                    <div className='absolute heartposition'>
                      {item.is_wishlisted ?
                        <img src='/images/add-to-wishlist-icon-filled.svg' role='button' onClick={() => addOrRemoveWishList(item.id, item.is_wishlisted)}></img>
                        :
                        <img src='/images/add-to-wishlist-icon.svg' role='button' onClick={() => addOrRemoveWishList(item.id, item.is_wishlisted)} ></img>
                      }
                    </div>
                  </div>
                  <div className='prod-name'>{item.name}</div>
                  {/* <div className='prod-desc'>ബീഫ് ഹൃദയം</div> */}
                  <div className='bg-f8f8f8 mt-1 mb-2 py-2'>
                    <div className='brick-clr fw-700 f-14'>£{item.price} <span className=''>(£{Math.round(((item.price / item.quantity) + Number.EPSILON) * 100) / 100}/kg)</span></div>
                  </div>
                  <div className='d-flex justify-content-center fontsize'>
                    <Link className='lightbrick-btn underline-none px-3 py-1 linkbtn ' onClick={() => { if (isLoggedIn) { { setIdSelected(item.id); showAddToCartTripListPopup(true) } } else { showLoginPopup(true) } }}>Add to bag</Link>
                    <div className='prod-kilo'>{item.quantity}Kg</div>
                  </div>
                  <div></div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className='userreview text-center my-1 my-lg-5'>
        <div className='black-clr f-xxl fw-700 mb-5'>What Our Customers Say About Us</div>

        <Slider className='' ref={slider => (slider = slider)} {...settings2}>
          <div className='bg-brick radius-11 p-3 p-lg-5'>
            <div className=' d-flex radius-35 justify-content-center flex-column align-items-center py-5 px-0 px-lg-3'>
              <div><img src="/images/userimg2.png" alt='' className=''></img></div>
              <div className='fw-600 white-clr f-xl mt-2'>Mia Evans</div>
              <div className='d-flex mt-1'>
                <button><i className="fa-solid fa-star gold-clr"></i></button>
                <button><i className="fa-solid fa-star gold-clr"></i></button>
                <button><i className="fa-solid fa-star gold-clr"></i></button>
                <button><i className="fa-solid fa-star gold-clr"></i></button>
                <button><i className="fa-regular fa-star gold-clr"></i></button>
              </div>
              <div className='w-75 text-center f-md py-3 px-0 px-lg-3 radius-11 white-clr'>
                I've been using this fish and meat delivery service for months now, and I couldn't be happier with the quality and freshness of their products. They always deliver on time, and the packaging is perfect. Highly recommend!
              </div>
            </div>
          </div>

          <div className='bg-brick radius-11 p-3 p-lg-5'>
            <div className=' d-flex radius-35 justify-content-center flex-column align-items-center py-5 px-0 px-lg-3'>
              <div><img src="/images/userimg2.png" alt='' className=''></img></div>
              <div className='fw-600 white-clr f-xl mt-2'>Louis Warren </div>
              <div className='d-flex mt-1'>
                <button><i className="fa-solid fa-star gold-clr"></i></button>
                <button><i className="fa-solid fa-star gold-clr"></i></button>
                <button><i className="fa-solid fa-star gold-clr"></i></button>
                <button><i className="fa-solid fa-star gold-clr"></i></button>
                <button><i className="fa-regular fa-star gold-clr"></i></button>
              </div>
              <div className='w-75 text-center f-md  py-3 px-0 px-lg-3 radius-11 white-clr'>
                As a busy professional, I don't always have the time to go to the market and pick out the freshest fish and meat. That's why I turned to Sibu fish and meat, and I'm so glad I did. The food arrived promptly and was packed with care. And best of all, it tasted amazing! I highly recommend this service to anyone looking for high-quality fish and meat.
              </div>
            </div>
          </div>

          <div className='bg-brick radius-11 p-3 p-lg-5'>
            <div className=' d-flex radius-35 justify-content-center flex-column align-items-center py-5 px-0 px-lg-3'>
              <div><img src="/images/userimg2.png" alt='' className=''></img></div>
              <div className='fw-600 white-clr f-xl mt-2'>Rebeca Price </div>
              <div className='d-flex mt-1'>
                <button><i className="fa-solid fa-star gold-clr"></i></button>
                <button><i className="fa-solid fa-star gold-clr"></i></button>
                <button><i className="fa-solid fa-star gold-clr"></i></button>
                <button><i className="fa-solid fa-star gold-clr"></i></button>
                <button><i className="fa-regular fa-star gold-clr"></i></button>
              </div>
              <div className='w-75 text-center f-md  py-3 px-0 px-lg-3 radius-11 white-clr'>
                I was hesitant to try a meat and fish delivery service, but after giving this one a chance, I'm never going back to the grocery store. The seafood was some of the freshest I've ever tasted. Plus, the customer service was top-notch. I had some questions about my order, and they were quick to respond and very helpful. Can't recommend this service enough!
              </div>
            </div>
          </div>
        </Slider>

      </div>







      <div className='bg-F7EBEC my-5 px-3 px-lg-0 radius-11 bg-keepintouch w-100'>
        <div className='fw-600 black-clr f-xxl'>See Our Full Product Menu</div>
        <div className='clr-868790 f-md fw-500 text-start text-md-center'>Enter your email address, and we will send the product menu to your email</div>
        <div className='mt-3 w-50-resp-mob text-center relative'>
          <input className='w-100 radius-7' id='catelogToEmail' placeholder='Enter your email'></input>
          <button className='absolute lightbrick-btn f-md px-4' onClick={sendFullMenuCard}>Send</button>
        </div>
      </div>
      {isLoading && <FixedOverlayLoadingSpinner />}

      {isAddToCartTripListPopupShown && <AddToCartTripSelectionPopup productId={idSelected} productCount={1} setterFunction={showAddToCartTripListPopup} loadUserData={loadUserData}></AddToCartTripSelectionPopup>}
      {isLoginPopupShown && <LoginPopup redirectUrl={"/"} showLoginPopup={showLoginPopup}></LoginPopup>}
      {isUpcomingDeliveryPopupShown && <HomePageDeliveryPopup redirectUrl={"/"} setterFunction={showUpcomingDeliveryPopup} selectedLocation={selectedLocation} setTripwiseAvailability={setTripwiseAvailability}></HomePageDeliveryPopup>}

      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

      {isLocationPopupShown && <LocationCollectionPopup setterFunction={showLocationPopup}></LocationCollectionPopup>}
    </div>

  );


}

export default Userhome