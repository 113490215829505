import React, { Component } from 'react'
import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from './Navbar'
import './Layout.css';
import Header from './Header';

import { UserContext } from '../../authentication/pages/UserContext';
import { useNavigate } from 'react-router-dom';

import API from '../../API';
import { Navigate } from 'react-router-dom';

import Profile from '../pages/Profile/Profile'
import Overview from '../pages/Overview/Overview';
import Location from '../pages/Location/Location';
import ProductsList from '../pages/ProductsList/ProductsList';
import Addproduct from '../pages/ProductAdd/ProductAdd';
import CustomersList from '../pages/CustomersList/CustomersList';
import CustomerDetails from '../pages/CustomerDetails/CustomerDetails';
import Coupon from '../pages/Coupon/Coupon';
import InvoiceList from '../pages/InvoiceList/InvoiceList';
//import InvoicesAdd from '../pages/Newinvoice/Newinvoice';
import InvoiceDetails from '../pages/InvoiceDetails/InvoiceDetails';
import Category from '../pages/Category/Category';
import Subcategory from '../pages/Subcategory/Subcategory';
import TripList from '../pages/TripList/TripList';
import TripAdd from '../pages/TripAdd/TripAdd';

import RoutesList from '../pages/RoutesList/RoutesList';
import RouteAdd from '../pages/RouteAdd/RouteAdd';

import Adminsettings from '../pages/Adminsettings/Adminsettings';
import OrdersList from '../pages/OrdersList/OrdersList';
// import Orderdetails from '../pages/Orderdetails/Orderdetails';
import OrderAdd from '../pages/OrderAdd/OrderAdd';
import Error from '../pages/Error/Error';
import Filemanager from '../pages/Filemanager/Filemanager';
import Analytics from '../pages/Analytics/Analytics';
import Ecommerce from '../pages/Ecommerce/Ecommerce';
import Maintanance from '../pages/Maintanance/Maintanance';
import Purchase from '../pages/Purchase/Purchase';
import TicketsList from '../pages/TicketsList/TicketsList';
import Addcoupon from '../pages/Addcoupon/Addcoupon';
import Createpurchase from '../pages/Createpurchase/Createpurchase';
import Topsellingfish from '../pages/Topsellingfish/Topsellingfish';
import Notification from '../pages/Notification/Notification';
import Notificationhistory from '../pages/Notificationhistory/Notificationhistory';
import ReviewList from '../pages/ReviewList/ReviewList';

const Layout = () => {


  let navigate = useNavigate();


  const [userData, setUserData] = useState(null);
  const [notificationData, setNotificationData] = useState();

  const [isNotificationSettingsShow, setNotificationSettingsShow] = useState(false)
  const [isSecurityModalShow, setSecurityModalShow] = useState(false)
  const [isLogoutModalShow, setLogoutModalShow] = useState(false)


  const submitNotification = () => {
    setNotificationSettingsShow(false)
  }




  const [navOpen, setnavOpen] = useState(false);

  const [profileMenuOpen, setProfileMenuOpen] = useState(false)
  const { isLoggedIn, login, logout } = useContext(UserContext);

  const toggleLeftNav = () => {
    setnavOpen(!navOpen)
  }


  const loadUserData = () => {
    API.get(`/users/${window.localStorage.getItem('userID')}`)
      .then(response => {

        setUserData(response.data)
        console.log("userdata from adminlayout", userData)
      })

      .catch(error => {
        console.error(error);
      });


  }

  const loadNotificatiosData = () => {
    API.get(`notifications/${window.localStorage.getItem('userID')}/`)
      .then(response => {
        setNotificationData(response.data)
      }
      )

      .catch(error => {
        console.error(error);
      })
  }


  useEffect(() => {
    if (localStorage.getItem("userRole") === "admin") {
      loadUserData();
    }
    loadNotificatiosData()
  }, [])



  const myFunction = () => {
    document.getElementsByClassName("navbar-left")[0].classList.toggle("open");
  }




  return (
    <React.Fragment>
      <div className='bg-white resp-toolbar  d-block d-lg-none d-flex'>
        <div>
          <img src="/images/brick-logo.svg" alt=''></img>
        </div>
        <button onClick={myFunction} className='white-btn px-3 hamburger-btn'><i className="fa-solid f-lg fa-bars"></i></button>

      </div>
      <div className='w-100 d-flex ff-mon'>


        <div className=' navbar-left bg-darknavy div1'>
          {/* <button onClick={() => handleClick()}>Click Me</button> */}
          <Navbar></Navbar>
        </div>
        <div className='content_wrpr d-flex flex-column'>
          <Header userData={userData} isLoggedIn={isLoggedIn}></Header>

          {isLoggedIn && (window.localStorage.getItem("userRole") === "admin") ?
            <div className='w-100 d-flex flex-column py-4 px-2 px-lg-4'>




              <Routes>
                <Route index element={<Overview />} />
                
                <Route path="profile" element={<Profile />} />
                
                <Route path="trips/list" element={<TripList />} />
                <Route path="trips/add" element={<TripAdd />} />
                <Route path="trips/edit" element={<TripAdd mode={"edit"} />} />
                
                <Route path="routes/list" element={<RoutesList />} />
                <Route path="routes/add" element={<RouteAdd />} />
                <Route path="routes/edit" element={<RouteAdd mode={"edit"} />} />

                <Route path="products/list" element={<ProductsList />} />
                <Route path="category" element={<Category />} />
                <Route path="subcategory" element={<Subcategory />} />
                <Route path="products/add" element={<Addproduct mode={"add"} />} />
                <Route path="products/edit" element={<Addproduct mode={"edit"} />} />
                <Route path="customers/list" element={<CustomersList />} />
                <Route path="customers/details" element={<CustomerDetails />} />
                
                {/* <Route path="orderdetails" element={<Orderdetails />} /> */}
                <Route path="coupon" element={<Coupon />} />
                <Route path="invoice/list" element={<InvoiceList />} />
                <Route path="invoice/details" element={<InvoiceDetails />} />
                <Route path="topxellingfish" element={<Topsellingfish />} />
                <Route path="adminsettings" element={<Adminsettings />} />
                
                <Route path="orders/list" element={<OrdersList />} />
                <Route path="orders/add" element={<OrderAdd mode={"add"} />} />
                <Route path="orders/edit" element={<OrderAdd mode={"edit"} />} />

                <Route path="reviews/list" element={<ReviewList />} />


                <Route path="error" element={<Error />} />
                <Route path="maintanance" element={<Maintanance />} />
                <Route path="filemanager" element={<Filemanager />} />
                <Route path="analytics" element={<Analytics />} />
                <Route path="ecommerce" element={<Ecommerce />} />

                <Route path="createsales" element={<OrderAdd />} />
                <Route path="purchase" element={<Purchase />} />
                <Route path="createpurchase" element={<Createpurchase />} />
                <Route path="addcoupon" element={<Addcoupon />} />
                <Route path="notification" element={<Notification />} />
                <Route path="notificationhistory" element={<Notificationhistory />} />
                <Route path="tickets/list" element={<TicketsList />} />
                
              </Routes>
            </div>
            :
            <div className="auth-mask">
              <p>Please login as Admin to continue</p>
            </div>
          }

        </div>
      </div>

    </React.Fragment>
  )

}

export default Layout