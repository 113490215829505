import React from "react";
import { useEffect, useState } from "react";

import { useNavigate } from 'react-router-dom';


import API from '../../../API';
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";

import $, { error } from 'jquery';
import { getCategoryName, getProductName } from '../../../GeneralFunctions';
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"

import "./OrderAdd.css"
import LoadingSpinner from "../../../LoadingSpinner";

const OrderAdd = ({ mode }) => {

    let navigate = useNavigate();


    const [data, setData] = useState(
        {
            id: null,
            user: null,
            trip: null,
            delivery_status: 1,
            payment_status: false,
            total_quantity: 0,
            total_price: 0,
            products: []                  //   {
            //     id: null,
            //     quantity: null,
            //     price: null
            //     },
        }
    );


    const [message, setMessage] = useState(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [categories, setCategories] = useState(null);
    const [subCategories, setSubCategories] = useState(null);

    const [liveTripsList, setLiveTripsList] = useState(null)

    const [postCodeEntered, setPostcode] = useState(null);
    const [postcodefiteredCustomers, setPostcodefiteredCustomers] = useState(null);
    const [productList, setProductList] = useState(null)

    const [selectedProduct, selectProduct] = useState(null)

    const [countEntered, setCountEntered] = useState(null)
  const [formErrors, setFormErrors] = useState([])
  
  //order status code

    //canceled -0 
    // preparing for dispatched - 1
    // on the way - 2
    // out for delivery- 3
    // delivered - 4
    // returned - 5
    const orderStatusList =
        [
            { value: 0, "label": "Cancelled", "description": "Your item has been cancelled.", "icon": "/images/orders/canceled-icon.svg" },
            { value: 1, "label": "Preparing For Dispatch", "description": "Your item is being prepaired for dispatch.", "icon": "/images/orders/preparing-for-dispatch-icon.svg" },
            { value: 2, "label": "On The Way", "description": "Your item is on the way.", "icon": "/images/orders/on-the-way-icon.svg" },
            { value: 3, "label": "Out For Delivery", "description": "Your item has been delivered.", "icon": "/images/orders/delivered-icon.svg" },
            { value: 4, "label": "Delivered", "description": "Your item has been delivered.", "icon": "/images/orders/delivered-icon.svg" },
            { value: 5, "label": "Returned", "description": "Your item has been returned to the seller.", "icon": "/images/orders/returned-icon.svg" },
        ]


    const paymentMethodList = [
        { value: 0, label: "Paypal" },
        { value: 1, label: "Card Payment" },
        { value: 2, label: "Cash On Delivery" },
        { value: 3, label: "manual" }

    ]

    const paymentStatusList = [
        { value: true, label: "Paid" },
        { value: false, label: "Pending" },

    ]

    useEffect(() => {
        $(function () {
            $(window).scrollTop(0);
        });
    }, [])
    useEffect(() => {

        API.get('/categories')
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error(error);
            });


        


      

        if (mode == "edit") {
            API.get(`/orders/${localStorage.getItem("itemSelectedId")}`)
                .then(response => {
                    setData(response.data);
                    postCodeEntered(response.data.postcode)
                })
                .catch(error => {
                    console.error(error);
                });

        }
    }, [])

    useEffect(() => {
        validateForm(data);
        API.get(`/get_customer_from_postcode/${postCodeEntered}`)
            .then(response => {
                setPostcodefiteredCustomers(response.data);
            })
            .catch(error => {
                console.error(error);
            });

    }, [postCodeEntered])



    useEffect(() => {
        let url=`/published-products/`;
        if(data.trip!=0){
            url=`/published-products/?trip=${data.trip}`
        }
        API.get(url)
        .then(response => {
            setProductList(response.data)



        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });


if(data&&data.user){

        API.get(`/customer_specific_live_trip/${data.user}`)
            .then(response => {
                setLiveTripsList(response.data);
            })
            .catch(error => {
                setMessage(error.message);
                setIsErrorModalOpen(true);
            });

        }
    }, [data])





    const onFieldChange = (e, key) => {
        console.log("e.target.value,key", e.target.value, key)
        var temp = { ...data };
        temp.order[key] = e.target.value;


    }



    useEffect((item, index) => {
        console.log("selectedProduct", selectedProduct)
    }, [selectedProduct])





    const onAddProduct = async (e) => {
        setIsLoading(true)
        let item = {}
        item.productId = $('#product_id').val()
        item.productCount = $('#product_count').val()
        console.log("item.productId", item.productId)
        console.log("item.productCount", item.productCount)

        // if(data.products.filter(item))
        if (item.productId !== 0 && item.productCount != 0) {


            // Replace 1 with the actual category ID
            const productName = await getProductName($('#product_id').val());   // Replace 1 with the actual product ID

            var temp = { ...data };

            const obj1 = {

                "id": $('#product_id').val(),
                "product_name": selectedProduct && selectedProduct.name,
                "count": $('#product_count').val(),// not needed for backend. just to display here
                "quantity": selectedProduct && selectedProduct.quantity,
                "price": (selectedProduct && selectedProduct.price),
                "sub_total": (selectedProduct && selectedProduct.price) * parseInt(countEntered ? countEntered : 0),
            }
            console.log("temp", temp)

            temp.products.push(obj1);

            temp.total_quantity = temp.total_quantity + (selectedProduct && parseInt(selectedProduct.quantity))
            temp.total_price = temp.total_price + ((selectedProduct && selectedProduct.price) * parseInt(countEntered ? countEntered : 0))

            setData(temp)
            selectProduct({ id: null })
            setCountEntered(0)
            setIsLoading(false)

        }
        else
            alert("Please fill all entries")
        setIsLoading(false)
    }


    const onRemoveProduct = (e, index) => {
        var temp = { ...data }
        temp.total_price = temp.total_price - parseInt(temp.products[index].price)
        temp.total_quantity = temp.total_quantity - parseInt(temp.products[index].quantity)
        temp.products.splice(index, 1)

        setData(temp)
    }


    const validateForm = (data) => {
        const errors = {};
    
        if (postCodeEntered==null) {
            errors.postcode = "Postcode is required.";
        } else if (postCodeEntered.includes(' ')) {
            errors.postcode = "Postcode should not contain spaces.";
        }
    console.log(errors)
    setFormErrors(errors);
      };


    const handleSubmit = (event) => {
       
        
        validateForm(data);
    
    if (Object.keys(formErrors).length === 0) {

      event.preventDefault();
        setIsLoading(true);

        if (mode == "edit") {
            let temp = {
                "delivery_status": data.delivery_status,
                "payment_status": data.payment_status
            }
            API.put(`/order/${localStorage.getItem("itemSelectedId")}/`, temp)
                .then(response => {
                    // Handle the response

                    setIsLoading(false);
                    window.localStorage.setItem("itemSelectedId", null)
                    setMessage("Order saved successfully");
                    setIsMessageModalOpen(true)
                })
                .catch(error => {
                    // Handle the error
                    setMessage('Error saving Order:', error);
                    setIsLoading(false);
                    setIsErrorModalOpen(true)
                });
        }

        else {
            API.post(`/order/`, data)
                .then(response => {
                    // Handle the response

                    setIsLoading(false);
                    window.localStorage.setItem("itemSelectedId", null)
                    setMessage("Order saved successfully");
                    setIsMessageModalOpen(true)
                })
                .catch(error => {
                    // Handle the error
                    setMessage('Error saving Order:', error);
                    setIsLoading(false);
                    setIsErrorModalOpen(true)
                });
        }

    }
    };



    return (
        <div className='createsales'>
            <div className='fw-600 f-sm mb-3'>{mode == "edit" ? "Edit" : "Create"} Order </div>
            <div className="mb-3">{mode == "edit" ? `Order Id : ${data.id} ` : ""}</div>
            <div className='bg-F9F9F9 radius-11 p-3 box-shadow mb-4'>
                <div className='fw-600 f-xs mb-3'>Customer Details</div>
                <div className='region-table mt-3'>
                    <table className="rwd-table mb-2">
                        <tbody>
                            <tr>
                                <th>Post Code</th>
                                <th>Customer</th>
                                <th>Trip Date</th>
                                <th>Delivery Status</th>
                                <th>Payment Status</th>
                            </tr>
                            <tr>
                                <td data-th="Post Code">
                                    <input className='f-13 px-2 fw-500 inp-EFEFEF' disabled={mode == "edit"} value={data.postcode} onChange={(e) => { setPostcode(e.target.value) }}></input>
                                    {formErrors.postcode&&<div className="text-danger">{formErrors.postcode}</div>}
                                </td>

                                <td data-th="Customer">
                                    <select name="cars" id="cars" className='f-13 px-2 fw-500 inp-EFEFEF' disabled={mode == "edit"} onChange={(e) => { setData({ ...data, "user": e.target.value }) }} >
                                        <option value={null}>Select</option>

                                        {postcodefiteredCustomers && postcodefiteredCustomers.results.length > 0 && postcodefiteredCustomers.results.map((item, index) => {
                                            return (
                                                <option value={item.id} title={item.first_name + item.last_name + item.address_line1 + item.phonenumber}>{item.first_name} {item.last_name}</option>

                                            )
                                        })}
                                    </select>
                                </td>

                                <td data-th="Trip Date">
                                    <select name="cars" id="cars" className='f-13 px-2 fw-500 inp-EFEFEF' disabled={mode == "edit"} onChange={(e) => { setData({ ...data, "trip": e.target.value }) }} >
                                        <option value={null}>Select</option>

                                        {liveTripsList && liveTripsList.results.length > 0 && liveTripsList.results.map((item, index) => {
                                            return (
                                                <option value={item.trip_id} > {item.label}</option>

                                            )
                                        })}
                                    </select>
                                </td>


                                <td data-th="Delivery Status">
                                    <select name="cars" id="cars" className='f-13 px-2 fw-500 inp-EFEFEF' value={data.delivery_status} onChange={(e) => { setData({ ...data, "delivery_status": e.target.value }) }} disabled={mode == "add"}>
                                        <option value={null}>Select</option>

                                        {orderStatusList.map((item, index) => {
                                            return (
                                                <option value={item.value}>{item.label}</option>

                                            )
                                        })}
                                    </select>
                                </td>

                                <td data-th="Payment Status">
                                    <select name="cars" id="cars" className='f-13 px-2 fw-500 inp-EFEFEF' onChange={(e) => { setData({ ...data, "payment_status": e.target.value }) }} >
                                        <option value={null}>Select</option>
                                        <option value={true}>Paid</option>
                                        <option value={false}>Unpaid</option>


                                    </select>
                                </td>
                            </tr>


                        </tbody>
                    </table>
                </div>
            </div>

            <div className='bg-F9F9F9 radius-11 py-2 box-shadow mb-3'>
                <div className='d-flex flex-column flex-lg-row w-100 px-4 mb-3 bb-dashed py-2'>
                    <div className='fw-600 f-xs brick-clr mb-2 px-4'>Trip</div>

                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs me-2 mb-1'>Product: </div>
                    </div>

                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs mb-1'>Qty. Per Bag: </div>
                    </div>
                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs me-2 mb-1'>No. of Units: </div>
                    </div>
                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs me-2 mb-1'>Price: </div>
                    </div>
                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs me-2 mb-1'>Sub Total: </div>
                    </div>
                    {mode !== "edit" &&
                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            <div className='fw-500 f-xs me-2 mb-1'>Action: </div>
                        </div>
                    }
                </div>
                {data && data.products && data.products.length > 0 && data.products.map((item, index) => {
                    return (
                        <div className='d-flex flex-column flex-lg-row w-100 px-4 mb-3 bb-dashed py-2'>
                            <div className='fw-600 f-xs brick-clr mb-2 px-4'> {item.trip_label}</div>

                            <div className='w-50-resp-mob mb-2 mb-lg-0'>
                                <div className='fw-500 f-xs me-2 mb-1'>{item.product_name} </div>
                            </div>

                            <div className='w-50-resp-mob mb-2 mb-lg-0'>
                                <div className='fw-500 f-xs mb-1'>{item.quantity} Kg. </div>
                            </div>
                            <div className='w-50-resp-mob mb-2 mb-lg-0'>
                                <div className='fw-500 f-xs me-2 mb-1'>{item.count} </div>
                            </div>
                            <div className='w-50-resp-mob mb-2 mb-lg-0'>
                                <div className='fw-500 f-xs me-2 mb-1'>£ {item.price} </div>
                            </div>
                            <div className='w-50-resp-mob mb-2 mb-lg-0'>
                                <div className='fw-500 f-xs me-2 mb-1'>£ {item.sub_total} </div>
                            </div>
                            {mode !== "edit" &&
                                <div className='w-50-resp-mob mb-2 mb-lg-0'>
                                    <button className="btn-add brick-clr" type="button" onClick={event => onRemoveProduct(event, index)}>
                                        <i className="fa-solid fa-trash"></i> remove
                                    </button>
                                </div>}
                        </div>
                    )
                })}




                {mode == "add" &&
                    <div className='d-flex flex-column flex-lg-row w-100 px-4 mb-3 bb-dashed py-2'>
                        <div className='fw-600 f-xs brick-clr mb-2 px-4'>Product 1</div>

                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            <select className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' id="product_id" value={selectProduct.id} onChange={(e) => selectProduct(productList.results.filter(x => x.id == e.target.value)[0])}>
                                <option value={null}>Select</option>
                                {productList && productList.results.length > 0 && productList.results.map((item, index) => {
                                    return (
                                        <option value={item.id}>{item.name}</option>
                                    )
                                })}
                            </select>
                        </div>


                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            <div className='fw-500 f-xs me-2 mb-1'>{selectedProduct && selectedProduct.quantity} Kg. </div>
                        </div>
                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' id="product_count" value={countEntered} onChange={(e) => setCountEntered(e.target.value)}></input>
                        </div>
                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            <div className='fw-500 f-xs me-2 mb-1'>£ {selectedProduct && selectedProduct.price}</div>
                        </div>
                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            <div className='fw-500 f-xs me-2 mb-1'>{(selectedProduct && selectedProduct.quantity) * parseInt(countEntered ? countEntered : 0)} </div>
                        </div>

                        <div className='w-50-resp-mob mb-2 mb-lg-0'>
                            {mode == "add" && <button className="btn-add f-xs lightbrick-btn mb-3 w-100" type="button" onClick={onAddProduct}>
                                <i className="fa-solid fa-square-plus"></i> Add
                            </button>
                            }
                        </div>
                    </div>
                }





            </div>

            <div className='d-flex justify-content-end'>
                <div className='bg-F9F9F9 radius-11 p-3 w-25-resp-mob box-shadow'>
                    <div className='bb-dashed py-2'>
                        <div><span className='fw-600 brick-clr f-xs me-3'>Total Quantity :</span><span className='fw-600 f-xs'>{data.total_quantity} Kg</span></div>
                        <div><span className='fw-600 brick-clr f-xs me-3'>Total Amount :</span><span className='fw-600 f-xs'>£ {data.total_price}</span></div>
                    </div>
                    <div className='py-3 d-flex text-center w-100'>
                        <button className='lightbrick-btn f-xs w-50 me-2' type="button" onClick={handleSubmit}>Save</button>
                        <button className='white-btn f-xs w-50' type="button" onClick={() => { navigate('/admin/orders/list') }}>Cancel</button>
                    </div>
                </div>
            </div>
            <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { navigate("/admin/orders/list") }} />
            {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { navigate("/admin/orders/list") }} />}
            {isLoading && <FixedOverlayLoadingSpinner />}

        </div>
    );
}



export default OrderAdd
