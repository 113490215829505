import React from "react";
import { useEffect, useState } from "react";

import $ from 'jquery';
import { useNavigate, Link } from 'react-router-dom';


import API from '../../../API';
import LoadingSpinner from "../../../LoadingSpinner";
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import DeleteConfirmModal from "../../../DeleteConfirmModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"

import Pagination from "../../../Pagination";

import "../../common-components/AdminListPage.css"
import "./OrdersList.css"
const OrdersList = () => {


    let navigate = useNavigate();

    const [data, setData] = useState(null);
    const [filters, setFilters] = useState({
        payment_status: null,
        delivery_status: null,
        search_key: null,
        route: null,
        page:1,
        pageSize:12

    });

    const [searchKey, setSearchKey] = useState(null);



    const [fullfilmentSelected, selectFullfilment] = useState(null);
    const [paymentStatusSelected, selectPaymentStatus] = useState(null);
    const [isTodaysOrderSelected, setTodaysOrder] = useState(false);

    const [message, setMessage] = useState(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [isDeleteConfModalOpen, setIsDeleteConfModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [tabSelected, setTabSelected] = useState(0);
    const [idSelected, setIdSelected] = useState(0);

    const [isActionModalOpen, setActionModalOpen] = useState(false);
    const [isFilterDropOpen, setFilterDropOpen] = useState([false, false, false]);

    const [countIndicator, setCountIndicator] = useState([null, null, null, null, null])
    const [orderType, setOrderType] = useState("all")
    const [statusSelected, setStatusSelected] = useState(0);
    const [routes, setRoutes] = useState();
    const [routeSelected, selectRoute] = useState(null);

    useEffect(() => {

        setFilterDropOpen([false, false, false])
        let PS;
        if (paymentStatusSelected === 0) {
            PS = "True"
        }
        else if (paymentStatusSelected === 1) {
            PS = "False"
        }
        else {
            PS = null
        }

        setFilters({
            ...filters,
            payment_status: PS,
            delivery_status: fullfilmentSelected,
            today: isTodaysOrderSelected ? "True" : "False",
            search_key: searchKey,
            route: routeSelected

        })
    }, [fullfilmentSelected, paymentStatusSelected, isTodaysOrderSelected, searchKey, routeSelected])


    useEffect(() => {

        console.log("filter changed")

    }, [filters])


    useEffect(() => {

        loadTableData();
        loadRoutesListData();
    }, [ filters, orderType]);

    const loadTableData = () => {
        setData(null);
        setIsMessageModalOpen(false);
        let apiUrl = `/orders/?page=${filters.page}&page_size=${filters.pageSize}`;

        if (orderType === "pre-order") {
            apiUrl = `/order_items/?datewisefilter=preorder&page=${filters.page}&page_size=${filters.pageSize}`;
        }
        console.log("apiUrl", apiUrl)

        // Loop through the filters object and append selected filters to the apiUrl
        for (let filter in filters) {
            if (filters[filter] !== null && filters[filter] !== "") {
                apiUrl += `&${filter}=${filters[filter]}`;
            }
        }

        setIsLoading(true)
        API.get(apiUrl)
            .then(response => {
                setData(response.data);
                let temp = [...countIndicator]
                temp[tabSelected] = response.data?.count;
                setCountIndicator(temp)
                setIsLoading(false)
            })
            .catch(error => {
                setMessage(error.message);

                setIsErrorModalOpen(true);
                setIsLoading(false)

            });
    }

    const loadRoutesListData = () => {
        setIsLoading(true)
        API.get('/routes/')
            .then(response => {
                setRoutes(response.data);
                setIsLoading(false)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setIsLoading(false)
            });
    }




    useEffect(() => {
        $(".tab-btn").removeClass("active");
        $(".tab-btn").eq(tabSelected).addClass("active");



        $(".add-btn").css("display", "none");
        $(".add-btn").eq(tabSelected).css("display", "block");


        if (tabSelected === 0) {
            selectFullfilment(null);
            selectPaymentStatus(null);
        }
        else if (tabSelected === 1) {
            selectFullfilment(null);
            selectPaymentStatus(1);
        }
        else if (tabSelected === 2) {
            selectFullfilment(null);
            selectPaymentStatus(null);
        }
        else if (tabSelected === 3) {
            selectFullfilment(4);
            selectPaymentStatus(null);
        }

        else if (tabSelected === 4) {
            selectFullfilment(6);
            selectPaymentStatus(null);
        }



    }, [tabSelected])


    function toggleTab(index) {
        setTabSelected(index);
    }




    const toggleFilterDropDown = (index) => {

        var temp = [...isFilterDropOpen];
        temp.forEach((item, i) => {
            temp[i] = false
        })

        temp[index] = true
        setFilterDropOpen(temp)
    }


    const Search = (key) => {
        if (key === "Enter") {
            const searchKEY = document.getElementById("search-input").value

            setSearchKey(searchKEY);
        }
    }

    const viewDetails = (id) => {
        window.localStorage.setItem("itemSelectedId", id)
        navigate("/admin/e-commerse-list/orders/details")
    }



    const deleteItem = () => {
        setIsLoading(true)

        API.delete(`/orders/${idSelected}`)
            .then(response => {
                setMessage("Item deleted successfully");
                setIsMessageModalOpen(true)
                setIsLoading(false)

            })
            .catch(error => {
                setMessage(error.message);
                setIsErrorModalOpen(true);
                setIsLoading(false)

            });
    }

    // Delivery status options
    const deliveryStatusOptions = [
        { value: 0, label: 'Cancelled' },
        { value: 1, label: 'Processing' },
        { value: 2, label: 'On the Way' },
        { value: 3, label: 'Out to Deliver' },
        { value: 4, label: 'Delivered' },
        { value: 5, label: 'Returned' },
        { value: 6, label: 'Refunded' },
    ];
    const setOrderItemStatus = (itemId, value) => {
        console.log(itemId, value);
        setIsLoading(true);
        API.put(`/orderitem/${itemId}/`, { delivery_status: value })
            .then(response => {
                setIsLoading(false)
                loadTableData();
                setStatusSelected(value);
            })
            .catch(error => {
                setMessage(error.message);
                setIsErrorModalOpen(true);
                setIsLoading(false)
            });
    }
    const updateOrderStatus = (itemId, value) => {
        console.log(itemId, value);
        setIsLoading(true);
        API.put(`/order/${itemId}/`, { payment_status: value })
            .then(response => {
                setIsLoading(false)
                loadTableData();
                setStatusSelected(value);
            })
            .catch(error => {
                setMessage(error.message);
                setIsErrorModalOpen(true);
                setIsLoading(false)
            });
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('en-GB', options).split('/').reverse().join('-');
    };


    return (
        <div className='px-2 px-lg-4 py-3  full-screen-table-container  order-list-page'>
            <div className='d-flex justify-content-between flex-column flex-md-row'>
                <h3 className='fw-600 mb-2 mb-md-0'>Orders List</h3>
                {/* <button className='lightbrick-btn f-xs px-4'>Add Product</button> */}
                <div className=''>
                    <button onClick={() => navigate('/admin/orders/add')} className='lightbrick-btn f-xs px-4 h-33px add-prod-link me-2'><i className="fa-solid fa-plus me-2"></i>Create Order</button>
                    {/* <button className='brick-btn f-xs px-2'><i className="fa-solid fa-download me-1"></i>Dowload all</button> */}
                </div>
            </div>

            <div className='d-flex align-items-center my-3'>
                <div className='relative w-25 me-3'>
                    <input className='nav-search-inp w-100 fw-600 d-none d-lg-block' placeholder='Start typing search for Orders' id='search-input' onKeyUp={(e) => Search(e.key)}></input>
                    <input className='nav-search-inp w-100 fw-600 d-block d-lg-none' placeholder='Search' id='search'></input>
                    <div className='search-i-position'><i className="fa-solid fa-magnifying-glass"></i></div>
                </div>
                <div className='w-25 text-end mx-2'>
                    <select type='text' className='w-100 nav-search-inp ps-2 fw-600' placeholder='Order date'
                        onChange={(e) => setOrderType(e.target.value)}
                    >
                        <option value={"all"}>All Orders</option>
                        <option value={"pre-order"}>Pre Order Items</option>
                    </select>
                </div>

                {/* <div className='w-50 text-end'>
                    <input type='date' className='w-100 nav-search-inp ps-2' placeholder='Delivery date'
                        onChange={(e) => console.log(e.target.value)}

                        onBlur={(e) => (e.target.type = "text")}
                    ></input>
                </div> */}
            </div>
            {orderType == "pre-order" &&
                <select onChange={(e) => selectRoute(e.target.value)}>
                    <option value="" label='All Routes'></option>
                    {routes && routes.results && routes.results.length > 0 &&
                        routes.results.map((item, index) => {
                            return (
                                <option value={item.id}>{item.name}</option>
                            )
                        })}
                </select>
            }
            {orderType == "all" ?
                <div className='table-box'>
                    <table className="product-table">
                        <thead>
                            <tr className='bg-white'>
                                <th className='fw-600 f-13'>ID</th>
                                <th className='fw-600 f-13'>CUSTOMER</th>
                                <th className='fw-600 f-13'>POSTCODE</th>
                                <th className='fw-600 f-13'>ROUTE</th>
                                <th className='fw-600 f-13'>DELIVERY DATE</th>
                                <th className='fw-600 f-13'>ORDER DATE</th>
                                <th className='fw-600 f-13'>ITEMS</th>

                                <th className='fw-600 f-13'>PAYMENT</th>
                                {/* <th className='fw-600 f-13'>DELIVERY STATUS</th> */}
                                <th className='fw-600 f-13'>ACTION</th>
                            </tr>
                        </thead>
                        <tbody id='table'>
                            {data && data.results.map((item, index) => {

                                return (
                                    <tr key={index}>
                                        <td className='fw-500 f-xs'>#{item.id}</td>
                                        <td className='fw-500 f-xs'>{item.first_name}&nbsp;{item.last_name}</td>
                                        <td className='fw-500 f-xs'>{item.postcode}</td>
                                        <td className='fw-500 f-xs'>{item.items && item.items.length > 0 && item.items[0].route_name}</td>
                                        <td className='fw-500 f-xs'>{item.delivery_date ? formatDate(item.delivery_date) : "Not Delivered"}</td>
                                        <td className='fw-500 f-xs'>{item.date}</td>
                                        <td className='fw-500 f-xs'>{<button type='button' data-toggle="modal" data-target="#exampleModal" onClick={() => { setIdSelected(index) }} className='underline fw-600 brick-clr'>{item.items && item.items.length} Items</button>}</td>

                                        <td><button className={item.payment_status?'status-btn status-btn-green':'status-btn status-btn-red'} data-toggle="modal" >{item.payment_status_display}</button></td>
                                        {/* <td className='fw-500 f-xs'><div className={item.delivery_status == 0 ? "status-btn status-btn-orange" : item.delivery_status == 1 ? "status-btn status-btn-pink" : item.delivery_status == 2 ? "status-btn status-btn-grey" : item.delivery_status == 3 ? "status-btn status-btn-3" : item.delivery_status == 4 ? "status-btn status-btn-green" : item.delivery_status == 5 ? "status-btn status-btn-5" : "status-btn status-btn-6"}>{item.delivery_status == 0 ? "Cancelled" : item.delivery_status == 1 ? "Processing" : item.delivery_status == 2 ? "On the Way" : item.delivery_status == 3 ? "Out to Deliver" : item.delivery_status == 4 ? "Delivered" : item.delivery_status == 5 ? "Returned" : "Refunded"}</div></td> */}
                                        <td className='fw-500'>
                                            <div className="action-btn-box">
                                            <button data-toggle="modal" data-target="#orderIdStatusEditModal" onClick={() => { setIdSelected(item.id); setStatusSelected(item.payment_status) }}><i className="fa-solid fa-pen clr-33363F"></i></button>
                                         
                                            </div>
                                            
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>

                </div>

                :


                <div className='table-box'>
                    <table className="product-table">
                        <thead>
                            <tr className='bg-white'>
                                <th className='fw-600 f-13'>ORDER ITEM ID</th>
                                <th className='fw-600 f-13'>ORDER ID</th>
                                <th className='fw-600 f-13'>PRODUCT</th>
                                <th className='fw-600 f-13'>NO. OF BAGS</th>
                                <th className='fw-600 f-13'>CUSTOMER</th>
                                <th className='fw-600 f-13'>POSTAL CODE</th>
                                <th className='fw-600 f-13'>DELIVERY DATE</th>
                                <th className='fw-600 f-13'>ORDER DATE</th>
                                <th className='fw-600 f-13'>PAYMENT STATUS</th>
                                <th className='fw-600 f-13'>DELIVERY STATUS</th>
                                <th className='fw-600 f-13'>ACTION</th>
                            </tr>
                        </thead>
                        <tbody id='table'>
                            {data && data.results.map((item, index) => {

                                return (
                                    <tr key={index}>
                                        <td className='fw-500 f-xs'>#{item.id}</td>
                                        <td className='fw-500 f-xs'>#{item.order_id}</td>
                                        <td className='fw-500 f-xs'>{item.product_name}</td>
                                        <td className='fw-500 f-xs'>{item.count}</td>
                                        <td className='fw-500 f-xs'>{item.first_name}&nbsp;{item.last_name}</td>
                                        <td className='fw-500 f-xs'>{item.postcode}</td>
                                        <td className='fw-500 f-xs'>{item.delivery_date ? formatDate(item.delivery_date) : "Not Delivered"}</td>
                                        <td className='fw-500 f-xs'>{item.date}</td>

                                        <td className='fw-500 f-xs ' data-toggle="modal" >{item.payment_status}</td>
                                        <td className='fw-500 f-xs'>
                                            <div className={item.delivery_status == 0 ? "status-btn status-btn-orange" : item.delivery_status == 1 ? "status-btn status-btn-pink" : item.delivery_status == 2 ? "status-btn status-btn-grey" : item.delivery_status == 3 ? "status-btn status-btn-3" : item.delivery_status == 4 ? "status-btn status-btn-green" : item.delivery_status == 5 ? "status-btn status-btn-5" : "status-btn status-btn-6"}>
                                                {item.delivery_status == 0 ? "Cancelled" : item.delivery_status == 1 ? "Processing" : item.delivery_status == 2 ? "On the Way" : item.delivery_status == 3 ? "Out to Deliver" : item.delivery_status == 4 ? "Delivered" : item.delivery_status == 5 ? "Returned" : "Refunded"}
                                            </div>
                                        </td>
                                        <td className='fw-500'>
                                            <button data-toggle="modal" data-target="#orderItemIdStatusEditModal" onClick={() => { setIdSelected(item.id); setStatusSelected(item.delivery_status) }}><i className="fa-solid fa-pen clr-33363F"></i></button>
                                            {/* <button><i className="fa-solid fa-download clr-33363F"></i></button> */}
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>

                </div>
            }

            {data &&
                <Pagination
                    totalItems={data.count}
                    pageSize={filters.pageSize}
                    selectPageSize={(PS) => setFilters({ ...filters, pageSize: PS })}
                    currentPage={filters.page}
                    setCurrentPage={(CP) => setFilters({ ...filters, page: CP })}>

                </Pagination>
            }
            {/* Popup Modal */}
            {orderType == "all" &&
                <div className="modal fade order-items-modal" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            {data && data.results[idSelected] && data.results[idSelected].items && data.results[idSelected].items.length > 0 &&


                                <div className="modal-body">

                                    <div className="modal-title">Order Items in Order #{data.results[idSelected].id}</div>
                                    <table>
                                        <tr className=' w-100 mb-3 bb-dashed pb-2'>

                                            <th className=' f-xs fw-600'>Item id</th>
                                            <th className=' f-xs fw-600'>Trip</th>
                                            <th className='f-xs fw-600'>Product</th>
                                            <th className='f-xs fw-600 text-center'>Qty. Per Bag</th>
                                            <th className='f-xs fw-600 text-end'>No. of Bags</th>
                                            <th className='f-xs fw-600 text-end'>Price</th>
                                            <th className='f-xs fw-600 text-end'>SubTotal</th>
                                            <th className='f-xs fw-600 text-end'>Status</th>

                                        </tr>

                                        {data.results[idSelected].items.map((item, index) => {

                                            return (
                                                <tr key={index} className=' w-100 mb-2'>
                                                    <td className=' fw-500 f-xxs'>{item.id}</td>
                                                    <td className=' fw-500 f-xxs'>{item.trip_label}</td>
                                                    <td className=' fw-500 f-xxs'>{item.product_name}</td>
                                                    <td className=' fw-600 f-xxs  text-center brick-clr'>{item.quantity}kg</td>
                                                    <td className=' fw-600 f-xxs text-end brick-clr'>{item.count}</td>
                                                    <td className=' fw-600 f-xxs text-end brick-clr'>£{item.price}</td>
                                                    <td className=' fw-600 f-xxs text-end brick-clr'>£{item.sub_total}</td>

                                                    <td className=' fw-600 f-xxs text-end brick-clr'>
                                                        <select
                                                            value={item.delivery_status}
                                                            onChange={(e) => setOrderItemStatus(item.id, e.target.value)}
                                                        >
                                                            {deliveryStatusOptions.map((option, idx) => (
                                                                <option key={idx} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                </tr>

                                            )
                                        })}
                                    </table>
                                    <div className="d-flex justify-content-end">
                                        <p className='fw-600'>Total:</p>&nbsp;
                                        <p className='fw-600'>£{data && data.results[idSelected].total}</p>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <p className='fw-600'>Total Quantity:</p>&nbsp;
                                        <p className='fw-600'>{data && data.results[idSelected].total_weight} Kg.</p>
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            <div className="modal fade" id="exampleModalstaus" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">

                        <div className="modal-body p-3">
                            <div className='fw-600'>PAYMENT STATUS</div>
                            <div className='status green my-3 w-50 f-xs fw-500 py-2'>Product Delivered</div>
                            <div className='f-xs fw-500'>
                                The cancellation of this product is due to an issue with the delivery date. The
                                customer required the product at the earliest possible date, but unfortunately,
                                we are unable to provide delivery to their location within the desired timeframe
                                As a result, they have decided to cancel the products.
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* Order Item delivery status */}
            {orderType == "pre-order" &&
                <div className="modal fade pre-order-modal" id="orderItemIdStatusEditModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='mb-3 f-xs fw-500 py-2 modal-title'>
                                    <span>Order Item ID </span>
                                    <span>#{idSelected}</span>
                                </div>
                                <div className='f-xs fw-500'>
                                    Delivery Status
                                </div>
                                <div className="f-xs fw-500 py-2">
                                    <select
                                        value={statusSelected}
                                        onChange={(e) => setOrderItemStatus(idSelected, e.target.value)}
                                    >
                                        {deliveryStatusOptions.map((option, idx) => (
                                            <option key={idx} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }
            {/* Order payment status */}
            {orderType == "all" &&
                <div className="modal fade pre-order-modal" id="orderIdStatusEditModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='f-xs mb-3 fw-500 py-2 modal-title'>
                                    <span>Order ID </span>
                                    <span>#{idSelected}</span>
                                </div>
                                <div className='f-xs mb-1 fw-500'>
                                    Payment Status:
                                </div>
                                <div className="f-xs fw-500 py-2">
                                    <select
                                        value={statusSelected}
                                        onChange={(e) => updateOrderStatus(idSelected, e.target.value)}
                                    >
                                        <option value={true}>Paid</option>
                                        <option value={false}>Unpaid</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }

            <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={loadTableData} />
            {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={loadTableData} />}
            {isLoading && <FixedOverlayLoadingSpinner />}

        </div>
    )

}

export default OrdersList
