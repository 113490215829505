import React from "react";
import { useEffect, useState } from "react";

import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

import ImageUploader from '../../common-components/ImageUploader'
import API from '../../../API';
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";

import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"

import { stepButtonClasses } from "@mui/material";
import './ProductAdd.css'
const View = ({ mode }) => {

    let navigate = useNavigate();

    const [data, setData] = useState({
        "name": null,
        "description": null,
        "rating": null,
        "price": 0.01,
        "category": 0,
        "subcategory": 0,
        "quantity": 0.01,
        "item_unit": 1,
        "is_published": false,
        "regular_price": 0.01,
        "halal":false


    });

    const [images, setImages] = useState([])

    const [isLoading, setIsLoading] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [isMessagerModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState(null);
    const [formFrrors, setFormErrors] = useState({});

    const [categories, setCategories] = useState(null);
    const [brands, setBrands] = useState(null);
    const [subCategories, setSubCategories] = useState(null);
    const [units, setUnits] = useState(null);

    const [showImageUploader, setShowImageUploader] = useState(false)

    const [variantCount, setVariantCount] = useState(0);

    useEffect(() => {
        $(function () {
            $(window).scrollTop(0);
        });
    }, [])

    useEffect(() => {

        API.get('/categories')
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error(error);
            });



        API.get('/units')
            .then(response => {
                setUnits(response.data);
            })
            .catch(error => {
                console.error(error);
            });




    }, [])


    useEffect(() => {
        if (data.category) {

            API.get(`/subcategories/?category=${data.category}`)
                .then(response => {
                    setSubCategories(response.data);
                })
                .catch(error => {
                    console.error(error);
                });

        }
    }, [data])









    ///////////////////////////////////////////////////////////////////////////////////
    const [tags, setTags] = useState([]);
    const [inputTagValue, setInputTagValue] = useState('');

    const handleTagInputChange = (event) => {
        setInputTagValue(event.target.value);
    };

    const handleTagInputKeyDown = (event) => {
        if (event.key === 'Enter' && inputTagValue.trim() !== '') {
            event.preventDefault();
            addTag(inputTagValue.trim());
        }
    };

    const addTag = (tag) => {
        setTags((prevTags) => [...prevTags, tag]);
        setInputTagValue('');
    };

    const removeTag = (index) => {
        setTags((prevTags) => prevTags.filter((_, i) => i !== index));
    };

    //////////////////////////////////////////////////////////////////////////////



    useEffect(() => {

        if (mode !== "add") {
            console.log("mode entered", mode)
            API.get(`/products/${localStorage.getItem("itemSelectedId")}`)
                .then(response => {
                    setData(response.data);

                    if (response.data.variant3_quantity) {
                        setVariantCount(3)
                    }
                    else if (response.data.variant2_quantity) {
                        setVariantCount(2)
                    }
                    else if (response.data.variant1_quantity) {
                        setVariantCount(1)
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
        , []);


    // const onFieldChange = (e, key) => {
    //     var temp = { ...data };
    //     temp[key] = e.target.value;
    //     setData(temp)
    // }


    const onFieldChange = (e, key) => {
        var temp = { ...data };
        if(key=="halal"){
            temp.halal=e.target.checked
        }
        else{
            temp[key] = e.target.value;
        }
        setData(temp)
    }


    const [editorState, setEditorState] = useState()

    const onEditorStateChange = (newState) => {
        setEditorState(newState)


        var temp = { ...data };
        temp.description = newState.getCurrentContent().getPlainText();
        setData(temp)

    }



    const validateForm = (data) => {
        const errors = {};

        // Validate each field and add errors if necessary
        if (!data.name) {
            errors.name = "Product name is required.";
        }


        if (!data.description) {
            errors.description = "Description is required.";
        }

        if (!data.regular_price || data.regular_price < 0.01) {
            errors.regular_price = "Should be a minimum of 0.01";
        }

        if (!data.price || data.price < 0.01) {
            errors.price = "Should be a minimum of 0.01";
        }




        // if (data.discount === null || parseInt(data.discount) < 0) {
        //     console.log("discount is", data.discount)
        //     errors.discount = "Discount should be a number greater than or equal to 0.";
        // }
        // if (!data.discount_end_date) {
        //     errors.price = "This field should not be empty.";
        // }
        if (parseInt(data.category) === 0) {
            errors.category = "Please Choose a Category.";
        }
        if (parseInt(data.subcategory) === 0) {
            errors.subcategory = "Please choose a Subcategory";
        }
        // if (parseInt(data.brand) === 0) {
        //     errors.brand = "Please choose a Brand";
        // }
        if (!data.quantity || data.quantity < 0.01) {
            errors.quantity = "Quantity  should be a minimum of 0.01";
        }

        if (parseInt(data.item_unit) === 0) {
            errors.item_unit = "Please choose a Brand";
        }



        return errors;
    };

    const validateImageCount = (data) => {
        const errors = {};
        if (!images.length > 0) {
            errors.image = "Minimum 1 image is required.";
        }
        return errors;
    };

    const post = (DATA) => {
        console.log("post fn entered")
        const validationErrors = validateForm(DATA);

        setFormErrors(validationErrors);
        console.log("validation Errors", validationErrors)
        if (Object.keys(validationErrors).length === 0) {
            console.log("validation success")
            setIsLoading(true);
            API.post(`/products/`, DATA)
                .then(response => {
                    // Handle the response

                    setIsLoading(false);
                    localStorage.setItem("itemSelectedId", response.data.id);
                    setMessage("Product saved successfully");
                    setShowImageUploader(true)
                })
                .catch(error => {
                    // Handle the error
                    console.error('Error saving Product:', error);
                    setIsLoading(false);
                    setIsErrorModalOpen(true)
                });

        }
    }


    const put = (DATA) => {

        const validationErrors = validateForm(DATA);

        setFormErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setIsLoading(true);
            API.put(`/products/${window.localStorage.getItem("itemSelectedId")}/`, DATA)
                .then(response => {
                    // Handle the response
                    setIsLoading(false);
                    setMessage("Product Details Updated successfully");
                    setShowImageUploader(true)
                })
                .catch(error => {
                    // Handle the error
                    console.error('Error saving Product:', error);
                    setIsLoading(false);
                    setIsErrorModalOpen(true)
                });

        }
    }
    const putWithImageValidation = (DATA) => {


        const validationErrors = validateImageCount(DATA);

        setFormErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setIsLoading(true);
            API.put(`/products/${window.localStorage.getItem("itemSelectedId")}/`, DATA)
                .then(response => {
                    // Handle the response

                    setIsLoading(false);
                    window.localStorage.setItem("itemSelectedId", null);
                    if(DATA.is_published){
                        setMessage("Product Published Successfully");
                    }
                    else{
                        setMessage("Product Saved As Draft Successfully");

                    }
                   
                    setIsMessageModalOpen(true);
                })
                .catch(error => {
                    // Handle the error
                    console.error('Error saving Product:', error);
                    setMessage("Error saving Product")
                    setIsLoading(false);
                    setIsErrorModalOpen(true)
                });

        }
    }

    const handlesaveProduct = () => {
        console.log("save product fn entered")
        var temp = { ...data };
        temp.is_published = false;

        if (mode === "add") {
            post(temp);
        }

        else {
            put(temp);
        }

    }





    const handleSaveDraft = () => {
        // Create the API call for saving the draft
        var temp = { ...data };
        temp.is_published = false;

        putWithImageValidation(temp);

    };

    const handlePublishProduct = () => {
        // Create the API call for publishing the product
        var temp = { ...data };
        temp.is_published = true;
        console.log("temp", temp)

        putWithImageValidation(temp);
    };


    const handleDiscad = () => {

        navigate('/admin/products/list')
        // setIsLoading(true);
        // API.delete(`/products/${window.localStorage.getItem("itemSelectedId")}`)
        //     .then(response => {
        //         // Handle the response
        //         console.log('Prduct deleted successfully:', response.data);

        //         window.localStorage.setItem(null)
        //         navigate('/admin/e-commerse-list/products')
        //     })
        //     .catch(error => {
        //         // Handle the error
        //         console.error('Error deleting Product:', error);
        //         navigate('/admin/e-commerse-list/products')
        //     });


    }





    return (


        <form className="w-100">
            <div className="admin-panel-add-product-sec-1 container">
                <div className="row">
                    <div className="col-lg-6">

                        {mode == "add" ? <h4>Add Product</h4> : <h4>Edit Product</h4>}

                    </div>
                    {window.innerWidth > 768 ? <div className="col-lg-6">
                        <div className="d-flex justify-content-end mb-3 submit-btn-container">
                            <button className=" btn btn-small-dark" type="button" onClick={handleDiscad}>Cancel </button>
                            <button className=" btn btn-small-dark" type="button" onClick={handlesaveProduct}>{mode == "add" ? "Save Product" : "Update Product"}</button>
                        </div>
                    </div> : null}
                </div>
                <div className="row">
                    <div className="col-lg-7 ">

                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label title-label">Product Title
                            </label>
                            <div className="d-flex w-100">
                                <input type="text" className="form-control form-control-sm" value={data.name} id="name" placeholder="Write product title" onChange={(e) => onFieldChange(e, "name")}></input>
                                <div className="halal-box">
                                    <div>
                                        <input type='checkbox' className='form-check' onChange={(e) => onFieldChange(e, "halal")}></input>
                                    </div>
                                    <div>
                                        <img src="/images/admin-panel/products/add-product/halal-logo.svg"></img>
                                        <span>halal</span>
                                    </div>
                                </div>
                            </div>
                            {formFrrors.name && <div className="text-danger">{formFrrors.name}</div>}

                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label title-label">Product Description</label>
                            <textarea type="text" className="form-control" value={data.description} id="description" placeholder="Write product description" onChange={(e) => onFieldChange(e, "description")}></textarea>
                            {formFrrors.description && <div className="text-danger">{formFrrors.description}</div>}

                        </div>




                        {showImageUploader && <ImageUploader setterFunction={setImages} state={images} setLoading={setIsLoading} formFrrors={formFrrors} setFormErrors={setFormErrors} handlePublishProduct={handlePublishProduct} handleSaveDraft={handleSaveDraft}></ImageUploader>}



                        <div className="mb-3">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label title-label">Pricing</label>




                            <div className="card inventory-card">
                                <div className="row w-100">
                                    <div className="col-4">
                                        <button className="btn btn-small-dark" type="button" onClick={() => { }}>Pricing </button>

                                    </div>
                                    <div className="col-8">
                                        <div className="row">
                                            {/* <div className="col-6">
                                                <label htmlFor="regularPrice" className="form-label">Regular Price</label>
                                                <input type="number" min={0.01} step={0.01} className="form-control form-control-sm" id="regularPrice" name="regularPrice" placeholder="Enter Regular Price" value={data.regular_price} onChange={(e) => onFieldChange(e, "regular_price")}></input>
                                                {formFrrors.regular_price && <div className="text-danger">{formFrrors.regular_price}</div>}
                                            </div> */}

                                            <div className="col-6">
                                                <label htmlFor="price" className="form-label">Base Price </label>
                                                <div className="d-flex align-items-center">
                                                    <input type="number" min={0.01} step={0.01} className="form-control form-control-sm" id="price" name="price" placeholder="Enter Sales Price" value={data.price} onChange={(e) => onFieldChange(e, "price")}></input>
                                                    <span className="f-xxs w-100 ms-3 mb-0">Per Bag</span>
                                                </div>{formFrrors.price && <div className="text-danger">{formFrrors.price}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>





                            </div>
                        </div>





                    </div>
                    <div className="col-lg-5">
                        <label class="form-check-label title-label">Organize</label>

                        <div className="card w-100">
                            <div className="row w-100">
                                <div className="col-12">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Category
                                        </label>

                                        <select class="form-select form-select-sm" aria-label=".form-select-sm example" value={data.category} onChange={(e) => onFieldChange(e, "category")}>
                                            <option value={0} selected>Select Category</option>
                                            {categories && categories.results.map((item, index) => {
                                                return (
                                                    <option value={item.id} key={index}>{item.category_name}</option>
                                                )
                                            })}


                                        </select>
                                        {formFrrors.category && <div className="text-danger">{formFrrors.category}</div>}

                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Sub Category
                                        </label>

                                        <select class="form-select form-select-sm" aria-label=".form-select-sm example" value={data.subcategory} onChange={(e) => onFieldChange(e, "subcategory")}>
                                            <option value={0} selected>Select Sub Category</option>
                                            {subCategories && subCategories.results.map((item, index) => {
                                                return (
                                                    <option value={item.id} key={index}>{item.sub_category_name}</option>
                                                )
                                            })}
                                        </select>
                                        {formFrrors.subcategory && <div className="text-danger">{formFrrors.subcategory}</div>}
                                    </div>
                                </div>




                                <div className="col-6">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Quantity
                                        </label>
                                        <input className="form-control form-control-sm" type="number" min={0.01} step={0.01} value={data.quantity} onChange={(e) => onFieldChange(e, "quantity")} ></input>
                                        {formFrrors.quantity && <div className="text-danger">{formFrrors.quantity}</div>}
                                        <span className="f-xxs">Per Bag</span>
                                    </div>

                                </div>
                                <div className="col-6">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Unit
                                        </label>
                                        <select class="form-select form-select-sm" aria-label=".form-select-sm example" value={data.item_unit} onChange={(e) => onFieldChange(e, "item_unit")}>

                                            {units && units.results.map((item, index) => {
                                                return (
                                                    <option value={item.id} key={index}>{item.unit_name}</option>
                                                )
                                            })}
                                        </select>
                                        {formFrrors.item_unit && <div className="text-danger">{formFrrors.item_unit}</div>}</div>
                                </div>






                                <div className="col-12 mb-3">
                                    {formFrrors.gross_weight && <div className="text-danger">{formFrrors.gross_weight}</div>}
                                </div>

                                <div className="col-12">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Tags: &nbsp;
                                        </label>
                                        <div className="tag-select">
                                            <div className="tags-container">
                                                {tags.map((tag, index) => (
                                                    <div key={index} className="tag">
                                                        <span className="tag-label">{tag}</span>
                                                        <span className="tag-remove" onClick={() => removeTag(index)}>
                                                            &times;
                                                        </span>
                                                    </div>
                                                ))}
                                                <input
                                                    type="text"
                                                    className="tag-input"
                                                    value={inputTagValue}
                                                    onChange={handleTagInputChange}
                                                    onKeyDown={handleTagInputKeyDown}
                                                    placeholder="Enter a tag..."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>















                    <div className="col-12">
                        <div className="card w-100">
                            <div className="row w-100">
                                <div className="col-12"></div>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlTextarea1" className="form-label title-label">Reviews</label>
                                    <div className="d-flex flex-column justify-content-between w-100 review-btn-container">
                                        <button className="btn btn-secondary" onClick={() => { navigate('/admin/reviews/list') }}>All Reviews</button>
                                        <button className="btn btn-secondary" onClick={() => { navigate('/admin/reviews/list') }}>Positive Reviews</button>
                                        <button className="btn btn-secondary" onClick={() => { navigate('/admin/reviews/list') }}>Hide Reviews</button>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>




                </div>

                <div className="row">
                    {window.innerWidth <= 768 ? <div className="col-lg-6">
                        <div className="d-flex justify-content-end mb-3 submit-btn-container">
                            <button className=" btn btn-small-dark" type="button" onClick={() => navigate('/admin/products/list')}>Discard </button>
                            <button className=" btn btn-small-dark" type="button" onClick={handleSaveDraft}>Save Draft </button>
                            <button className=" btn btn-small-dark" type="button" onClick={handlePublishProduct}>Publish  Product </button>
                        </div>
                    </div> : null}
                </div>
            </div>

            <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { }} />
            {isMessagerModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { navigate("/admin/products/list") }} />}
            {isLoading && <FixedOverlayLoadingSpinner />}
        </form >


    )
}

export default View;