import React, { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie';
import axios from 'axios';
import EditPopup from '../../common-components/EditPopupForCategoriesAndSubCategories/EditPopup';
import API from '../../../API'
import FixedOverlayLoadingSpinner from '../../../FixedOverlayLoadingSpinner'
import PositiveModal from "../../../PositiveModal";
import DeleteConfirmModal from "../../../DeleteConfirmModal";
import ErrorModal from "../../../ErrorModal";
import $ from 'jquery';

const Category = () => {

  const navigate = useNavigate()

  const [data, setData] = useState();
  const [selectedItem, setSelectedItem] = useState({ id: "", "category_name": "" })
  const [isFormPopupOpen, showFormPopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState("add");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [isMessagerModalOpen, setIsMessageModalOpen] = useState(false);
  const [message, setMessage] = useState(null);

  const [isDeleteConfModalOpen, setIsDeleteConfModalOpen] = useState(false)

  const handleInputChange = (e) => {
    setSelectedItem({ ...selectedItem, category_name: e.target.value })
  };


  useEffect(() => {
    loadTableData()
  }, [])

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])
 

  const loadTableData = () => {
    setIsLoading(true)
    API.get('/categories/')
      .then(response => {
        setIsLoading(false)
        setData(response.data)

      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false)

      });
  }



  const save = () => {

    if (selectedItem.category_name) {
      setIsLoading(true)
      if (mode == "add") {
        API.post('/categories/', { category_name: selectedItem.category_name })
          .then((response) => {
            showFormPopup(false)
            loadTableData();
            setIsLoading(false)
          })
          .catch((error) => {
            setMessage(error.response.data.message);
            setIsErrorModalOpen(true)
            setIsLoading(false)
          })
      }

      else {
        API.put(`/categories/${selectedItem.id}/`, { category_name: selectedItem.category_name })
          .then(response => {
            setSelectedItem(null);
            showFormPopup(false)
            setIsLoading(false)

            loadTableData()
          })
          .catch(error => {
            console.error('Error updating data:', error);
            setIsLoading(false)

          });
      }
    }
    else {
      setMessage("Fields canot be empty");
      setIsErrorModalOpen(true)
    }
  }




  const deleteItem = () => {
    setIsLoading(true)
    API.delete(`/categories/${selectedItem.id}`)
      .then((response) => {
        setIsLoading(false)
        setMessage("Item Deleted Successfully")
        setIsMessageModalOpen(true)
      })
      .catch((error) => {
        console.log("Error Deleting!")
      })

  }


  const handleClosePopup = () => {
    setSelectedItem(null)
    showFormPopup(false)
  };



  const deleteCategory = (item) => {
    setSelectedItem(item);
    setIsDeleteConfModalOpen(true)
   
  }




  return (
    <div className='px-0 px-md-4 py-3 customer full-screen-table-container'>
      <div className='text-end'>
        {/* <button className='lightbrick-btn f-xs px-4'>Add Product</button> */}
        <button className='lightbrick-btn f-xs px-4 add-prod-link mb-3' onClick={() => { setMode("add"); showFormPopup(true) }}>New Category</button>
      </div>

      <div className='w-100 product-table'>
        <table className="rwd-table mb-2">
          <tbody id='table'>
            <tr>
              <th>CATEGORY NAME</th>
              <th className='text-center'>More</th>
            </tr>


            {
              data && data.results.length > 0 && data.results.map((item, index) => (
                <tr key={item.id} >
                  <td data-th="Name">{item.category_name}</td>
                  <td data-th="Action" className='text-center'>
                    {index > 2 && <button onClick={() => { setSelectedItem(item); setMode("edit"); showFormPopup(true) }} ><i className="fa-solid fa-pen clr-33363F"></i></button>}
                    {/* {index > 2 && <button onClick={() => { deleteCategory(item) }} ><i className="fa-solid fa-trash clr-33363F"></i></button>} */}
                  </td>
                </tr>
              ))
            }

          </tbody>
        </table>
      </div>


      {/* Popup Modal */}



      {isFormPopupOpen &&
        <div className='custom-modal'>

          <div className='card'>
            <div className='main'>
              <button type="button" className="close" onClick={() => showFormPopup(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
              <form>


                <div className='mb-2'>
                  <div className='f-13 fw-500 mb-1 black-clr'>Category Name</div>
                  <div><input className='inp-F0F0F0 w-100 p-2 fw-500' placeholder='Add/Edit Category Name'
                    name='category_name'
                    value={selectedItem.category_name}
                    onChange={handleInputChange}></input></div>
                </div>
                <input type='hidden' name='id'></input>

                <button type='button' className='f-xs lightbrick-btn w-100' onClick={save}>Save/Edit</button>
              </form>
              <div>


              </div>
            </div>
          </div>
        </div>
      }
      {isLoading && <FixedOverlayLoadingSpinner />}
      {isDeleteConfModalOpen && <DeleteConfirmModal resourceName={'category'} setterFunction={setIsDeleteConfModalOpen} onDeleteFunction={deleteItem}></DeleteConfirmModal>}
      {isMessagerModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => loadTableData()} />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={loadTableData} />

    </div >
  )


}

export default Category