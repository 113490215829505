
import React, { useEffect, useLayoutEffect, useState } from 'react';
import "../../CustomPopup.css";
import API from '../../API'
import FixedOverlayLoadingSpinner from "../../FixedOverlayLoadingSpinner"

import ErrorModal from "../../ErrorModal";
import { UK_COUNTIES } from '../../Constants';
import { error } from 'jquery';
import PositiveModal from "../../PositiveModal";

const LocationCollectionPopup = ({ productId, productCount, setterFunction, loadUserData }) => {

    const [data, setData] = useState(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSecondScreenShown, showSecondScreen] = useState(false)
    const [formErrors, setFormErrors] = useState([])


    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);


    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedTrip, selectTrip] = useState(null)

    useEffect(() => {
        setIsLoading(true)
        API.get(`/personalised_trips_for_product/${productId}/`)

            .then(response => {
                setData(response.data)
                setIsLoading(false)
            })
            .catch(error => {
                console.log("error on personalised_trips_for_product API")
                setIsLoading(false)
            })
    }, [])

    const validateForm = () => {
        let error = {}
        if (selectedTrip == null) {
            error.trip_id = "Select a delivery."
        }
        return (error)
    }
    const handleSubmit = () => {
        const validationErrors = validateForm(data);
        setFormErrors(validationErrors);
        console.log("validationErrors", validationErrors)
        if (Object.keys(validationErrors).length === 0) {
            setIsLoading(true)
            API.post(`add-to-cart/${productId}/${productCount}/`, { "trip_id": selectedTrip })// add_to_cart/product id/variant number/count
                .then(response => {
                    // Handle the response
                    setIsLoading(false)
                    setMessage("Item Added to the Cart Successfully!")
                    setIsMessageModalOpen(true)

                    console.log('Item Added to cart succesfully')
                    loadUserData()
                })
                .catch(error => {
                    // Handle the error
                    setMessage(error.response.data.message)
                    setIsErrorModalOpen(true)
                    setIsLoading(false)
                });
        }

    }

    return (
        <div className='custom-modal add-to-cart-trip-selection-modal'>
            <div className='card'>

                {data &&
                    <div className='main-screen'>
                        <h1>ORDER NOW!</h1>
                        <p>Choose the Availability of this product In your location</p>

                        {data.results.map((item, index) => {
                            return (
                                <div class="form-check" key={index}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id={`flexRadioDefault${index}`}
                                        onClick={() => selectTrip(item.id)}
                                    />
                                    <label className="form-check-label" htmlFor={`flexRadioDefault${index}`}>
                                        {item.label}
                                    </label>
                                </div>

                            )
                        })}

                        {formErrors.trip_id && <div className="text-danger">{formErrors.trip_id}</div>}
                        <br />

                       <p className='hint'> (If you wish to change your Location, Please go to the address page and update your primary Address.)</p>

                        <div className='footer'>
                            <button type='button' className='cancel-button' onClick={() => setterFunction(false)}>May Be Later</button>

                            <button type='button' className='ok-button' onClick={handleSubmit}>Add to Bag</button>
                        </div>
                    </div>
                }

            </div>
            <ErrorModal message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
            {isLoading && <FixedOverlayLoadingSpinner />}
            {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { setterFunction(false) }} />}

        </div>
    );
};

export default LocationCollectionPopup;

