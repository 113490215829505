import React, { useContext } from 'react'
import './Footer.css'
import { Link, Navigate } from 'react-router-dom'
import { UserContext } from '../../authentication/pages/UserContext';
const Footer=()=>{
  const { isLoggedIn, login, logout } = useContext(UserContext);
  const sendMail = () => {
    window.location.href = 'mailto:sibufishonline@gmail.com';
  };


    return (
      <div>
        <div className='w-100 d-flex flex-column flex-md-row justify-content-between mt-6 footer'>

          <div className='w-40-resp-mob text-start'>
            <img src="/images/Footer/Logo.svg" alt='' className='logosize'></img>
            <div className='f-sm clr-868790 fw-500 mt-2 mb-3'>We are the leading delivery service<br />for fish and meat in the UK,<br />widely recognised for our quality<br />and reliability. </div>
            <div className='f-address d-flex flex-column  align-items-start'>
              <div className='d-flex'>
                <div className='me-4'><span className="material-symbols-outlined">distance</span></div>
                <div className='f-sm fw-500 black-clr'>
                  5 Seafield Place, Formby<br></br>
                  L37 4AW, United Kingdom.
                </div>
              </div>
              <div className='d-flex my-3'>
                <div className='me-4'><span className="material-symbols-outlined">call</span></div>
                <div className='f-sm fw-500 black-clr'>07739225112</div>
              </div>
              <div className='d-flex'>
                <div className='me-4'><span className="material-symbols-outlined">language</span></div>
                <div className='f-sm fw-500 black-clr'>sibufishandmeat.co.uk</div>
              </div>
            </div>
          </div>

          <div className='w-20-resp-mob text-center text-lg-start black-clr f-links mt-4'>
            <div className='fw-600 f-lg mb-2'>Services</div>
            <div className=''>
              <div className='mb-2'><Link to='/profile'>My Account</Link></div>
              <div className='mb-2'><Link to='/faq-contact'>Customer Services</Link></div>
              <div className='mb-2'><Link to='/wishlist'>Wishlist</Link></div>
              <div className='mb-2'><Link to='/privacy-policy'>Privacy Policy</Link></div>
            </div>
          </div>

          <div className='w-20-resp-mob text-center text-lg-start black-clr f-links mt-4'>
            <div className='fw-600 f-lg mb-2'>Information</div>
            <div className=''>
              <div className='mb-2 '><Link to='/'>Home</Link></div>
              <div className='mb-2'><Link to='/about-us'>About Us</Link></div>
              <div className='mb-2'><Link to='/'>Testimonials</Link></div>
              <div className='mb-2'><Link to='/orders'>Orders</Link></div>
            </div>
          </div>

          <div className='w-20-resp-mob  text-start followus mt-4'>
            <div className='fw-600 f-lg mb-2'>Follow Us</div>
            <div className='f-sm clr-868790 fw-500 mt-2 mb-3 px-0 '>Let's stay connected on<br /> social media.</div>
            {/* <div className='w-100 text-center relative'>
            <input className='w-100 radius-7 f-xxs fw-500' placeholder='Enter email'></input>
           
              <button className='absolute r-0 lightbrick-btn px-3 h-100 f-sm' onClick={this.sendMail}><i className="fa-solid fa-paper-plane"></i></button>
          
          </div> */}
            <div className='d-flex justify-content-start mt-4'>
              {/* <div className='socialmedia me-2'>
              <Link href="#" className=''><i className="fa-brands fa-twitter"></i></Link>
            </div> */}
              {/* <div className='socialmedia me-2'>
              <Link href="#" className=''><i className="fa-brands fa-whatsapp"></i></Link>
            </div> */}
              <div className='socialmedia me-2'>
                <Link to="https://www.facebook.com/sibufishandmeat" className=''><i className="fa-brands fa-facebook-f"></i></Link>
              </div>
              <div className='socialmedia'>
                <Link to="https://www.instagram.com/sibufishnmeat_uk/" className=''><i className="fa-brands fa-instagram"></i></Link>
              </div>
            </div>

          </div>

        </div>

{isLoggedIn&&
        <div className='d-flex w-100 justify-content-between mt-4 d-block d-lg-none p-3 mob-footer'>
          <Link to='/home' className='d-flex flex-column align-items-center'>
            <span className="material-symbols-outlined f-xxl">home</span>
            <div>Home</div>
          </Link>
          <Link to='/orders' className='d-flex flex-column align-items-center'>
            <span className="material-symbols-outlined f-xxl">shopping_bag</span>
            <div>Order</div>
          </Link>
          <Link to='/cart' className='d-flex flex-column align-items-center'>
            <span className="material-symbols-outlined f-xxl">shopping_cart</span>
            <div>Cart</div>
          </Link>
          <Link to='/profile' className='d-flex flex-column align-items-center'>
            <span className="material-symbols-outlined f-xxl">person</span>
            <div>Profile</div>
          </Link>
        </div>
  }
      </div>
    ) 
}

export default Footer