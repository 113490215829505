import React, { Component } from 'react'
import './Maintanance.css'
import { Link } from 'react-router-dom'

export class Maintanance extends Component {
  render() {
    return (
      <div className='maintanance'>
        <div className='box-shadow p-4 d-flex flex-column justify-content-center align-items-center radius-11'>
            <div>
                <div><img src="/images/maintanace.svg" alt='' className='errorimgwid'></img></div>
            </div>
            <div className='fw-600 maintanance-text brick-clr mt-3 text-center'>We are Under Maintenance</div>
            <div className='mb-3 fw-500'>Will be Back Soon!.</div>

            <Link to="/Layout2/Overview" className='lightbrick-btn f-xs px-4 underline-none py-1'>Back to home</Link>
        </div>
      </div>
    )
  }
}

export default Maintanance
