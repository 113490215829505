import React from "react";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';


import API from '../../../API';
import LoadingSpinner from "../../../LoadingSpinner";
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import DeleteConfirmModal from "../../../DeleteConfirmModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"
import Empty from "../../../Empty"

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import './Cart.css'
import { cardContentClasses } from "@mui/material";
import CartPreOrderWarningPopup from '../../common-component/CartPreOrderWarningPopup'
const Cart = () => {



  let navigate = useNavigate();

  const [cartData, setCartData] = useState({
    cart_items: []
  });

  const [cartTotals, setCartTotals] = useState({
    sub_total: 0,
    vat: 0,
    total: 0
  })


  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isDeleteConfModalOpen, setIsDeleteConfModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [units, setUnits] = useState();
  const [idSelected, setIdSelected] = useState(null);

const [isPreOrderWarningPopupShown,showPreOrderWarningPopup]=useState(false)

  useEffect(() => {
    $(function() {
  $(window).scrollTop(0);
});
}, [])


  useEffect(() => {
    setUnits([])
    API.get(`/units/`)
      .then(response => {
        setUnits(response.data)
      })
      .catch(error => {
        console.error(error);
      });
    loadData();
  }, []);

  const loadData = () => {
    setIsLoading(true)
    API.get(`/cart/`)
      .then(response => {
        const promises = response.data.items.map((product, index) => {
          return API.get(`products/${product.product_id}`)
            .then(prodDetails => {
              const cartItem = {
                ...prodDetails.data,
                cart_item_id: product.cart_item_id,
                trip: product.trip,
                price: product.price,
                count: product.count,
                instock: product.instock,
                sub_total: parseInt(product.count) * (parseFloat(product.price))
              };

              return cartItem;
            });
        });

        Promise.all(promises)
          .then(items => {
            setCartData(prevData => ({
              ...prevData,
              cart_items: items,

            })
            );
            setIsLoading(false)
          });
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false)

      });
  }



  useEffect(() => {
    console.log("cartTotals", cartTotals)
  }, [cartTotals])

  const getUnitName = (unitId) => {
    if (units && units.results && units.results.length > 0) {
      let temp = units.results.find(x => x.id === unitId);
      if (temp) {
        return (temp.unit_name)
      }
      else {
        return ""
      }
    }
    else {
      return ""
    }
  }

  const deleteItem = () => {
    setIsLoading(true)
    API.delete(`/cart/${idSelected}/`)
      .then(response => {
        setIsLoading(false)
        loadData();
      }
      )
      .catch(error => {
        setIsLoading(false)

        console.error(error);
      });
  }

  const handleQtyChange = (direction, index, id) => {

    console.log("cartData from cart update", cartData)
    let count = parseInt(cartData.cart_items[index].count);
    if (direction === "plus") {
      count = count + 1
    }
    else {
      count = count - 1
    }
    if (count > 0) {
      setIsLoading(true)
      API.put(`/cart/${id}/`, { "count": count })
        .then(response => {
          loadData();
          setIsLoading(false)
        }
        )
        .catch(error => {
          setMessage(error.response.data.message)
          setIsErrorModalOpen(true)
          setIsLoading(false)
        });
    }
    else {
      setMessage("Quantity should be a number greater than zero")
      setIsErrorModalOpen(true)
    }
  }







  useEffect(() => {   //calculate main total
    let temp = { ...cartData };


    let subTotal = 0
    for (let i = 0; i < temp.cart_items.length; i++) {

      subTotal = subTotal + temp.cart_items[i].sub_total

    }
    let result = {};

    result.sub_total = subTotal;

    result.total = subTotal

    setCartTotals(result)

    console.log("cartData",cartData)
  }, [cartData])



const checkCartCotainsPreOrder = () => {
  var isContainPreOrder = false;
  (cartData.cart_items).forEach(element => {
    if (element.trip === "Pre Order") {
      isContainPreOrder = true;
    }
  });

  if (isContainPreOrder === true) {
    showPreOrderWarningPopup(true);
  } else {
    proceedToHomeDeliveryCheckout();
  }
}


  const proceedToHomeDeliveryCheckout = (e) => {
  
    window.localStorage.setItem("buyMode", null);
     navigate("/checkout-address")
  }

  const deleteAll = () => {
    setIsLoading(true)
    API.post('/delete-all-cart-items/')
      .then(response => {
        setIsLoading(false)
        setMessage("All the items from your cart were deleteted.")
        setIsMessageModalOpen(true)
      })
      .catch(error => {
        setIsLoading(false)
        setMessage(error.response.data.message ? error.response.data.message : "Something went Wrong!!")
        setIsErrorModalOpen(true)
      })
  }

  return (
    <div className='cart'>

      <div className='my-5 mx-0 mx-lg-5 d-flex justify-content-center'>
        <ProgressBar percent={0}>
          <Step>
            {({ accomplished, index }) => (
              <div
                className={`indexedStep ${accomplished ? "accomplished" : null}`}
              >
                {index + 1}
              </div>
            )}
          </Step>
          <Step>
            {({ accomplished, index }) => (
              <div
                className={`indexedStep ${accomplished ? "accomplished" : null}`}
              >
                {index + 1}
              </div>
            )}
          </Step>
          <Step>
            {({ accomplished, index }) => (
              <div
                className={`indexedStep ${accomplished ? "accomplished" : null}`}
              >
                {index + 1}
              </div>
            )}
          </Step>
        </ProgressBar>
      </div>

      <div className='d-flex flex-column flex-lg-row align-items-center w-100 mt-4'>
        <div className='lhs w-60-resp-tab radius-11 box-shadow p-3 me-0 me-lg-3 mb-3 mb-lg-0 bg-white'>
          <div className="d-flex justify-content-between w-100 mb-3">
            <h4>Cart</h4>
            {cartData && cartData.cart_items.length>0?
             <button className="lightbrick-btn f-xs w-50 w-md-25 " onClick={deleteAll}>Delete All</button>:
             <></>
            }
          </div>

          <div className='cart-table-head w-100 d-none d-md-flex bb-dashed f-xs fw-500 pb-2 justify-content-between'>

            <p>Product Name </p>
            <p>Delivery Day</p>
            <p >No. of Bags</p>
            <p>Total</p>
            <p>Action</p>
          </div>


          {cartData && cartData.cart_items.length>0?cartData.cart_items.map((item, index) => (
            <div className='cart-table-item w-100 d-flex flex-column flex-md-row bb-dashed f-xs fw-500 py-2' key={index}>
              <div className='w-50-resp-mob d-flex justify-content-start mb-3 mb-lg-0'>

                <div className=' product-summ-strip'>
                  <div className='f-13 fw-600'>{item.name}</div>
                  <div className='d-flex justify-content-between my-1'>
                    <div className='brick-clr bg-F9F9F9 p-1 radius-7 me-2'><strong>£{item.price}/bag</strong></div>
                    <div className='f-xxs fw-600 radius-7 bg-F9F9F9 p-1'>{item.quantity}kg (per bag)</div>
                  </div>
                  {/* <div className='f-xxs clr-898989'><i className="fa-solid fa-reply f-xxs me-1"></i>2 days return available </div> */}
                </div>
                <div className='ms-3'>
                  <div className='f-13 fw-600'>{item.trip}</div>
                </div>
              </div>
              <div className='w-50-resp-mob d-flex'>
                <div className='w-50-resp-mob mb-3 mb-lg-0'>
                  <div className="qty-container">
                    <button className="qty-btn-minus  btn-cornered me-1" type="button" onClick={(e) => handleQtyChange('minus', index, item.cart_item_id)}><i className="fa fa-minus"></i></button>
                    <input type="text" name="qty" value={item.count} className="input-qty input-cornered me-1" />
                    <button className="qty-btn-plus btn-cornered" type="button" onClick={(e) => handleQtyChange('plus', index, item.cart_item_id)}><i className="fa fa-plus"></i></button>
                  </div>
                </div>
                <div className='w-50-resp-mob d-flex justify-content-between'>
                  <div className='brick-clr fw-600'>£{item.sub_total}</div>
                  <button role="button" onClick={() => {setIdSelected(item.cart_item_id);setIsDeleteConfModalOpen(true)}}><i className="fa-regular fa-circle-xmark clr-898989"></i></button>
                </div>
              </div>
            </div>
          ))
        
        :<div className="empty">
          <img src="/images/empty.svg"></img>
          <h1>Your Cart is Empty</h1>
          <p>Start shopping now to find the perfect items for you!</p>
          </div>}




        </div>

        {cartData && cartTotals &&
          <div className='rhs w-40-resp-tab radius-11 box-shadow p-3 bg-white'>
            <div className='scootyman fw-500 f-lg d-flex align-items-center bb-dashed'>
              <strong>
                Ordering with ease,<br></br>
                delivering with <br></br>
                <span className='brick-clr'>pleasure</span>
              </strong>
            </div>
            <div className='py-3 px-5'>

              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Sub Total</div>
                  <div className='w-50'>£ {cartTotals.sub_total}</div>
                </div>
              </div>
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Discount</div>
                  <div className='w-50'>No Discount</div>
                </div>
              </div>
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Convinience Fee</div>
                  <div className='w-50'>Free</div>
                </div>
              </div>
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Total Amount</div>
                  <div className='w-50 brick-clr'><strong>£ {cartTotals.total}</strong></div>
                </div>
              </div>
            </div>
            <div className='py-1 px-5'>
              <button className='lightbrick-btn f-xs w-100 mx-auto' onClick={checkCartCotainsPreOrder}>Place your order</button>
            </div>

            <div className='clr-898989 fw-500 f-xs mt-2 d-flex justify-content-center'>Need help? <span className='underline' onClick={() => navigate('/faq-contact')} role="button">Contact Us</span></div>

          </div>
        }
      </div>
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={loadData} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={loadData} />}
      {isDeleteConfModalOpen && <DeleteConfirmModal resourceName={'order'} setterFunction={setIsDeleteConfModalOpen} onDeleteFunction={deleteItem}></DeleteConfirmModal>}
      {isPreOrderWarningPopupShown && <CartPreOrderWarningPopup setterFunction={showPreOrderWarningPopup} okClickedFunction={proceedToHomeDeliveryCheckout} />}
     
      {isLoading && <FixedOverlayLoadingSpinner />}
    </div>
  )

}

export default Cart
