import React, { Component } from 'react'
import './Catfish.css'
import MultiRangeSlider from "multi-range-slider-react";
import { Link } from 'react-router-dom'
import axios from 'axios';
import Cookies from 'js-cookie';

export class Catfish extends Component {



  constructor(props) {
    super(props);
    this.state = {
      minValue: 0,
      maxValue: 1000,
      products: [],
      categories: [],
      subcategories: [],
      productimages: [],
      DataisLoaded: false,
      quantity: 0,
      category: '',
      subcategory: '',
      isChecked: false,
      pageIndex: 1,
      pageSize: 6,

    };
    this.minRef = React.createRef()
    this.maxRef = React.createRef()
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
  }

  componentDidMount() {
    const storedData = localStorage.getItem('apiData');

  // Check if data is available in local storage
  if (storedData) {
    const apiData = JSON.parse(storedData);
    console.log('Data from local storage:', apiData);
    const Id = apiData.user_id;
    this.setState({ Id: Id});
    this.getDataList(Id)
  } else {
    console.log('Data not found in local storage');
  }
  
    this.getDataList();

  }

  setupPaginator() {
   
    const total = 115;
    // var pagi = document.querySelector(".pagination")
    var pagi = document.getElementById("pagination")
    const NoOfPages = Math.ceil(total / this.state.pageSize)
    let indexs = "<li value='0'>«</li>"
    for (var i = 1; i <= NoOfPages; i++) {
      indexs += "<li value=" + i + ">" + i + "</li>"
    }
    indexs += "<li value='-1'>»</li>"
    pagi.innerHTML = indexs;
    let childs = pagi.childNodes
    for (i = 0; i < childs.length; i++) {
      if (i === 1) {
        childs[i].classList.add("active")
      }
      childs[i].addEventListener("click", this.handlePaginator.bind(this))
    }
  }
  handlePaginator(event) {
  
    if (this.state.pageIndex !== event.target.value) {
      var preIndex = this.state.pageIndex
      var newIndex = 0
      if (event.target.value === 0) {
        newIndex = this.state.pageIndex - 1

      }
      else if (event.target.value === -1) {
        newIndex = this.state.pageIndex + 1
      }
      else {
        newIndex = Number(event.target.value)
      }
      var pagi = document.getElementById("pagination")
      let childs = pagi.childNodes
      if (newIndex > 0 && newIndex < childs.length - 1) {
        this.setState({ pageIndex: newIndex })
        childs[preIndex].classList.remove("active");
        childs[newIndex].classList.add("active");
      }
    }
  }

  AddtoWishCart(id){
    try {

      
      axios.post('users/<userID>/products/' +  id + '/add-to-wishlist/',

       {
        headers: { 'Content-Type': 'application/json', 'X-CSRFToken': Cookies.get('csrftoken'),

      }

      }).then(response => {
        if (response.data) {
          // this.props.navigation('/layout2/Addproduct?Id=' + response.data.id );
          this.getDataList(this.state.Id);

        }
        else {
          // Handle errors (e.g., show an error message)
        }

      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    } catch (error) {
    }
  }
  getDataList() {
    const authToken = '36eed37a4c0c0520c909196da47d44b90cefa2cd';
    const headers = {
      'Authorization': `Token ${authToken}`
    };
   
      axios.get('https://friendsmalayali.uk/published-products/?page=' + this.state.pageIndex + '&page_size=' + this.state.pageSize,
      { headers })
      .then(response => {
        this.setState({ products: response.data, });
        this.getProductImage();

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  getProductImage() {
    const authToken = '36eed37a4c0c0520c909196da47d44b90cefa2cd';
    const headers = {
      'Authorization': `Token ${authToken}`
    };
    axios.get('https://friendsmalayali.uk/product-images/',
      { headers })
      .then(response => {
        this.setState({ productimages: response.data, DataisLoaded: true });
        // this.getSubCategoryList();
        setTimeout(() => {
          this.setupPaginator();
        }, 1);

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

  }

  handleCheckboxChange(event) {


    if (event.target.checked) {
      event.target.parentElement.nextSibling.style.display = "block"
      this.getDataListByCategory(event.target.value)
    }
    else {
      event.target.parentElement.nextSibling.style.display = "none"
      this.getDataList()
    }


  }
  getDataListByCategory(id) {
    const authToken = '36eed37a4c0c0520c909196da47d44b90cefa2cd';
    const headers = {
      'Authorization': `Token ${authToken}`
    };
    axios.get('https://friendsmalayali.uk/published-products/?page=1&page_size=10&category=' + id, { headers })
      .then(response => {
        this.setState({ products: response.data, });
        this.getProductImage();
      })
      .catch(error => {
        console.error('Error making API call', error);
      });
  }

  myFunction() {
    document.getElementsByClassName("div1")[0].classList.toggle("open");
  }

  // state = { values: [0, 1000] };

  handleInput(e) {
    this.minRef.current.value = '$ ' + e.minValue;
    this.maxRef.current.value = '$ ' + e.maxValue;
  };

  render() {
    const { DataisLoaded, products, categories, productimages } = this.state;
    
    return (
      <div className='catoffer catfish'>

        <div className='w-100 banner-section'>
          <div className=''>
            <div className='heading mb-1 mb-lg-3'>
              <span className='brick-clr'><b>Discover Unbeatable Fish<br></br>& Seafood Deals</b></span>
            </div>
            <div className='subheading fw-500'>
              Get ready to indulge in the freshest &<br></br> most delicious seafood at unbeatable prices
            </div>
            <div className='text-center text-md-start'>
              <button className='lightbrick-btn ff-poppins px-3 priceperkg fw-500 mt-3'>Explore Now <i className="fa-solid fa-chevron-right f-xxs fw-600"></i></button>
            </div>
          </div>
          <div><img src="/images/bannerfish.png" alt='' className='bannerimg'></img></div>

        </div>
        <div className='d-flex align-items-end justify-content-end d-block d-lg-none mt-5 mb-3'>
          <button className='lightbrick-btn-outline px-3 hamburger-btn' onClick={this.myFunction}><i className="fa-solid fa-filter me-2"></i>Filter</button>
        </div>
        <div className='w-100 d-flex mt-2 mt-lg-5'>
          <div className='div1 bg-white box-shadow radius-11 py-3 me-3'>
            <div className='bg-F9F9F9 py-2 px-3 f-xs fw-600'>Price <i className="fa-solid fa-sterling-sign brick-clr f-xs"></i></div>
            <div className='p-3'>
              <div className='w-100 relative d-flex'>
                <input ref={this.minRef} className='min-max' value="2" onChange={this.handlePriceChange} readOnly />
                <input ref={this.maxRef} className='min-max' value="2" onChange={this.handlePriceChange} readOnly />
              </div>

              <div>
                <MultiRangeSlider
                  min={0}
                  max={1000}
                  ruler={false}
                  labels={false}
                  label={false}
                  onInput={(e) => {
                    this.handleInput(e);
                  }} />
              </div>
            </div>
            <div className='bg-F9F9F9 py-2 px-3 f-xs fw-600'>Best offers</div>
            <div className='p-3'>
              <div className="comment-form-cookies-consent d-flex mb-2">
                <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                <label htmlhtmlFor="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>Brisket (chopped)</label>
              </div>
              <div className="comment-form-cookies-consent d-flex mb-2">
                <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                <label htmlFor="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>beef heart</label>
              </div>
              <div className="comment-form-cookies-consent d-flex mb-2">
                <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                <label htmlFor="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>indian mackeral</label>
              </div>
              <div className="comment-form-cookies-consent d-flex mb-2">
                <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                <label htmlFor="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>silver promfret</label>
              </div>
            </div>
            <div className='bg-F9F9F9 py-2 px-3 f-xs fw-600'>Categories</div>
            <div className='p-3 category'>
              <label className='f-13 fw-500 black-clr d-flex'>
                {/* <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="catmeat" checked={this.state.isChecked} onChange={this.handleCheckboxChange} ></input> */}
                <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="2" onChange={this.handleCheckboxChange} ></input>
                Meat
              </label>
              <div className='subCat'>
                <label className='f-13 fw-500 black-clr d-flex'>
                  <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="meatitem1"></input>
                  Meat item1
                </label>
                <label className='f-13 fw-500 black-clr d-flex'>
                  <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="meatitem2"></input>
                  Meat item2
                </label>
                <label className='f-13 fw-500 black-clr d-flex'>
                  <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="meatitem3"></input>
                  Meat item3
                </label>

              </div>
              <div className="comment-form-cookies-consent d-flex mb-2">
                <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="1" onChange={this.handleCheckboxChange}></input>
                <label htmlFor="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>Fish</label>
              </div>
              <div className="comment-form-cookies-consent d-flex mb-2">
                <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                <label htmlFor="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>Sea shell</label>
              </div>
            </div>
            <div className='bg-F9F9F9 py-2 px-3 f-xs fw-600'>Availability</div>
            <div className='p-3'>
              <div className="comment-form-cookies-consent d-flex mb-2">
                <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                <label htmlFor="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>In stock</label>
              </div>
              <div className="comment-form-cookies-consent d-flex mb-2">
                <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                <label htmlFor="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>Out of stock</label>
              </div>
              <div className="comment-form-cookies-consent d-flex mb-2">
                <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                <label htmlFor="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>Preorder</label>
              </div>
            </div>
            <div className='bg-F9F9F9 py-2 px-3 f-xs fw-600'>Discount</div>
            <div className='p-3'>
              <div className="comment-form-cookies-consent d-flex mb-2">
                <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                <label htmlFor="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>10% to 20%</label>
              </div>
              <div className="comment-form-cookies-consent d-flex mb-2">
                <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                <label htmlFor="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>20% to 30%</label>
              </div>
              <div className="comment-form-cookies-consent d-flex mb-2">
                <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                <label htmlFor="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>30% to 50%</label>
              </div>
            </div>
            <div className='p-3 w-100 d-flex'>
              <button className='white-btn f-13 fw-500 w-50 me-2'>Cancel</button>
              <button className='lightbrick-btn f-13 fw-500 w-50'>Apply</button>
            </div>
          </div>
          <div className='w-75-resp-mob cardgrid radius-11 p-0 p-lg-3'>
            {/* <div className='bg-white py-2 px-3 f-sm fw-600 radius-11 w-100 d-flex justify-content-center mb-2'>Best offers</div> */}
            <div className="grid-container bestofferlist mb-4">
              {products.results&&products.results.length>0&&products.results.map((product) => (

                <div className="grid-item">
                  <div className='product-card'>
                    <div className='prod-img-bg relative'>
                      <button className='absolute heartposition' onClick={this.AddtoWishCart(product.id)}>
                        <i className="fa-regular fa-heart f-md"></i>
                      </button>
                      {productimages.map(productimage => {
                        return productimage.product === product.id ? <img src={productimage.image} alt=''></img> : ""
                      })}

                    </div>
                    <div className='prod-name'>{product.name}</div>
                    <div className='prod-desc'>{product.description}</div>
                    <div className='bg-f8f8f8 mt-2 mt-md-1 mb-3 mb-md-2 py-2'>
                      <div className='brick-clr fw-700 priceperkg'>£{product.price} <span className=''>(£8/kg)</span></div>
                    </div>
                    <div className='d-flex justify-content-center fontsize'>
                      <Link to={`/Layout5/Addcart?Id=${product.id}`} className='lightbrick-btn underline-none px-3 py-1 linkbtn h-0'>View Details</Link>
                      <div className='prod-kilo'>5Kg</div>
                    </div>
                    <div></div>
                  </div>
                </div>

              ))}







            </div>
            <div id="wrapper">


              <ul id="pagination">
                {/* <li > <a class="" href="#">«</a></li>
                <li><a href="#" class="active">1</a></li>
                <li><a href="#" >2</a></li>
                <li><a href="#">3</a></li>
                <li><a href="#">4</a></li>
                <li><a href="#">5</a></li>
                <li><a href="#">6</a></li>
                <li><a href="#">»</a></li> */}
              </ul>



            </div>

          </div>
        </div>

      </div>
    )
  }
}

export default Catfish