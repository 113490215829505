import React, { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie';
import axios from 'axios';
import EditPopup from '../../common-components/EditPopupForCategoriesAndSubCategories/EditPopup';
import API from '../../../API'
import FixedOverlayLoadingSpinner from '../../../FixedOverlayLoadingSpinner'
import PositiveModal from "../../../PositiveModal";
import DeleteConfirmModal from "../../../DeleteConfirmModal";
import ErrorModal from "../../../ErrorModal";
import { getCategoryName } from '../../../GeneralFunctions';
import $ from 'jquery';

const Subcategory = () => {
  const navigate = useNavigate()

  const [data, setData] = useState();
  const [selectedItem, setSelectedItem] = useState({ id: "",category:"", sub_category_name: "" })
  const [isFormPopupOpen, showFormPopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState("add");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [isMessagerModalOpen, setIsMessageModalOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [isDeleteConfModalOpen, setIsDeleteConfModalOpen] = useState(false)
  const selectCategory = (e) => {
    setSelectedItem({ ...selectedItem, category: e.target.value })
  };
  const enterSubCategoryName=(e)=>{
    setSelectedItem({ ...selectedItem, sub_category_name: e.target.value })
  };
  const [categories, setCategories] = useState()


  useEffect(() => {
    $(function() {
  $(window).scrollTop(0);
  });
  }, []) 

  useEffect(() => {
    loadCategories()
    loadTableData()
  }, [])




  const loadTableData = () => {
    setIsLoading(true)
    API.get('/subcategories/')
      .then(response => {
        setIsLoading(false)
        setData(response.data)

      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false)

      });
  }


  const loadCategories = () => {
    setIsLoading(true)
    API.get('/categories/')
      .then(response => {
        setIsLoading(false)
        setCategories(response.data)

      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false)

      });
  }
  const save = () => {
   if(selectedItem.sub_category_name&&selectedItem.category)
   {
    setIsLoading(true)
    if (mode == "add") {
      API.post('/subcategories/', {sub_category_name: selectedItem.sub_category_name, category:selectedItem.category})
        .then((response) => {
          showFormPopup(false)
    setIsLoading(false)

          loadTableData();
        })
        .catch((error) => {
          setMessage(error.response.data.message);
          setIsErrorModalOpen(true)
          setIsLoading(false)
        })
    }

    else {
      API.put(`/subcategories/${selectedItem.id}/`, {sub_category_name: selectedItem.sub_category_name, category:selectedItem.category})
        .then(response => {
          setSelectedItem(null);
          showFormPopup(false)
          setIsLoading(false)

          loadTableData()
        })
        .catch(error => {
          console.error('Error updating data:', error);
          setIsLoading(false)

        });
    }

  }
  else{
    setMessage("Fields canot be empty");
    setIsErrorModalOpen(true)
  }
  }









  const deleteItem = () => {
    setIsLoading(true)
    API.delete(`/subcategories/${selectedItem.id}`)
      .then((response) => {
        setIsLoading(false)
        setMessage("Item Deleted Successfully")
        setIsMessageModalOpen(true)
      })
      .catch((error) => {
        console.log("Error Deleting!")
      })

  }
  const handleClosePopup = () => {
    setSelectedItem(null)
    showFormPopup(false)
  };



  const deleteSubCategory = (item) => {
    setSelectedItem(item);
    setIsDeleteConfModalOpen(true)

  }




  return (
    <div className='px-0 px-md-4 py-3 full-screen-table-container'>
      <div className='text-end'>
        {/* <button className='lightbrick-btn f-xs px-4'>Add Product</button> */}
        <button className='lightbrick-btn f-xs px-4 add-prod-link' onClick={()=>{setMode("add");showFormPopup(true)}}>New SubCategory</button>
      </div>

      <div className='d-flex align-items-center my-3'>
        {/* <div className='relative w-100'>
          <input className='nav-search-inp w-100 fw-600' placeholder='Start typing search for customer' id='search'></input>
          <div className='search-i-position'><i className="fa-solid fa-magnifying-glass"></i></div>
        </div> */}
      </div>

      <div className='w-100 table-box'>
        <table className="rwd-table mb-2">
          <tbody id='table'>
            <tr>
              <th>SUB CATEGORY NAME</th>
              <th>CATEGORY NAME</th>
              <th className='text-center'>More</th>
            </tr>


            {
              data && data.results.map((item) => (
                <tr key={item.id} >

                  <td data-th="Sub category">{item.sub_category_name}</td>
                  <td data-th="Category">{getCategoryName(item.category, categories)}</td>
                  <td data-th="More" className='text-center'>
                    <button onClick={() => {setSelectedItem(item);setMode("edit");showFormPopup(true)}}><i className="fa-solid fa-pen clr-33363F"></i></button>
                    <button onClick={() => {deleteSubCategory(item)}}><i className="fa-solid fa-trash clr-33363F"></i></button>
                  </td>
                </tr>
              ))
            }
            <tr>
            </tr>
          </tbody>
        </table>
      </div>


      {/* Popup Modal */}

      {isFormPopupOpen &&
        <div className='custom-modal'>

          <div className='card'>
            <div className='main'>
              <button type="button" className="close"  onClick={()=>showFormPopup(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
              <form >

                <div className='mb-2'>
                  <div className='f-13 fw-500 mb-1 black-clr'>Category Name</div>
                  <select id="cars" className='inp-F0F0F0 w-100 p-2 fw-500'
                    name='category'
                    value={selectedItem.category}
                    onChange={selectCategory}
                    disabled={mode=="edit"}>
                    <option value="">Choose..</option>
                    {categories && categories.results.map((item) => (
                      <option value={item.id}>{item.category_name}</option>
                    ))}
                  </select>
                </div>

                <div className='mb-2'>
                  <div className='f-13 fw-500 mb-1 black-clr'>SubCategory Name</div>
                  <div>
                    <input className='inp-F0F0F0 w-100 p-2 fw-500' placeholder='Add/Edit SubCategory Name'
                     name='sub_category_name'
                     value={selectedItem.sub_category_name}
                     onChange={enterSubCategoryName}></input>
                  </div>
                </div>

                <button className='f-xs lightbrick-btn w-100' type='button' onClick={save}>Save/Edit</button>
              </form>

            </div>
          </div>
        </div>
      }

{isLoading && <FixedOverlayLoadingSpinner />}
      {isDeleteConfModalOpen&&<DeleteConfirmModal resourceName={'category'}  setterFunction={setIsDeleteConfModalOpen} onDeleteFunction={deleteItem}></DeleteConfirmModal>}
      {isMessagerModalOpen&&<PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() =>   loadTableData()} />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={loadTableData} />
    
    </div>
  )
}


export default Subcategory