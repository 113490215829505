import React, { Component, useEffect, useState,useRef } from 'react'
import './ScrollingHeader.css'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import API from '../../../API'
import $ from 'jquery';
//import profileimg from '../../images/profile/profile-img.jfif';
//import { Link } from 'react-router-dom'

const Header = ({ setTripwiseAvailability }) => {
 
  // For Security popup, not implemented, but the states are just to make the switch UI work
  let navigate = useNavigate();


  const [upcomingTrips, setUpcomingTrips] = useState(null)

  const scrollerRef = useRef(null);
  const cloneRef = useRef(null);

  const loadUpcomingTrips = () => {
    setUpcomingTrips([])
    let url;

    API.get('/upcoming_trips/')
      .then(response => {
        setUpcomingTrips(response.data)
      })
      .catch(error => {
        console.error(error);
      });
  }

  useEffect(()=>{
loadUpcomingTrips()
  },[])

  return (
    <div className='scrolling-header'>
     
     <div className='location-box'>
                  <div className='black-box'>
                    <img src='/images/scrolling-header/upcoming.png'/>
                    {upcomingTrips && upcomingTrips.results && upcomingTrips.results.length > 0 ?
                      <div className="scroller-container">
                        <div className="scroller" ref={scrollerRef}>
                          <div>
                            { upcomingTrips.results.map((item, index) => (
                              <div className="scroller-item" key={index}>
                                {item.route_name},&nbsp;{item.location_name}&nbsp; on {item.delivery_date} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;|
                              </div>
                            ))}
                          </div>
                          <div ref={cloneRef}>
                          { upcomingTrips.results.map((item, index) => (
                              <div className="scroller-item" key={index}>
                                                             {item.route_name},&nbsp;{item.location_name}&nbsp; on {item.delivery_date}

                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      // (todaysTrip.results[0].location.location_name) 

                      : "No Upcoming Deliveries"}

                  </div>
                  <button className='brick-btn ff-poppins f-14 fw-500' onClick={() => {
                    if (upcomingTrips && upcomingTrips.results.length == 1) {
                      setTripwiseAvailability(upcomingTrips.results[0].trip_id);
                    }
                    navigate('/shop')
                  }}>ORDER&nbsp;NOW<svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.0466 13.3651C14.558 13.8533 14.558 14.6448 15.0466 15.1329C15.5352 15.6211 16.3274 15.6211 16.816 15.1329L23.0535 8.90233C23.5421 8.41418 23.5421 7.62272 23.0535 7.13457L16.7967 0.867108C16.3081 0.378952 15.5159 0.378952 15.0272 0.867108C14.5386 1.35526 14.5386 2.14672 15.0272 2.63487L19.1482 6.76845L2.10158 6.76845C1.44897 6.76845 0.919921 7.3281 0.919921 8.01845C0.919921 8.70881 1.44897 9.26845 2.10158 9.26845L19.1482 9.26845L15.0466 13.3651Z" fill="white" />
                    </svg>
                  </button>
                </div>
    </div>
  )
}


export default Header


