import React, { Component, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import API from '../../../API';
import LoadingSpinner from "../../../LoadingSpinner";
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import CancelTripPopup from "../../common-components/CancelTripPopup";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner";
import Pagination from '../../../Pagination'
import { convertTo12HourTime, getCategoryName } from '../../../GeneralFunctions'
import "../../common-components/AdminListPage.css"
import "./TripList.css"
import $ from 'jquery';
import { UK_COUNTIES } from '../../../Constants';

import ReminderNotificationConfirmPopup from '../../common-components/ReminderNotificationConfirmPopup'
const TodaysDelivery = () => {

  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({
    date_group: null,
    county: null,
    date: null,
    Trip_start_time: null,
    page: 1,
    pageSize: 12
  });


  const [searchKey, setSearchKey] = useState(null);


  const [categories, setCategories] = useState(null);

  const [counties, setCounties] = useState(null);

  const [idSelected, setIdSelected] = useState(0);

  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isDeleteConfModalOpen, setIsDeleteConfModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);






  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isCustomersModalOpen, setIsCustomersModalOpen] = useState(false);
  const [isProductPopupTableShown, setIsProductPopupTableShown] = useState(false)

  const [tripProducts, setTripProducts] = useState(null)
  const [tripCustomers, setTripCustomers] = useState(null)

  const [isReminderNotificationConfModalOpen, setIsReminderNotificationConfModalOpen] = useState(false)
  
  
  useEffect(() => {
    $(function() {
  $(window).scrollTop(0);
  });
  }, []) 
  
  useEffect(() => {
    loadTableData();
    getCategoryList();
    getCountyList();
  }, [filters]);

  useEffect(() => {
    console.log("tripProducts", tripProducts)
  }, [tripProducts])


  const loadTableData = () => {
    let apiUrl = `/trips/?page=${filters.page}&page_size=${filters.pageSize}`;
    for (let filter in filters) {
      if (filters[filter] !== null) {
        apiUrl += `&${filter}=${filters[filter]}`;
      }
    }


    setIsLoading(true)
    API.get(apiUrl)
      .then(response => {
        setData(response.data);
        setIsLoading(false)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false)

      });
  }

  const getCategoryList = () => {
    API.get('/categories/')
      .then(response => {
        setCategories(response.data)
       

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  const getCountyList = () => {
    setIsLoading(true)
    API.get('/counties')
      .then(response => {
        setCounties(response.data);
        setIsLoading(false)
      })
      .catch(error => {
        setMessage(error.message);
        setIsErrorModalOpen(true);
        setIsLoading(false)
      });
  }

  const loadCustomerPopup = (id) => {

    setIsLoading(true)
    API.get(`/customers_per_trip/${id}`)
      .then(response => {
        setTripCustomers(response.data);
        setIsLoading(false)
        setIsCustomersModalOpen(true)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false)

      });
  }

  const loadProductPopup = (id) => {
    setIsLoading(true)
    API.get(`/trips/${id}`)
      .then(response => {
        let DATA = response.data;
        DATA.total_quantity = 0;
        DATA.total_price = 0;

        for (let i = 0; i<(DATA.items.length); i++) {
          console.log("i", i)
          console.log("proice", DATA.items)
          DATA.total_quantity += parseInt(DATA.items[i].day_opening_stock);
          DATA.total_price += parseInt(DATA.items[i].price);
        }
        setTripProducts(DATA);
        setIsLoading(false)
        setIsProductModalOpen(true)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false)

      });

  }

  const cancelTrip = () => {
    API.post(`/cancel-trip/${idSelected}/`)
      .then(response => {
        setMessage("The trip is cancelled successfully");
        setIsMessageModalOpen(true)
      })
      .catch(error => {
        setMessage(error.message);
        setIsErrorModalOpen(true);
      });
  }

  
  return (
    <div className='container full-screen-table-container'>
      <div className='row justify-content-between mb-3 d-block d-md-flex'>
        <h3 className=' mb-2 mb-md-0'>{filters.date_group === "today" ? "Today’s Delivery" : filters.date_group === "upcoming" ? "Upcoming Deliveries" : filters.date_group === "completed" ? "Delivery History" : "Trip List"} </h3>
        <div className='d-flex'>
          <select name="cars" id="cars" className='form-select form-select-sm f-13 date-group-filter ms-md-auto me-3 ' onChange={(e) => setFilters({ ...filters, date_group: e.target.value })}>
            <option value={null}>All </option>
            <option value="today">Today's Delivery </option>
            <option value="upcoming">Upcoming </option>
            <option value="completed">Delivery History</option>
            <option value="cancelled">Cancelled</option>

          </select>
          <Link to='/admin/trips/add' className='lightbrick-btn f-xs px-4 add-prod-link me-2 create-trip-btn' style={{height:"40px"}}><i className="fa-solid fa-plus me-2"></i>Create Trip</Link>
        </div>
      </div>

      <div className='row align-items-start my-3'>

        <div className='d-block d-md-flex align-items-center'>
          <p className='f-xs mb-0 '><strong>County:&nbsp;</strong></p>
          <select name="cars" id="cars" className='form-select form-select-md f-13 county-filter mb-3 mb-md-0 ' onChange={(e) => setFilters({ ...filters, location: e.target.value })}>

            <option value={""}>All </option>
            { counties&&counties.results.length>0&&counties.results.map((item, index) => {
              return (
                <option value={item.id} key={index}>{item.name}</option>
              )
            })}
          </select>

          <input type='date' className='form-control form-control-md date-filter mb-3 mb-md-0' placeholder='Delivery date'
            onChange={(e) => setFilters({ ...filters, date: e.target.value })}

          ></input>
          <input type='time' step="60" className='form-control form-control-md time-filter' placeholder='Start Time'
            onChange={(e) => setFilters({ ...filters, trip_start_time: e.target.value })}

          ></input>
        </div>
      </div>

      <div className='table-box'>
        <table className='w-100 rwd-table'>
          <thead>
            <tr>
              <th className='fw-600 f-13'>CUSTOMER LIST</th>
              <th className='fw-600 f-13'>PRODUCT LIST</th>
              <th className='fw-600 f-13'>ROUTE</th>
              <th className='fw-600 f-13'>STATUS</th>
              <th className='fw-600 f-13'>DATE</th>
              <th className='fw-600 f-13'>TIME</th>
              <th className='fw-600 f-13'>ACTION</th>
            </tr>
          </thead>
          <tbody id='table'>


            {
              data && data.results && data.results.map((item) => (
                <tr key={item.id} >
                  <td data-th="Customer List" onClick={() => loadCustomerPopup(item.id)} style={{ cursor: "pointer" }}><u>{item.number_of_customers}&nbsp;Customers</u></td>
                  <td data-th="Products List" onClick={() => loadProductPopup(item.id)} style={{ cursor: "pointer" }}><u>{item.total_number_of_products}&nbsp;Products</u></td>
                  <td data-th="Route">{item.location}</td>
                  <td data-th="Status"><div className={item.status == "today" ? "status-btn status-btn-green" : item.status == "upcoming" ? "status-btn status-btn-orange" : "status-btn status-btn-grey"}>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</div></td>
                  <td data-th="Date">{item.delivery_date}</td>
                  <td data-th="Time">{convertTo12HourTime(item.trip_start_time)}</td>
                  <td data-th="Action">

                  {item.status !== "completed" &&item.status !== "cancelled"&&
                  <div className="">
                      <button className='lightbrick-btn f-xs reminder-notification-btn mb-2' onClick={() => { setIdSelected(item.id); setIsReminderNotificationConfModalOpen(true) }}><img src='/images/admin-panel/trips/send-icon.svg'></img> Send Reminder</button>
                  
                  <button onClick={() => { setIdSelected(item.id); setIsDeleteConfModalOpen(true) }} className='lightbrick-btn f-xs reminder-notification-btn px-2'>
                          Cancel Trip
                        </button>
                      </div>
                    }

                      
                  </td>
                </tr>
              ))
            }




          </tbody>
        </table>
        <Pagination
          totalItems={data && data.count}
          pageSize={filters.pageSize}
          selectPageSize={(PS) => setFilters({ ...filters, pageSize: PS })}
          currentPage={filters.page}
          setCurrentPage={(CP) => setFilters({ ...filters, page: CP })}>
        </Pagination>
      </div>
      {/* Popup Modal */}







      {isProductModalOpen &&
        <div className='custom-modal products-modal'>
          <div className='card'>


            <div className='main-screen'>
              <div className='d-flex justify-content-between w-100 mb-3 bb-dashed pb-2'>
                <div className='fw-600'>Product Details</div>


              </div>

              <div className='header'>
                <div className='lhs'>
                  <p>TOTAL PRODUCT LIST</p>
                  <p>CATEGORY</p>
                  <p>TOTAL QUANTITY </p>
                  <p>TOTAL AMOUNT</p>

                </div>
                <div className='rhs'>
                  <p>{tripProducts.items.length}</p>
                  <p>{getCategoryName((tripProducts.items[0].category), categories)}</p>
                  <p>{tripProducts.total_quantity}</p>
                  <p>{tripProducts.total_price}</p>
                </div>
              </div>
              <button onClick={() => setIsProductPopupTableShown(!isProductPopupTableShown)}></button>
              <table>

                <tr>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Quantity</th>
                  <th>Price</th>
                </tr>
                {tripProducts && tripProducts.items.length > 0 && tripProducts.items.map((item, index) => {

                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.category}</td>
                      <td>{item.day_opening_stock} Kg.</td>
                      <td>£ {item.price}</td>
                    </tr>
                  )
                })}

              </table>
              <div className='footer single-button-footer'>
                <button type='button' className='ok-button' onClick={() => setIsProductModalOpen(false)} >Close</button>
              </div>
            </div>


          </div>



        </div>
      }









      {isCustomersModalOpen &&
        <div className='custom-modal customers-modal'>
          <div className='card'>


            <div className='main-screen'>

              {tripCustomers && tripCustomers.results && tripCustomers.results.map((item, index) => {
                return (
                  <div key={index}>
                    <p>{item.first_name} {item.last_name} </p>
                  </div>
                );
              })}
              <div className='footer single-button-footer'>
                <button type='button' className='ok-button' onClick={() => setIsCustomersModalOpen(false)} >Close</button>
              </div>
            </div>
          </div>
        </div>
      }

      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { navigate("/admin/trips/list") }} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => loadTableData()} />}
      {isLoading && <FixedOverlayLoadingSpinner />}
      {isDeleteConfModalOpen && <CancelTripPopup resourceName={'trip'} setterFunction={setIsDeleteConfModalOpen} onDeleteFunction={cancelTrip}></CancelTripPopup>}
      {isReminderNotificationConfModalOpen && <ReminderNotificationConfirmPopup setterFunction={setIsReminderNotificationConfModalOpen} tripId={idSelected}></ReminderNotificationConfirmPopup>}
    </div>
  )

}

export default TodaysDelivery