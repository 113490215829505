
import React, { useState } from 'react';
import "../../../CustomPopup.css";
import API from '../../../API'
import PositiveModal from "../../../PositiveModal"
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"
import $ from 'jquery';
import {useNavigate} from 'react-router-dom'
import ErrorModal from "../../../ErrorModal";
const LocationCollectionPopup = ({ setterFunction, okClickedFunction }) => {
   
   const navigate=useNavigate()
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSecondScreenShown, showSecondScreen] = useState(false)
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);



    const [selectedLocation, setSelectedLocation] = useState(null);

    const handleSubmit = async (e) => {
    
      let countyName=$("#county_name").val()
        if (!!countyName) {
    setIsLoading(true);
          API.post('/counties/', {name:countyName})
            .then(response => {
    
              setMessage("County is Added Succesfully!")
              setIsMessageModalOpen(true)
              setIsLoading(false)
            })
            .catch(error => {
              setIsLoading(false)
              console.error('Error fetching data:', error);
            });
        }
      }
    



    return (
        <div className='custom-modal'>
            <div className='card'>

                <div className='main-screen'>
                    <h1 className=''>Add County</h1>
                    <div className='mb-3'>
                        <label>County Name:&nbsp;</label>

                        <input
                            id="county_name"
                            name="county_name"
                           
                            className='inp-transparent p-2 f-13 w-100'></input></div>
                    <div className='footer '>
                        <button type='button' className='cancel-button' onClick={() => setterFunction(false)}>Cancel</button>
                        <button type='button' className='ok-button' onClick={handleSubmit}>Add</button>

                    </div>
                </div>


            </div>
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { navigate("/admin/routes/add"); window.location.reload() }} />}
           
            <ErrorModal message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
            {isLoading && <FixedOverlayLoadingSpinner />}

        </div>
    );
};

export default LocationCollectionPopup;

