import React from "react";
import { useState } from "react";
import { Link } from 'react-router-dom'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

import API from '../../API';


<script src="https://accounts.google.com/gsi/client" async defer></script>

const View = () => {

    let navigate = useNavigate();


    const [errors, setErrors] = useState({});


    const validateForm = (data) => {
        const errors = {};

        // Validate each field and add errors if necessary

        if (!data.email.trim()) {
            errors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = "Invalid email address.";
        }
        return errors;
    };


    const sendMail = (e) => {
        var email = $("#email").val();
        var data = { "email": email }

        e.preventDefault();

        // Validate the form fields
        const validationErrors = validateForm(data);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            API.post("/password-reset/",  data)
                .then((response)=>
                    navigate("/confirm-mail")
                )
                .catch((error)=>
                    setErrors({ "email": "Not found!" })
                )
        }
    }
    return (
        <>
           <div className='auth-container mail-confirmed'>
                    <div className='card auth-alert-page-card'>

                            <div className="body">
                                <Link to={"/login"} className="back-btn"><img src="/images/authentication-screens/back-icon.svg" ></img></Link>
                                {/* <img src="/images/authentication-screens/forgot-password-icon.svg"></img> */}
                                <div className='f-xl black-clr mb-2 ff-dmsans'><b>Forgot Password?</b></div>

                                <span>Enter your email address below and we’ll send you<br></br>
                                    password reset OTP </span>
                                <input placeholder="" className=" form-control mb-3" id="email"></input>

                                {errors.email && <div className="invalid-feedback d-block ms-5 mb-3">{errors.email}</div>}

                                <button className="brick-btn f-xs w-100" type="button" onClick={sendMail}>Reset Password</button>

                                <div className="footer">

                                <Link to="/login" className='black-clr underline-none f-xs forgetpass-anc'>Back to Log in?</Link>
                                </div>
                            </div>
                        </div>
                    </div>


               
        </>
    )
}

export default View;