import React, { Component, useEffect, useState } from 'react'
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import API from '../../../API'
import ErrorModal from '../../../ErrorModal'
import FixedOverlayLoadingSpinner from '../../../FixedOverlayLoadingSpinner'
import './CheckoutAddress.css'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom'
const CheckoutAddress =()=> {
  
  const navigate=useNavigate()

  const [data, setData]=useState(null);

  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isDeleteConfModalOpen, setIsDeleteConfModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    $(function() {
  $(window).scrollTop(0);
});
}, [])
  const loadData=()=>{
    setIsLoading(true)
    API.get(`checkout/`)
            .then(response => {
               setData(response.data);
               setIsLoading(false)
            }
            )
            .catch(error => {
               setMessage(error.response.data.message?error.response.data.message:"Something Went wrong!")
               setIsErrorModalOpen(true)  
               setIsLoading(false)
               
            }
            )

  }
  useEffect(()=>{

  loadData()
  },[])

  const submitClicked = () => {
    setIsLoading(true);
    
    API.post('create_order/')

    .then(response => {
       setIsLoading(false);
       localStorage.setItem("createOrderResponse_order_id", response.data.order_id)
       localStorage.setItem("createOrderResponse_total", response.data.total)

       navigate('/checkout-payment')
      
    }
    )
    .catch(error => {
      setMessage(error.response.data.message)
      setIsErrorModalOpen(true)
    }

    )

  }
    return (
      <div className='checkout cartsddress'>
        <div className='my-5 mx-0 mx-lg-5 d-flex justify-content-center'>
          <ProgressBar percent={50}>
            <Step>
              {({ accomplished, index }) => (
                <div
                  className={`indexedStep ${accomplished ? "accomplished" : null}`}
                >
                  {index + 1}
                </div>
              )}
            </Step>
            <Step>
              {({ accomplished, index }) => (
                <div
                  className={`indexedStep ${accomplished ? "accomplished" : null}`}
                >
                  {index + 1}
                </div>
              )}
            </Step>
            <Step>
              {({ accomplished, index }) => (
                <div
                  className={`indexedStep ${accomplished ? "accomplished" : null}`}
                >
                  {index + 1}
                </div>
              )}
            </Step>
          </ProgressBar>
        </div>


        {/* <div className='w-60-resp-mob radius-11 box-shadow p-3 me-3 bg-white mb-3 mb-lg-0'>
              <div className='d-flex justify-content-between w-100 mb-2'>
                <div className='fw-500 f-xs'>Add shipping Address</div>
                <button><i className="fa-solid fa-pencil"></i></button>
              </div>

              <div className='d-flex justify-content-between w-100 mb-3'>
              <input className='w-50 inp-F0F0F0 p-2 me-3 f-xs' placeholder='First Name'></input>
                <input className='w-50 inp-F0F0F0 p-2 f-xs' placeholder='Last Name'></input>
              </div>
              <div className='d-flex justify-content-between w-100 mb-3'>
                <input className='w-50 inp-F0F0F0 p-2 me-3 f-xs' placeholder='Phone Number'></input>
                <input className='w-50 inp-F0F0F0 p-2 f-xs' placeholder='Email Address'></input>
              </div>
              <div className='d-flex justify-content-between w-100 mb-3'>
                <input className='w-50 inp-F0F0F0 p-2 me-3 f-xs' placeholder='Address(Area and street)'></input>
                <input className='w-50 inp-F0F0F0 p-2 f-xs' placeholder='Postal Code'></input>
              </div>
              <div className='d-flex justify-content-between w-100 mb-3'>
                <input className='w-50 inp-F0F0F0 p-2 me-3 f-xs' placeholder='City/District/Town'></input>
                <input className='w-50 inp-F0F0F0 p-2 f-xs' placeholder='State'></input>
              </div>
              <div>
              <div className='fw-500 f-xs mb-2'>Address Type</div>
              <div className='d-flex flex-column flex-lg-row'>
                <div className="comment-form-cookies-consent d-flex mb-2 me-3">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="radio" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 f-13 fw-500 black-clr'>Home Delivery</label>
                </div>
                <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="radio" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 f-13 fw-500 black-clr'>Work ( Delivery between 10 to 5)</label>
                </div>
              </div>
              </div>
              <div className='d-flex justify-content-end mt-3'>
                <button className='lightbrick-btn f-13 fw-500 me-3 px-2'>Save Address</button>
                <button className='white-btn f-13 fw-500 px-3'>Cancel</button>
              </div>
              
            </div> */}
        {data &&
        <div className='w-40-resp-mob radius-11 box-shadow p-3 mb-3 bg-white mx-auto'>
          <div className='black-clr fw-500 f-xs mb-3'><span>Delivering To :</span></div>
          <div className='w-100 d-flex flex-column flex-lg-row'>
          
            <div className='w-100-resp-mob d-flex'>
              <button className=' me-2'><img src='/images/Checkout/address-icon.svg'></img></button>
              <div>
                <div className='brick-clr fw-500 f-xs'><span>{data.primary_address.first_name} {data.primary_address.last_name}</span><span className='bg-d5d5d5 ms-2 black-clr py-1 px-2 radius-7'>{data.primary_address.is_office?"Office":"Home"}</span></div>
                <div className='f-xxs clr-898989 fw-500 mt-2'>
                  {data.primary_address.number},&nbsp; {data.primary_address.address_line1}<br/>
                {data.primary_address.organisation},&nbsp;{data.primary_address.premise}<br/>
                {data.primary_address.street},&nbsp;{data.primary_address.postcode}<br/>
                 {data.primary_address.posttown},&nbsp;{data.primary_address.county}</div>

              </div>

            </div>
            {/* <div className='w-40-resp-mob text-end  mt-3 mt-lg-0 f-13'>
              <button className='lightbrick-btn mb-3 fw-500'>Deliver Here</button>
            </div> */}
          </div>
        </div>
}
        
        {data &&
          <div className='w-40-resp-mob radius-11 box-shadow p-3 mx-auto bg-white'>
            <div className='scootyman fw-500 f-lg d-flex align-items-center bb-dashed'>
              <strong>
                Ordering with ease,<br></br>
                delivering with <br></br>
                <span className='brick-clr'>pleasure</span>
              </strong>
            </div>
            <div className='py-3 px-5'>
              
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Sub Total</div>
                  <div className='w-50'>£ {data.order_summary.sub_total}</div>
                </div>
              </div>
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Discount</div>
                  <div className='w-50'>No Discount</div>
                </div>
              </div>
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Convinience Fee</div>
                  <div className='w-50'>Free</div>
                </div>
              </div>
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Total Amount</div>
                  <div className='w-50 brick-clr'><strong>£ {data.order_summary.total}</strong></div>
                </div>
              </div>
            </div>
            <div className='py-1 px-5'>
              <button className='lightbrick-btn f-xs w-100 mx-auto' onClick={submitClicked}>Continue</button>
            </div>

            <div className='clr-898989 fw-500 f-xs mt-2 d-flex justify-content-center'>Need help? <span className='underline' onClick={() => navigate('/faq-contact')} role="button">Contact Us</span></div>

          </div>
        }

<ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={()=>{}} />

{isLoading && <FixedOverlayLoadingSpinner />}


      </div>

    )
  
}

export default CheckoutAddress