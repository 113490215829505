import React, { Component, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import API from '../../../API';
import LoadingSpinner from "../../../LoadingSpinner";
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import CancelTripPopup from "../../common-components/CancelTripPopup";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner";
import Pagination from '../../../Pagination'
import { convertTo12HourTime, getCategoryName, formatDate } from '../../../GeneralFunctions'
import "../../common-components/AdminListPage.css"
import "./TicketsList.css";
import $ from 'jquery';


import ReminderNotificationConfirmPopup from '../../common-components/ReminderNotificationConfirmPopup'
import { fabClasses } from '@mui/material';
import DeleteConfirmModal from '../../../DeleteConfirmModal';
const TodaysDelivery = () => {

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({
    county: null,
    page: 1,
    pageSize: 10,
  });


  const [searchKey, setSearchKey] = useState(null);


  const [counties, setCounties] = useState(null);

  const [idSelected, setIdSelected] = useState(0);
  const [itemSelected, setItemSelected] = useState(null);

  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isDeleteConfModalOpen, setIsDeleteConfModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPostCodesListPopupShown, showPostCodesListPopup] = useState(false)











  useEffect(() => {
    loadTableData();
    getCountyList();
  }, [filters]);


  const loadTableData = () => {
    let apiUrl = `/tickets/?page=${filters.page}&page_size=${filters.pageSize}`;
    for (let filter in filters) {
      if (filters[filter] !== null) {
        apiUrl += `&${filter}=${filters[filter]}`;
      }
    }
    setIsLoading(true)
    API.get(apiUrl)
      .then(response => {
        setData(response.data);
        setIsLoading(false)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false)

      });
  }

  const getCountyList = () => {
    setIsLoading(true)
    API.get('/counties/')
      .then(response => {
        setCounties(response.data)
        setIsLoading(false)


      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false)

      });
  }






  const changeStatus = (id, value) => {

    // 
      let temp = { status: value }

      API.put(`/tickets/${id}/`, temp)
        .then(response => {
          if (value == "closed") {
             setMessage("The Ticket is closed, and intimated the customer via mail.");
          }
          else{
            setMessage("The Ticket is Reopened, and intimated the customer via mail.");
          }
          setIsMessageModalOpen(true)
        })
        .catch(error => {
          setMessage(error.response.data.message);
          setIsErrorModalOpen(true);
        });
    // }


  }

  return (
    <div className='container full-screen-table-container'>
      <div className='row justify-content-between mb-3 d-block d-md-flex'>
        <h3 className=' mb-2 mb-md-0'>Ticket List </h3>
        <div className='d-flex justify-content-end'>

          <Link to='/admin/routes/add' className='lightbrick-btn f-xs px-4 add-prod-link me-2 create-route-btn'><i className="fa-solid fa-plus me-2"></i>Create Route</Link>
        </div>
      </div>

      <div className='row align-items-start my-3'>

        <div className='d-block d-md-flex'>
          <p className='f-xs mb-0 '>County:&nbsp;</p>
          <select name="cars" id="cars" className='form-select form-select-sm f-13 county-filter mb-3 mb-md-0 ' onChange={(e) => setFilters({ ...filters, county: e.target.value })}>

            <option value={""}>All </option>
            {counties && counties.results.length > 0 && counties.results.map((county, index) => {
              return (
                <option value={county.id} key={index}>{county.name}</option>
              )
            })}
          </select>


        </div>
      </div>

      <div className='table-box'>
        <table className='w-100 rwd-table'>
          <thead>
            <tr>
              <th className='fw-600 f-13'>TICKET ID</th>
              <th className='fw-600 f-13'>TYPE</th>
              <th className='fw-600 f-13'>ORDER ITEM ID</th>
              <th className='fw-600 f-13'>CUSTOMER</th>
              <th className='fw-600 f-13'>INITIATED BY</th>
              <th className='fw-600 f-13'>CREATED AT</th>
              <th className='fw-600 f-13'>STATUS</th>

            </tr>
          </thead>
          <tbody id='table'>


            {
              data && data.map((item) => (
                <tr key={item.id} >
                  <td data-th="Id" onClick={() => { }} >{item.id}</td>
                  <td data-th="Type">{item.ticket_type}</td>
                  <td data-th="Item Id">{item.order_item}</td>
                  <td data-th="Customer">{item.customer_name}</td>
                  <td data-th="Initiated By">{item.initiated_by}</td>
                  <td data-th="Created At">{formatDate(item.created_at)}</td>
                  <td data-th="Status">
                    <select onChange={(e) => {changeStatus(item.id, e.target.value) }} value={item.status}>
                      <option value={"closed"} label='Closed'></option>
                      <option value={"in_progress"} label='In Progess'></option>
                    </select>
                    </td>
                </tr>
              ))
            }




          </tbody>
        </table>
        <Pagination
          totalItems={data && data.count}
          pageSize={filters.pageSize}
          currentPage={filters.page}
          setCurrentPage={(CP) => setFilters({ ...filters, page: CP })}>
        </Pagination>
      </div>
      {/* Popup Modal */}

      {isPostCodesListPopupShown &&
        <div className='custom-modal customers-modal'>
          <div className='card'>


            <div className='main-screen'>
              <h1>Postcodes</h1>
              {itemSelected && itemSelected.postcodes.length > 0 && itemSelected.postcodes.map((item, index) => {
                return (
                  <div key={index}>
                    <p>{item}</p>
                  </div>
                );
              })}
              <div className='footer single-button-footer'>
                <button type='button' className='ok-button' onClick={() => showPostCodesListPopup(false)} >Close</button>
              </div>
            </div>
          </div>
        </div>
      }

      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { navigate("/admin/tickets/list") }} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { { navigate("/admin/tickets/list"); window.location.reload() } }} />}
      {isLoading && <FixedOverlayLoadingSpinner />}
    </div>
  )

}

export default TodaysDelivery