
import './Shop.css'
import MultiRangeSlider from "multi-range-slider-react";


import React from "react";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import MainFilter from '../../common-component/MainFilter.js';
import { UserContext } from '../../../authentication/pages/UserContext.js';

import API from '../../../API.js';
import ErrorModal from "../../../ErrorModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import Empty from "../../../Empty.js";
import Pagination from "../../../Pagination.js";
import PositiveModal from "../../../PositiveModal";

import LoginPopup from "../../common-component/LoginPopup/LoginPopup.js"

import AddToCartTripSelectionPopup from '../../common-component/AddToCartTripSelectionPopup'



const Shop = ({ searchKey, setSearchKey, categorySelected, selectCategory, subCategorySelected, selectSubCategory, brandSelected, tripwiseAvailability, setTripwiseAvailability, loadUserData,userData}) => {
  const { isLoggedIn, login, logout } = useContext(UserContext);
  let navigate = useNavigate();
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, selectPageSize] = useState(12);
  const [sortingOrder, selectSortingOrder] = useState("newest")
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isDeleteConfModalOpen, setIsDeleteConfModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [liveTripsFilterList, setLiveTripsFilterList] = useState(null);
  const [units, setUnits] = useState();


  const [popularProducts, setPopularProducts] = useState([])
  const [recentlyViewed, setRecentlyViewed] = useState([])

  const [isLoginPopupShown, showLoginPopup] = useState(false)


  const [isAddToCartTripListPopupShown, showAddToCartTripListPopup] = useState(false);
  const [idSelected, setIdSelected] = useState(null);


  const [filters, setFilters] = useState({
    search_key: null,
    price_range: null,
    category: categorySelected,
    trip: tripwiseAvailability,
    quantity: null,
  });


  useEffect(() => {
console.log("liveTripsFilterList",liveTripsFilterList)
  },[liveTripsFilterList])
  
  useEffect(() => {

    API.get('/categories')
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {

        setMessage(error.message);
        setIsErrorModalOpen(true);

      });
    API.get('/subcategories')
      .then(response => {
        setSubCategories(response.data);
      })
      .catch(error => {

        setMessage(error.message);
        setIsErrorModalOpen(true);

      });

    API.get('/live_trips')
      .then(response => {
        setLiveTripsFilterList(response.data);
      })
      .catch(error => {
        setMessage(error.message);
        setIsErrorModalOpen(true);
      });

    setUnits([])
    API.get(`/units/`)
      .then(response => {
        setUnits(response.data.results)
      })
      .catch(error => {
        console.error(error);
      });


  }, [])


  useEffect(() => {
    if (categories) {
      setRecentlyViewed([])
      API.get(`/recently-viewed/${window.localStorage.getItem("userID")}/`)
        .then(response => {
          setRecentlyViewed(response.data)
        })
        .catch(error => {
          console.error(error);
        });

      setPopularProducts([])
      API.get(`/popular-products/`)
        .then(response => {
          setPopularProducts(response.data)
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [categories])


  const popularSectionSlideSettings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1, responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };



  const [tabSelected, setTabSelected] = useState(0);
  var isMainFilterShow = false


  const [mainFilterOpen, setMainFiterOpen] = useState(true)



  const [catPopupOpen, setCatPopupOpen] = useState(false)
 
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])

  useEffect(() => {
    if (window.innerWidth > 992) {
      setMainFiterOpen(true)
    }
    else {
      setMainFiterOpen(false)
    }
  }, [])

  //setting filter according to the props from layout
  useEffect(() => {
    setFilters(prevFilters => ({
      ...prevFilters, // Copy the existing state object
      category: categorySelected,
      search_key: searchKey,
      subcategory: subCategorySelected,
      brand: brandSelected,
      trip: tripwiseAvailability
    }));
  }, [categorySelected, searchKey, subCategorySelected, tripwiseAvailability])


  const loadData = () => {
    setData(null)
    console.log("filters", filters);

    let apiUrl = `/published-products/?page=${page}&page_size=${pageSize}`;

    // Loop through the filters object and append selected filters to the apiUrl
    for (let filter in filters) {
      if (filters[filter] && filters[filter] !== null) {
        apiUrl += `&${filter}=${filters[filter]}`;
      }
    }
   
    API.get(apiUrl)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        setError(error.message);
        setIsErrorModalOpen(true);
      });

  }
  useEffect(() => {

    loadData()

  }, [page, filters, pageSize, sortingOrder]);


  useEffect(() => {
    $(".tab-btn").removeClass("active");
    $(".tab-btn").eq(tabSelected).addClass("active");

    $(".tab-content").css("display", "none");
    $(".tab-content").eq(tabSelected).css("display", "block");

  }, [tabSelected])




  useEffect(() => {

    if (catPopupOpen == true) {

      $(".categories-popup").css("display", "block")
    }
    else {

      $(".categories-popup").css("display", "none")
    }


  }, [catPopupOpen]);



  $(document).mouseup(function (e) {
    var container = $(".categories-popup");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      setCatPopupOpen(false)
    }
  }
  )

  const handleInput = () => {

  }

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
  };



  const addOrRemoveWishList = (ID, isWishlisted) => {
    if (isWishlisted) {
      setIsLoading(true)

      API.post(`/remove-from-wishlist/${localStorage.getItem("userID")}/${ID}/`)
        .then(response => {
          setMessage("Item Removed from your Wishlist successfully");
          setIsMessageModalOpen(true)
          setIsLoading(false)
          loadData()
        })
        .catch(error => {
          setMessage(error.message);
          setIsErrorModalOpen(true);
          setIsLoading(false)
        });

    }
    else {
      if (isLoggedIn) {

        setIsLoading(true)
        API.post(`users/${window.localStorage.getItem("userID")}/products/${ID}/add-to-wishlist/`)
          .then(response => {
            setMessage("Item added to your wishlist successfully");
            setIsMessageModalOpen(true)
            setIsLoading(false)
            loadData()
          })
          .catch(error => {
            setMessage(error.response.data.message)
            setIsErrorModalOpen(true);
            setIsLoading(false)
          });
      }
      else {
        setMessage("Please login to add your favorite items to your wishlist!!")
        setIsErrorModalOpen(true)
      }
    }
  }




  useEffect(() => {
    // if(categories!==null){
    // let cattemp=categories.results.find(x=>x.category_name=="Fish")
    // console.log("cattemp",cattemp)
    // }
  }, [])

  return (
<>
   
    <div className='catoffer'>

{categories&&
<>
      {/* general banner */}
      {categorySelected == null &&
        <div className='w-100 banner-section'>
          <div className='w-100'>
            <div className='heading mb-1 mb-lg-3'>
              <span className='brick-clr d-none d-md-block'><b>Savour.Source.Tradition</b></span>
              <span className='brick-clr d-block d-md-none'><b>Savour<br/>Source<br/>Tradition</b></span>
            </div>
            <div className='subheading fw-500'>
              Indulge in flavoursome delights while enjoying exclusive offers.
            </div>

          </div>
          <div><img src="/images/banneroffer.png" alt='' className='bannerimg'></img></div>

        </div>
      }

      {/* fish banner */}
      {categorySelected == (categories && categories.results.find(x => x.category_name == "Fish").id) &&
        <div className='w-100 banner-section'>
          <div className=''>
            <div className='heading mb-1 mb-lg-3'>
              <span className='brick-clr'><b>Discover Unbeatable<br/>Fish & Seafood Deals</b></span>
            </div>
            <div className='subheading fw-500'>
              Get ready to indulge in the freshest and most delicious seafood at unbeatable prices.
            </div>
            
          </div>
          <div>
              <img src="/images/bannerfish.png" alt='' className='bannerimg'></img>
              </div>
        </div>
      }


      {/* meat banner */}
      {categorySelected == (categories && categories.results.find(x => x.category_name == "Meat").id) &&
        <div className='w-100 banner-section'>
          <div className=''>
            <div className='heading mb-1 mb-lg-3'>
              <span className='brick-clr'><b>Find Your Perfect<br />Meat Deal Here </b></span>
            </div>
            <div className='subheading fw-500'>
              Explore our wide selection of premium cuts and get incredible<br/> savings for your next culinary masterpiece.
            </div>

          </div>
          <div><img src="/images/bannermeat.png" alt='' className='bannerimg'></img></div>

        </div>
      }


      {/* seafood banner */}
      {categorySelected == (categories && categories.results.find(x => x.category_name == "Seafood").id) &&
        <div className='w-100 banner-section'>
          <div className=''>
            <div className='heading mb-1 mb-lg-3'>
              <span className='brick-clr'><b>Discover Unbeatable<br />Fish & Seafood Deals</b></span>
            </div>
            <div className='subheading fw-500'>
              Get ready to indulge in the freshest and most delicious seafood at unbeatable prices.
            </div>
          </div>
          <div><img src="/images/bannerseafood.png" alt='' className='bannerimg'></img></div>

        </div>
      }

</>
}
      <div className='w-100 d-flex justify-content-between mt-3'>

        <div className="col-filter">

          {categories && liveTripsFilterList && mainFilterOpen &&
            <MainFilter

              categoryList={categories.results}
              categorySetterFunction={selectCategory}

              subCategorySetterFunction={selectSubCategory}

              liveTripsFilterList={liveTripsFilterList.results}
              filters={filters}
              setFilters={setFilters}
              setTripwiseAvailability={setTripwiseAvailability}
              setMainFiterOpen={setMainFiterOpen}
              userData={userData}
            >

            </MainFilter>
          }
        </div>

        <div className='w-75-resp-tab radius-11 ps-3'>
          <div className='bg-white py-2 px-3 f-sm fw-600 radius-11 w-100 d-flex justify-content-center mb-4'>Choose From Our Best Products <button className='filter-button ms-auto me-1 d-lg-none' onClick={() => setMainFiterOpen(!mainFilterOpen)}><img src='/images/shop/filter-icon.svg'></img></button></div>
          <div className="grid-container bestofferlist mb-4">

            {data &&
              data.results.length > 0 && data.results.map((item, index) => {
                return (
                  <div className="grid-item" >
                    <div className='product-card' key={index}>
                      <div className='prod-img-bg'>
                        <img src={`https://sibufishnmeat.co.uk${item.images[0].url}`} alt='' onClick={() => { window.localStorage.setItem("productId", item.id); navigate("/view-product"); window.location.reload() }} role='button' />
                        <div className='absolute heartposition'>
                          {item.is_wishlisted ?
                            <img src='/images/add-to-wishlist-icon-filled.svg' role='button' onClick={() => addOrRemoveWishList(item.id, item.is_wishlisted)}></img>
                            :
                            <img src='/images/add-to-wishlist-icon.svg' role='button' onClick={() => addOrRemoveWishList(item.id, item.is_wishlisted)} ></img>
                          }
                        </div>
                        <div className='absolute halal'>
                    {item.halal &&
                      <img src='/images/halal-logo.svg' role='button'></img>
                    }
                  </div>
                      </div>
                      <div className='prod-name'>{item.name}</div>
                      {/* <div className='prod-desc'>ബീഫ് ഹൃദയം</div> */}
                      <div className='bg-f8f8f8 mt-1 mb-2 py-2'>
                        <div className='brick-clr fw-700 f-14'>£{item.price} <span className=''>(£{Math.round(((item.price / item.quantity) + Number.EPSILON) * 100) / 100}/kg)</span></div>
                      </div>
                      <div className='d-flex justify-content-center fontsize'>
                        <Link className='lightbrick-btn underline-none px-3 py-1 linkbtn h-0' onClick={() => { if (isLoggedIn) {{ setIdSelected(item.id); showAddToCartTripListPopup(true) }} else { showLoginPopup(true) } }}>Add to bag</Link>
                        <div className='prod-kilo'>{item.quantity}Kg</div>
                      </div>
                      <div></div>
                    </div>
                  </div>

                )
              })}
          </div>

          {data && data.results.length > 1 &&
            <Pagination
              totalItems={data.count}
              pageSize={pageSize}
              currentPage={page}
              setCurrentPage={setPage}

              selectPageSize={selectPageSize}>

            </Pagination>
          }
        </div>
      </div>




{popularProducts && popularProducts.results&&popularProducts.results.length>6&&

      <div className='w-100 mt-4'>
        <div className='bg-F9F9F9 py-2 px-3 f-sm fw-600 radius-11 w-100 d-flex justify-content-center mb-3'>Most Popular</div>
        <div className='bestdealcarousal'>
          <Slider ref={slider => (slider = slider)} {...settings}>

            { popularProducts.results.map((item, index) => {
              return (
                <div className='product-card' key={index}>
                  <div className='prod-img-bg'>
                    <img src={`https://sibufishnmeat.co.uk${item.images[0].url}`} alt='' onClick={() => { window.localStorage.setItem("productId", item.id); navigate("/view-product"); window.location.reload() }} role='button' />
                    <div className='absolute heartposition'>
                      {item.is_wishlisted ?
                        <img src='/images/add-to-wishlist-icon-filled.svg' role='button' onClick={() => addOrRemoveWishList(item.id, item.is_wishlisted)}></img>
                        :
                        <img src='/images/add-to-wishlist-icon.svg' role='button' onClick={() => addOrRemoveWishList(item.id, item.is_wishlisted)} ></img>
                      }
                    </div>
                    <div className='absolute halal'>
                    {item.halal &&
                      <img src='/images/halal-logo.svg' role='button'></img>
                    }
                  </div>
                  </div>
                  <div className='prod-name'>{item.name}</div>
                  {/* <div className='prod-desc'>ബീഫ് ഹൃദയം</div> */}
                  <div className='bg-f8f8f8 mt-1 mb-2 py-2'>
                    <div className='brick-clr fw-700 f-14'>£{item.price} <span className=''>(£{Math.round(((item.price / item.quantity) + Number.EPSILON) * 100) / 100}/kg)</span></div>
                  </div>
                  <div className='d-flex justify-content-center fontsize'>
                    <Link className='lightbrick-btn underline-none px-3 py-1 linkbtn h-0' onClick={() => { if (isLoggedIn) { showAddToCartTripListPopup(true) } else { showLoginPopup(true) } }}>Add to bag</Link>
                    <div className='prod-kilo'>{item.quantity}Kg</div>
                  </div>
                  <div></div>
                </div>
              )
            })}


          </Slider>

        </div>
      </div>
}
      {/* <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate('/shop')} /> */}
      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}
      {isAddToCartTripListPopupShown && <AddToCartTripSelectionPopup productId={idSelected} productCount={1} setterFunction={showAddToCartTripListPopup}  loadUserData={loadUserData}></AddToCartTripSelectionPopup>}
      {isLoginPopupShown && <LoginPopup redirectUrl={"/shop"} showLoginPopup={showLoginPopup}></LoginPopup>}



    </div>
          
          </>
  )

}

export default Shop
