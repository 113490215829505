import React, { Component, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import $, { error } from 'jquery';
import Cookies from 'js-cookie';
import axios from 'axios';
import API from '../../../API';
import LoadingSpinner from "../../../LoadingSpinner";
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import DeleteConfirmModal from "../../../DeleteConfirmModal";

import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"
import AddCountyPopup from './AddCountyPopup'
import "./RouteAdd.css"

const RouteAdd = ({ mode }) => {

  const navigate = useNavigate();

  const [data, setData] = useState(
    {
      name: null,
      county: null,

      postcodes: [

      ]
    }
  )
  const [searchKey, setSearchKey] = useState(null);


  const [counties, setCounties] = useState(null);

  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);


  const [formErrors, setFormErrors] = useState([])

  const [isAddCountyPopupOpen, openAddCountyPopup] = useState(false)

  const[postCodeErrors,setPostCodeFormErrors]=useState([])
  useEffect(() => {
    if (mode === "edit") {
      API.get(`/routes/${localStorage.getItem("itemSelectedId")}/`)
        .then((response) => {
          setData(response.data)
        })
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    API.get('/counties')
      .then(response => {
        setCounties(response.data);
        setIsLoading(false)
      })
      .catch(error => {
        setMessage(error.message);
        setIsErrorModalOpen(true);
        setIsLoading(false)
      });
  }, [])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const temp = { ...data }
    temp[name] = value
    setData(temp);
  };





  const validateForm = (data) => {
    const errors = {};

    // Validate each field and add errors if necessary
    if (!data.name) {
      errors.name = "Please Enter a Route Name.";
    }
    if (parseInt(data.county) == 0) {
      errors.county = "Please Choose a County.";
    }





    if (data.postcodes.length == 0) {
      errors.postcode_list = "Please add atleast one Postcode"
    }


    return errors;
  };

  const validateFormPostCodeOnly = (postcode) => {
    const errors = {};

    if (postcode == null) {
      errors.postcode = "Postcode is required.";
    } else if (postcode.includes(' ')) {
      errors.postcode = "Postcode should not contain spaces.";
    }
    console.log(errors)
    setPostCodeFormErrors(errors);
  };


  const handleSubmit = async (e) => {

    const validationErrors = validateForm(data);
    setFormErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {

      e.preventDefault();
      if (mode !== "edit") {

        setIsLoading(true);
        API.post('/routes/', data)
          .then(response => {

            setMessage("Route is Created Succesfully!")
            setIsMessageModalOpen(true)
            setIsLoading(false)
          })
          .catch(error => {
            setIsLoading(false)
            console.error('Error fetching data:', error);
          });

      }
      else {
        setIsLoading(true);
        API.put(`/routes/${localStorage.getItem("itemSelectedId")}/`, data)
          .then(response => {

            setMessage("Route is Updated Succesfully!")
            setIsMessageModalOpen(true)
            setIsLoading(false)
          })
          .catch(error => {
            setIsLoading(false)
            console.error('Error fetching data:', error);
          });
      }
    }
  }

  const onAddPostcode = async (e) => {
    setIsLoading(true)
    let postcode = $('#postcode').val()
    validateFormPostCodeOnly(postcode);

    if (Object.keys(postCodeErrors).length === 0) {
      console.log("postcodeerrors length 0")
      var temp = { ...data };
      temp.postcodes.push(postcode)
      setData(temp)
      setIsLoading(false)
      $('#postcode').val("")
    }
    else
      alert("Please Enter a Postcode")
    setIsLoading(false)
  }
  const onRemovePostcode = (index) => {
    var temp = { ...data }
    temp.postcodes.splice(index, 1)
    setData(temp)
  }


  return (
    <div className='m-4 bg-white p-3 radius-7 route-add'>
      <div className='f-md text-center mb-3 fw-600'>Create Route</div>
      <div className='row w-100 mb-4'>

        <div className='col-12 col-md-6 col-lg-3 mb-3'>
          <div className='f-13 fw-500'>Route Name</div>
          <input
            id="name"
            name="name"
            value={data.name}
            onChange={handleInputChange}
            className='inp-transparent p-2 f-13 w-100'></input>
          {formErrors.name && <div className="text-danger">{formErrors.name}</div>}

        </div>

        <div className='col-12 col-md-6 col-lg-3 mb-3'>
          <div className='f-13 fw-500'>County</div>
          <select className='inp-transparent p-2 f-13 w-100'
            name='county'
            id='county'
            value={data.county && data.county}
            onChange={handleInputChange}>
            <option value={0}>Choose..</option>
            {counties && counties.results.length > 0 && counties.results.map((county, index) => {
              return (
                <option value={county.id} key={index}>{county.name}</option>
              )
            })}




          </select>
          {formErrors.location && <div className="text-danger">{formErrors.location}</div>}
        </div>

        <div className='col-12 col-md-6 col-lg-3 mb-3 mt-4'>
          <button className='lightbrick-btn' onClick={() => openAddCountyPopup(true)}>Add County</button>

        </div>


      </div>



      <div className='f-13 fw-500'>Add Postcodes</div>




      <div className='row mb-1 d-flex'>

        <div className='col-12 col-md-6 col-lg-4 mb-3'>

          <input className='inp-D9D9D9 w-100 f-13' maxlength="8" placeholder='Postcode' id="postcode" name='postcode' type='text' ></input>
          {postCodeErrors.postcode && <div className='text-danger'>{postCodeErrors.postcode}</div>}


        </div>

        <div className='col-12 col-md-6 col-lg-3 mb-lg-0 mb-3'>
          <button className="btn-add f-xs lightbrick-btn mb-3 w-100" type="button" onClick={onAddPostcode}>
            <i className="fa-solid fa-square-plus"></i> Add
          </button>
        </div>
      </div>











      <div className='w-100 postcodes-box'>
        {data && data.postcodes.length > 0 && data.postcodes.map((item, index) => {
          return (
            <div className='postcode-tag' key={index}>
              {item}
              <button onClick={() => onRemovePostcode(index)}>x</button>
            </div>
          )
        })
        }
      </div>

      <div className='w-100 '>
        <div className='d-flex justify-content-end w-100'>
          <button type='button' onClick={handleSubmit} className='f-xs lightbrick-btn mb-3 px-2'>Save</button>

        </div>
      </div>


      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { navigate("/admin/routes/list") }} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { navigate("/admin/routes/list") }} />}
      {isLoading && <FixedOverlayLoadingSpinner />}
      {isAddCountyPopupOpen && <AddCountyPopup setterFunction={openAddCountyPopup}></AddCountyPopup>}

    </div >
  )

}

export default RouteAdd