import React, { useState } from 'react';


import { Link } from 'react-router-dom'


import { useContext } from 'react';
import { UserContext } from '../../../authentication/pages/UserContext';

import '../../../authentication/pages/Authentication.css'
const LoginPopup = ({ onClose, redirectUrl, showLoginPopup }) => {


    const { login, isOTPInputShown, showOTPInput, otpTimer, otpTimeout, setRedirectUrl } = useContext(UserContext);

    setRedirectUrl(redirectUrl)

    const [errors, setErrors] = useState({});
    const [passwordShown, showPassword] = useState(false);
    const [formData, setFormData] = useState({
        username_or_email: "",
        password: "",
    });

    const [isGuestFormShown, showGuestForm] = useState(false)

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    showOTPInput(false);

    const validateLoginForm = (data) => {
        const errors = {};

        if (!data.username_or_email.trim()) {
            errors.username_or_email = "username or email is required.";
        }
        else if (!/^[a-zA-Z][a-zA-Z0-9]*$/.test(data.username)) {
            errors.general = "Invalid username or password" //Username must start with a letter and can only contain letters and numbers. Error details are not showing to user because security reason
        }
        else if (/^\d+$/.test(data.username_or_email)) {
            errors.general = "Invalid username or password" //username or email cannot be full of numbers.  Error details are not showing to user because security reason
        }

        if (!data.password.trim()) {
            errors.password = "Password is required.";
        }
        else if (data.password.length < 8) {
            errors.general = "Invalid username or password"//Password must be at least 8 characters. Error details are not showing to user because security reason
        }
        else {
            // Password must contain at least one letter, one number, and one special character Error details are not showing to user because security reason
            if (!/[a-zA-Z]/.test(data.password)) {
                errors.general = "Invalid username or password"//Password must contain at least one letter."; Error details are not showing to user because security reason
            }
            else if (!/\d/.test(data.password)) {
                errors.general = "Invalid username or password" //Password must contain at least one number. Error details are not showing to user because security reason
            }
            else if (!/[!@#$%^&*]/.test(data.password)) {
                errors.general = "Invalid username or password" //Password must contain at least one special character (!@#$%^&*). Error details are not showing to user because security reason
            }
        }

        return errors;
    };

    const handleLoginSubmit = () => {
        // Validate the form fields
        const validationErrors = validateLoginForm(formData);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {



            login(formData); // Call the login function from UserContext

        }
    }

    const handleGuestLoginSubmit = () => {
        // Implement your guest login logic here
        console.log('Logging in as a guest');
        onClose();
    };

    return (
        <div style={styles.overlay} className='auth-container'>
            <div style={styles.popup}>


               
                    <div className='w-100 '>
                        <div className='d-flex justify-content-between align-items-center'>
                            <h3> Login </h3>
                            <button className='btn' style={styles.closeButton} onClick={() => { showLoginPopup(false); showGuestForm(false) }}> X</button>
                        </div>
                        <form noValidate>

                            <label htmlFor="username_or_email" className="form-label lightgrey-clr f-xxs text-start">USER NAME/EMAIL</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    id="username_or_email"
                                    name="username_or_email"
                                    className={`form-control ${errors.username_or_email ? "is-invalid" : ""}`}
                                    value={formData.username_or_email}
                                    onChange={handleChange}

                                />
                                <span className="input-group-text">
                                    <img className="icon" src="/images/authentication-screens/user-icon.svg" alt="user-icon" />
                                </span>
                                {errors.username_or_email && <div className="invalid-feedback">{errors.username_or_email}</div>}

                            </div>

                            <label htmlFor="password" className="form-label lightgrey-clr f-xxs text-start">PASSWORD</label>
                            <div className="input-group">
                                <input
                                    type={passwordShown ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    className={`form-control ${errors.password ? "is-invalid" : ""}`}
                                    value={formData.password}
                                    onChange={handleChange}

                                />
                                <span className="input-group-text" onClick={() => showPassword(!passwordShown)} style={{ cursor: "pointer" }}>

                                    {passwordShown ?

                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 12.668C1 12.668 5 4.66797 12 4.66797C19 4.66797 23 12.668 23 12.668C23 12.668 19 20.668 12 20.668C5 20.668 1 12.668 1 12.668Z" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12 15.668C13.6569 15.668 15 14.3248 15 12.668C15 11.0111 13.6569 9.66797 12 9.66797C10.3431 9.66797 9 11.0111 9 12.668C9 14.3248 10.3431 15.668 12 15.668Z" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        :


                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_1777_2198)">
                                                <path d="M17.94 18.608C16.2306 19.911 14.1491 20.6329 12 20.668C5 20.668 1 12.668 1 12.668C2.24389 10.3499 3.96914 8.3246 6.06 6.72799M9.9 4.90799C10.5883 4.74687 11.2931 4.66633 12 4.66799C19 4.66799 23 12.668 23 12.668C22.393 13.8036 21.6691 14.8727 20.84 15.858M14.12 14.788C13.8454 15.0827 13.5141 15.3191 13.1462 15.4831C12.7782 15.6471 12.3809 15.7353 11.9781 15.7424C11.5753 15.7495 11.1752 15.6754 10.8016 15.5245C10.4281 15.3736 10.0887 15.149 9.80385 14.8641C9.51897 14.5793 9.29439 14.2399 9.14351 13.8664C8.99262 13.4928 8.91853 13.0927 8.92563 12.6899C8.93274 12.2871 9.02091 11.8898 9.18488 11.5218C9.34884 11.1538 9.58525 10.8226 9.88 10.548" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M1 1.66797L23 23.668" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1777_2198">
                                                    <rect width="24" height="24" fill="white" transform="translate(0 0.667969)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    }

                                </span>
                                {errors.password && <div className="invalid-feedback">{errors.password}</div>}



                            </div>
                            {errors.general && <div className="text-danger">{errors.general}</div>}

                            <Link to={"/forgot-password"} className="forgot-pswd">Forgot Password?</Link>

                            <br></br>

                            <button className="brick-btn f-xs w-100 mt-4" type='button' onClick={handleLoginSubmit}>LOG IN </button>




                            <Link to={"/sign-up"} className="forgot-pswd">Don't have an account? Please sign up <u>here</u></Link>

                        </form>


                        {/* feature of Guest login disabled */}
                        {/* <div className="or-seperator">
                            <span className="left-line">  </span>
                            <span className="text">OR</span>
                            <span className="right-line"></span>
                        </div>


                        <button className='form-control btn btn-secondary w-100 text-center mt-4' type='button' onClick={() => showGuestForm(true)} >Guest Login</button>
                   */}
                    </div> 


                        
                    </div>

        </div>
            );
};

            const styles = {
                overlay: {
                position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 3
    },
            popup: {
                backgroundColor: '#eee',
            padding: '20px',
            borderRadius: '8px',
            textAlign: 'center',
            marginTop: "70px"
    },
            buttonContainer: {
                marginTop: '15px',
    },
            button: {
                margin: '0 10px',
            padding: '10px',
            cursor: 'pointer',
            backgroundColor:"#ff0000"
    },
            closeButton: {
                borderRadius: "30px",
            padding: "7px",
            borded: "none",
            boxShadow: "none"
    }
};

            export default LoginPopup;
