// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/Orders/banner-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wishlist-banner{\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") !important ;\r\n  }\r\n\r\n  .wishlist-banner .heading{\r\n    font-family: Poppins;\r\n    font-size: 35px;\r\n    font-weight: 900;\r\n    line-height: 40px;\r\n    letter-spacing: 0em;\r\n    text-align: left;\r\n    \r\n  }\r\n\r\n  .wishlist-banner .subheading{\r\n    color: #777;\r\n  }", "",{"version":3,"sources":["webpack://./src/user-panel/pages/Wishlist/Wishlist.css"],"names":[],"mappings":"AAAA;IACI,qEAAwE;EAC1E;;EAEA;IACE,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;;EAElB;;EAEA;IACE,WAAW;EACb","sourcesContent":[".wishlist-banner{\r\n    background-image: url(\"/public/images/Orders/banner-bg.png\") !important ;\r\n  }\r\n\r\n  .wishlist-banner .heading{\r\n    font-family: Poppins;\r\n    font-size: 35px;\r\n    font-weight: 900;\r\n    line-height: 40px;\r\n    letter-spacing: 0em;\r\n    text-align: left;\r\n    \r\n  }\r\n\r\n  .wishlist-banner .subheading{\r\n    color: #777;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
