import React from "react";
import { useEffect, useState, useContext } from "react";
import $ from 'jquery';

import Slider from "react-slick";
import ZoomImage from "../../common-component/ZoomImage/ZoomImage"

//import ZoomImage from "../common-componets/ZoomImage"
import { UserContext } from '../../../authentication/pages/UserContext';

//import StarRatingComponent from 'react-star-rating-component';
import { useNavigate, Link } from 'react-router-dom';

import "./ProductView.css";

//import BuyNowDeliveryTypePopup from "../common-componets/BuyNowDeliveryTypePopup"

import API from '../../../API';
import LoadingSpinner from "../../../LoadingSpinner";
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner";
import AddToCartTripSelectionPopup from '../../common-component/AddToCartTripSelectionPopup'

import LoginPopup from "../../common-component/LoginPopup/LoginPopup"
import Empty from "../../../Empty"
import { responsiveFontSizes } from "@mui/material";

const ProductView = ({ loadUserData }) => {


   let navigate = useNavigate();
   const { isLoggedIn, login, logout } = useContext(UserContext);



   const [data, setData] = useState(null);
   const [ratingData, setRatingData] = useState(null);

   const [estimatedDeliveryDate, setEstimatedDeliveryDate] = useState(null)

   const [units, setUnits] = useState();
   const [idSelected, setIdSelected] = useState(null);

   const [message, setMessage] = useState(null);
   const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
   const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const [categories, setCategories] = useState(null);
   const [brands, setBrands] = useState(null);


   const [relatedProducts, setRelatedProducts] = useState([])
   const [recentlyViewed, setRecentlyViewed] = useState([])

   const [variantSelected, selectVariant] = useState(0)


   const [isLoginPopupShown, showLoginPopup] = useState(false)


   const [isAddToCartTripListPopupShown, showAddToCartTripListPopup] = useState(false);

   const [isZoomImgOpen, openZoomImg] = useState(false)

   const [reviews, setReviews] = useState(null)

   useEffect(() => {
      $(function () {
         $(window).scrollTop(0);
      });
   }, [])

   useEffect(() => {
      window.scrollTo(0, 0)
   }, [data])


   const loadData = () => {
      setIsLoading(true)
      API.get(`/products/${localStorage.getItem("productId")}`)
         .then(response => {
            API.get("/units")
               .then((units) => {
                  let product = response.data

                  let temp = units.data.results.find(x => x.id == product.item_unit);

                  product.item_unit = temp.unit_name
                  setData(product);
                  setIsLoading(false)
               })
               .catch((error) => {
                  console.error(error);
                  setIsLoading(false)
               });

         })
         .catch(error => {
            console.error(error);
         });


   }
   useEffect(() => {

      window.localStorage.setItem("buyMode", null);
      loadData()

      API.get('/categories')
         .then(response => {
            setCategories(response.data);
         })
         .catch(error => {
            console.log("categories error")
            setMessage(error.message);
            //setIsErrorModalOpen(true);

         });

      API.get('/brands')
         .then(response => {
            setBrands(response.data);
         })
         .catch(error => {
            console.log("brands error")
            setMessage(error.message);
            // setIsErrorModalOpen(true);
         });
      setUnits([])
      API.get(`/units/`)
         .then(response => {
            setUnits(response.data)
         })
         .catch(error => {

            console.error(error);
         });

      API.get(`/reviews/?product=${localStorage.getItem("productId")}`)
         .then(response => {
            setReviews(response.data)
         })
         .catch(error => {

            console.error(error);
         });


   }
      , []);



   const getUnitName = (unitId) => {
      if (units && units.length > 0) {
         let temp = units && units.find(x => x.id === unitId);
         if (temp) {
            return (temp.unit_name)
         }
         else {
            return ""
         }
      }
      else {
         return ""
      }
   }
   useEffect(() => {
      if (categories && brands) {


         setRelatedProducts([])
         API.get(`/related-products/${localStorage.getItem("productId")}/`)
            .then(response => {
               setRelatedProducts(response.data)
            })
            .catch(error => {
               console.error(error);
            });


         setRecentlyViewed([])
         API.get(`/recently-viewed/${window.localStorage.getItem("userID")}/`)
            .then(response => {
               setRecentlyViewed(response.data)
            })
            .catch(error => {
               console.error(error);
            });
      }
   }, [categories, brands])


   const [activeImgIndex, setActiveImgIndex] = useState(0);
   const [productCount, setProductCount] = useState(1);


   const otherImageClicked = (index) => {
      setActiveImgIndex(index)
   }






   const qtyUp = () => {
      var temp = productCount;
      temp = temp + 1
      setProductCount(temp)
   }

   const qtyDown = () => {

      if (productCount > 1) {
         var temp = productCount;
         temp = temp - 1
         setProductCount(temp)
      }
   }




   const [tabSelected, setTabSelected] = useState(0);


   useEffect(() => {
      $(".tab-btn").removeClass("active");
      $(".tab-btn").eq(tabSelected).addClass("active");
      //$(".tab-btn").eq(tabSelected + 2).addClass("active");



   }, [tabSelected])


   function toggleTab(index) {
      setTabSelected(index);

   }




   const handleBuyNow = (deliveryType) => {
      if (isLoggedIn) {
         API.post(`/buy_now/${localStorage.getItem("productId")}/${variantSelected}/${productCount}/`)  ///buy_now/<userID>/<product id>/variant/count/
            .then(response => {
               // Handle the response
               setMessage("Item Added to Buy now queue Successfully!")
               setIsMessageModalOpen(true)
               window.localStorage.setItem("buyMode", "buy-now");

               if (deliveryType === "click-n-collect") {
                  navigate("/click-and-collect-checkout")
               }
               else {
                  navigate("/checkout")
               }
            })
            .catch(error => {
               // Handle the error
               setMessage(error.response.data.message)
               setIsErrorModalOpen(true)
            });
      }
      else {

         showLoginPopup(true)
      }

   }






   const checkStock = () => {
      let isInStock = false;


      if (variantSelected === 0) {
         if (data.stock >= productCount) {
            isInStock = true
         }
         else {
            isInStock = false;
         }
      }

      else if (variantSelected === 1) {
         if (data.variant1_stock >= productCount) {
            isInStock = true
         }
         else {
            isInStock = false;
         }
      }
      else if (variantSelected === 2) {
         if (data.variant2_stock >= productCount) {
            isInStock = true
         }
         else {
            isInStock = false;
         }
      }
      else if (variantSelected === 3) {
         if (data.variant3_stock > 0) {
            isInStock = true
         }
         else {
            isInStock = false;
         }
      }
      return isInStock;
   }

   const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      responsive: [{
         breakpoint: 991,
         settings: {
            slidesToShow: 3,
            slidesToScroll: 1
         }
      },
      {
         breakpoint: 600,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 2
         }
      }]
   };
   const ratingSettings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      responsive: [{
         breakpoint: 600,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1
         }
      }]
   };

   const addOrRemoveWishList = (ID, isWishlisted) => {
      if (isWishlisted) {
         setIsLoading(true)

         API.post(`/remove-from-wishlist/${localStorage.getItem("userID")}/${ID}/`)
            .then(response => {
               setMessage("Item Removed from your Wishlist successfully");
               setIsMessageModalOpen(true)
               setIsLoading(false)
               loadData()
            })
            .catch(error => {
               setMessage(error.message);
               setIsErrorModalOpen(true);
               setIsLoading(false)
            });

      }
      else {
         if (isLoggedIn) {
            console.log("id from add to wishlist", ID)
            setIsLoading(true)
            API.post(`users/${window.localStorage.getItem("userID")}/products/${ID}/add-to-wishlist/`)
               .then(response => {
                  setMessage("Item added to your wishlist successfully");
                  setIsMessageModalOpen(true)
                  setIsLoading(false)
                  loadData()
               })
               .catch(error => {
                  setMessage(error.response.data.message)
                  setIsErrorModalOpen(true);
                  setIsLoading(false)
               });
         }
         else {
            setMessage("Please login to add your favorite items to list!!")
            setIsErrorModalOpen(true)
         }
      }
   }
   const checkPostCode = () => {
      setIsLoading(true)
      API.get(`/check_post_code_availability/${$("#postcodeCheckInput").val()}/${localStorage.getItem("productId")}/`)
         .then(response => {
            setIsLoading(false)
            console.log("dates", response.data.dates)
            if (response.data.dates.length === 1) {
               setMessage("No scheduled deliveries at this location. But you can order this product as Pre order to get it on the very next delivery.")
            }
            else if (response.data.dates.filter(item => item === "Today").length > 0) {
               setMessage("Hurray!! This Product is available at the entered location for today itself. Order soon before stock ends.")

            }
            else {
               setMessage(`This Product is available at this location for the following dates: ${response.data.dates[0]}, ${response.data.dates[1]}, ${response.data.dates[2]}, ${response.data.dates[3]},`)
            }
            setIsMessageModalOpen(true)
         })
         .catch(error => {
            setIsLoading(false)

         })
   }

   const likeOrDislikeReview = (id, code) => {
      API.post(`like_a_review/${localStorage.getItem("productId")}`)
         .then(

      )
   }

   return (

      <div className='view-prod-sec-1'>
         {data &&
            <div className='w-100 bg-white d-flex flex-column flex-lg-row box-shadow px-4 py-5 radius-11'>
               <div className='w-50-resp-mob me-5 main-image-box'>
                  {data.images.map(productimage => {
                     return productimage ? <img src={`https://sibufishnmeat.co.uk${productimage.url}`} alt='' className='addcartmeatsize'></img> : ""
                  })}
                  <button className="zoom" onClick={() => openZoomImg(true)}><img src="/images/zoom.svg"></img></button>

               </div>
               <div className='w-50-resp-mob'>
                  <div className='f-md fw-600 mb-3'>{data.name}
                     <span className='meatweight brick-clr f-13 mx-2 p-1'>{data.quantity}kg</span>
                     {data.is_wishlisted ?
                        <img src='/images/add-to-wishlist-icon-filled.svg' role='button' onClick={() => addOrRemoveWishList(data.id, data.is_wishlisted)}></img>
                        :
                        <img src='/images/add-to-wishlist-icon.svg' role='button' onClick={() => addOrRemoveWishList(data.id, data.is_wishlisted)} ></img>
                     }</div>
                  <div className='d-flex align-items-center mb-3'>
                     <div className='f-xs fw-600 me-2' role="button" data-toggle="modal" data-target="#exampleModalreview"><span className='fullborderblack p-1'>{data.rating}<i className="fa-solid fa-star lightbrick"></i></span><span className='fullborderblack p-1 leftnone'>1 ratings</span></div>
                     {/* <div className='f-xs fw-500 status green'>Out of Stock</div> */}
                  </div>
                  <form className='mb-3'>

                     <div className="qty-container mb-2">
                        <button className="qty-btn-minus btn-cornered me-2" type="button" onClick={qtyDown}><i className="fa fa-minus"></i></button>
                        <input type="text" className="input-qty input-cornered"
                           name='quantity'
                           value={productCount}
                           onChange={() => { }} />
                        <button className="qty-btn-plus  btn-cornered ms-2" type="button" onClick={qtyUp}><i className="fa fa-plus"></i></button>
                     </div>
                     <button className='f-13 px-5 mb-2 fw-500 lightbrick-btn me-2' type="button" onClick={() => { if (isLoggedIn) { setIdSelected(window.localStorage.getItem("productId")); showAddToCartTripListPopup(true) } else { showLoginPopup(true) } }}>Add to Bag</button>
                     {/* ----------------- */}

                     {/* ----------------- */}
                  </form>
                  <div className='mb-3'>
                     <div className='f-xs clr-898989 fw-500 mb-1'>Description</div>
                     <div className='f-xs clr-898989 fw-500 w-75'>
                        {data.description}                     </div>
                  </div>
                  <div>
                     <div className='f-xs fw-600'>DELIVERY OPTIONS</div>
                     <div className='relative w-50'>
                        <input className='inp-D9D9D9 w-100 f-13 pe-6 fw-500' id="postcodeCheckInput" maxLength={8} placeholder='Enter Postal Code'></input>
                        <div className='absolute f-xs fw-600 brick-clr position-middle-r' role="button" onClick={checkPostCode}>Check</div>
                     </div>
                     <div className='f-13 fw-500 mt-2'>Please enter Postal code to check delivery time & Pay on Delivery Availability</div>
                  </div>
               </div>
            </div>
         }


         {data && isZoomImgOpen &&
            <ZoomImage className="zoom-model" url={`https://sibufishnmeat.co.uk${data.images[activeImgIndex].url}`} closeZoom={() => openZoomImg(false)}></ZoomImage>
         }


         {relatedProducts && relatedProducts.length > 4 &&
            <div className='text-center ourbestdeal pt-75px'>
               <div className='black-clr f-xl fw-600 mb-5'>You May Also Like</div>


               <div className='w-100 pb-4 scroll-outer-box'>
                  <div className='scroll-box'>

                     <Slider ref={slider => (slider = slider)} {...settings}>

                        {relatedProducts.map((item, index) => {
                           return (
                              <div className='product-card' key={index}>
                                 <div className='prod-img-bg'>
                                    <img src={`https://sibufishnmeat.co.uk${item.images[0].url}`} alt='' onClick={() => { window.localStorage.setItem("productId", item.id); navigate("/view-product"); window.location.reload() }} role='button' />
                                    <div className='absolute heartposition'>
                                       {item.is_wishlisted ?
                                          <img src='/images/add-to-wishlist-icon-filled.svg' role='button' onClick={() => addOrRemoveWishList(item.id, item.is_wishlisted)}></img>
                                          :
                                          <img src='/images/add-to-wishlist-icon.svg' role='button' onClick={() => addOrRemoveWishList(item.id, item.is_wishlisted)} ></img>
                                       }
                                    </div>
                                    <div className='absolute halal'>
                                       {item.halal &&
                                          <img src='/images/halal-logo.svg' role='button'></img>
                                       }
                                    </div>
                                 </div>
                                 <div className='prod-name'>{item.name}</div>
                                 {/* <div className='prod-desc'>ബീഫ് ഹൃദയം</div> */}
                                 <div className='bg-f8f8f8 mt-1 mb-2 py-2'>
                                    <div className='brick-clr fw-700 f-14'>£{item.price} <span className=''>(£{Math.round(((item.price / item.quantity) + Number.EPSILON) * 100) / 100}/kg)</span></div>
                                 </div>
                                 <div className='d-flex justify-content-center fontsize'>
                                    <Link className='lightbrick-btn underline-none px-3 py-1 linkbtn h-0' onClick={() => { if (isLoggedIn) { setIdSelected(item.id); showAddToCartTripListPopup(true) } else { showLoginPopup(true) } }}>Add to bag</Link>
                                    <div className='prod-kilo'>{item.quantity}Kg</div>
                                 </div>
                                 <div></div>
                              </div>
                           )
                        })}







                     </Slider>

                  </div>
               </div>
            </div>
         }

         {recentlyViewed && recentlyViewed.results && recentlyViewed.results.length > 4 &&
            <div className='text-center ourbestdeal pt-75px'>
               <div className='black-clr f-xl fw-600 mb-5'>Recently Viewed</div>


               <div className='w-100 pb-4 scroll-outer-box'>
                  <div className='scroll-box'>

                     <Slider ref={slider => (slider = slider)} {...settings}>

                        {recentlyViewed.results.map((item, index) => {
                           return (
                              <div className='product-card' key={index}>
                                 <div className='prod-img-bg'>
                                    <img src={`https://sibufishnmeat.co.uk${item.images[0].url}`} alt='' onClick={() => { window.localStorage.setItem("productId", item.id); navigate("/view-product"); window.location.reload() }} role='button' />
                                    <div className='absolute heartposition'>
                                       {item.is_wishlisted ?
                                          <img src='/images/add-to-wishlist-icon-filled.svg' role='button' onClick={() => addOrRemoveWishList(item.id, item.is_wishlisted)}></img>
                                          :
                                          <img src='/images/add-to-wishlist-icon.svg' role='button' onClick={() => addOrRemoveWishList(item.id, item.is_wishlisted)} ></img>
                                       }
                                    </div>
                                 </div>
                                 <div className='prod-name'>{item.name}</div>
                                 {/* <div className='prod-desc'>ബീഫ് ഹൃദയം</div> */}
                                 <div className='bg-f8f8f8 mt-1 mb-2 py-2'>
                                    <div className='brick-clr fw-700 f-14'>£{item.price} <span className=''>(£{Math.round(((item.price / item.quantity) + Number.EPSILON) * 100) / 100}/kg)</span></div>
                                 </div>
                                 <div className='d-flex justify-content-center fontsize'>
                                    <Link className='lightbrick-btn underline-none px-3 py-1 linkbtn h-0' onClick={() => { if (isLoggedIn) { setIdSelected(item.id); showAddToCartTripListPopup(true) } else { showLoginPopup(true) } }}>Add to bag</Link>
                                    <div className='prod-kilo'>{item.quantity}Kg</div>
                                 </div>

                              </div>
                           )
                        })}







                     </Slider>

                  </div>
               </div>
            </div>
         }

         {/* revies modal */}
         <div className="modal fade" id="exampleModalreview" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
               <div className="modal-content">

                  <div className="modal-body">
                     <div className='brick-clr fw-600 f-xs mb-3'>Ratings & reviews (43)</div>
                     <div className='bestdealcarousal reviewaddcart'>
                        <Slider ref={slider => (slider = slider)} {...ratingSettings}>

                           {reviews && reviews.results && reviews.results.length > 0 ? reviews.results.map((item, index) => {

                              return (
                                 <div className='px-3'>
                                    <div className='item p-3'>
                                       <img src={item.profile_image} alt='' className='rounduser mb-2'></img>
                                       <div className='f-13 mb-2 fw-500 text-justify'>
                                          {item.text}
                                       </div>
                                       <div>
                                          <button><i className="fa-regular fa-thumbs-up" onClick={() => likeOrDislikeReview(item.id, 0)}></i></button>
                                          <button><i className="fa-regular fa-thumbs-down" onClick={() => likeOrDislikeReview(item.id, 2)}></i></button>
                                       </div>
                                    </div>
                                 </div>
                              )

                           })
                              :
                              <div>No Reviews to show for this product.</div>}


                        </Slider>

                     </div>
                     <div>

                     </div>
                  </div>
               </div>
            </div>
         </div>


         {isLoginPopupShown && <LoginPopup redirectUrl={"/view-product"} showLoginPopup={showLoginPopup}></LoginPopup>}
         {isAddToCartTripListPopupShown && <AddToCartTripSelectionPopup productId={idSelected} productCount={productCount} setterFunction={showAddToCartTripListPopup} loadUserData={loadUserData}></AddToCartTripSelectionPopup>}
         {isLoading && <FixedOverlayLoadingSpinner />}
         {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

      </div>
   )

}

export default ProductView
