import React, { Component, useState, useEffect } from 'react'
import "../../common-components/AdminListPage.css"
import './ProductsList.css'
import API from "../../../API"
import { Link, Navigate, useNavigate } from 'react-router-dom'
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import DeleteConfirmModal from '../../../DeleteConfirmModal'
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"
import $ from 'jquery';

import Pagination from '../../../Pagination'
const Catalog = () => {
  const navigate = useNavigate()

  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    brand: null,
    category: null,
    subcategory: null,
    is_published: null,
    search_key: null
  });
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [DataisLoaded, setDataisLoaded] = useState(false);
  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isDeleteConfModalOpen, setIsDeleteConfModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [idSelected, setIdSelected] = useState(0);


  const [page, setPage] = useState(1);
  const [pageSize, selectPageSize] = useState(12);

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {
    loadTableData();
    getCategoryList()
  }, [page, pageSize, filters]);

  const deleteItem = () => {
    API.delete(`/products/${idSelected}`)
      .then(response => {
        setMessage("Item deleted successfully");
        setIsMessageModalOpen(true)
      })
      .catch(error => {
        setMessage(error.message);
        setIsErrorModalOpen(true);
      });
  }
  const loadTableData = () => {
    setData(null);
    setIsMessageModalOpen(false);

    let apiUrl = `/products/?page=${page}&page_size=${pageSize}`;

    // Loop through the filters object and append selected filters to the apiUrl
    for (let filter in filters) {
      if (filters[filter] !== null && filters[filter] !== "") {
        apiUrl += `&${filter}=${filters[filter]}`;
      }
    }
    setIsLoading(true)
    API.get(apiUrl)
      .then(response => {
        setData(response.data);
        setIsLoading(false)
      })
      .catch(error => {
        setMessage(error.message);
        setIsErrorModalOpen(true);
        setIsLoading(false)

      });
  }


  const getCategoryList = () => {
    API.get('/categories/')
      .then(response => {
        setCategories(response.data)
        setDataisLoaded(true)

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  const editItem = (idSelected) => {
    localStorage.setItem("itemSelectedId", idSelected);
    navigate("/admin/products/edit")
  }


  const publish = (id, index) => {
    if (!(data.results[index].images) || data.results[index].images.length == 0) {
        console.log("no images")
        setMessage("There is no images saved for this Product. Please add atleast one image to publish this product.")
        setIsErrorModalOpen(true)
    }
    else {
        let DATA = {
            "is_published": true,
           
        }
        setIsLoading(true);
        API.put(`/products/${id}/`, DATA)
            .then(response => {

                setIsLoading(false);

                setMessage("Product Published successfully");
                setIsMessageModalOpen(true)
            })
            .catch(error => {
                // Handle the error
                console.error('Error Publishing Product:', error);
                setIsLoading(false);
                setIsErrorModalOpen(true)
            });
    }

}

const unPublish = (id) => {

    let DATA = {
        "is_published": false,
        
    }
    setIsLoading(true);
    API.put(`/products/${id}/`, DATA)
        .then(response => {
            // Handle the response

            setIsLoading(false);

            setMessage("Product unpublished successfully");
            
            setIsMessageModalOpen(true)
        })
        .catch(error => {
            // Handle the error
            console.error('Error Unpublishing Product:', error);
            setIsLoading(false);
            setIsErrorModalOpen(true)
        });
}

  return (

    <div className='px-3 px-md-4 py-3 coupon catalog customer full-screen-table-container'>
      <div className='text-end d-flex justify-content-between'>
        {/* <button className='lightbrick-btn f-xs px-4'>Add Product</button> */}
        <h3 className=' mb-2 mb-md-0'>Products</h3>

        <button type='button' className='lightbrick-btn f-xs px-4 add-prod-link' onClick={() => { navigate("/admin/products/add") }}>New Catalog</button>

      </div>

      <div className='d-flex align-items-center my-3'>
        <div className='relative w-100'>
          <input className='nav-search-inp w-50 fw-600' placeholder='Search for products' id='search' onKeyUp={(e) => setFilters(prevFilters => ({
            ...prevFilters, search_key: e.target.value
          }))}></input>
          <div className='search-i-position'><i className="fa-solid fa-magnifying-glass"></i></div>
        </div>
        <select className='w-50 nav-search-inp ps-2' onChange={(e) => setFilters(prevFilters => ({
            ...prevFilters, is_published: e.target.value

          }))}>
          <option value="" label='All'></option>
          <option value={"True"} label='Published'></option>
          <option value={"False"} label='Drafts'></option>

        </select>
      </div>

      <div className='w-100 table-box'>
        <table className="rwd-table mb-2 catalog-table">
          <tbody id='table'>
            <tr>
              <th>PRODUCT ID</th>
              <th>NAME</th>
              <th>IMAGE</th>
              <th>CATEGORY</th>
              <th>STOCK</th>
              <th>PRICE</th>
              <th>PUBLISH</th>
              <th className='text-center'>MORE</th>
            </tr>
            {data && data.results && data.results.map((product,index) => (

              <tr key={index}>
                <td data-th="Product ID" className='f-xs'>
                  {product.id}
                </td>
                <td data-th="Name" className='f-xs'>
                  {product.name}
                </td>
                <td data-th="Image">
                  {product.images[0] &&
                    <img src={`https://sibufishnmeat.co.uk${product.images[0].url}`} alt=''></img>}




                </td>
                <td data-th="Category" className='f-xs'>

                  {categories && categories.results && categories.results.map(category => {
                    return category.id == product.category ? <td>{category.category_name}</td> : ""


                  })}


                </td>
                <td data-th="Stock">
                  <div className='status-btn status-btn-green f-12 fw-600'>In Stock</div>

                </td>
                <td data-th="Price" className='f-xs'>
                  £{product.price}

                </td>

                <td data-th="Publish" className='f-xs'><button className={product.is_published?"unpublish-button":"publish-button"} onClick={()=>{if(product.is_published){unPublish(product.id)} else{publish(product.id, index)}}}>{product.is_published?"UNPUBLISH":" PUBLISH"}</button></td>

                <td data-th="More">
                  <div className='action-btn-box'>
                    <button onClick={() => { editItem(product.id) }}><img src='/images/admin-panel/table-edit-icon.svg' /></button>
                    <button onClick={() => { setIdSelected(product.id); setIsDeleteConfModalOpen(true) }}><img src='/images/admin-panel/table-delete-icon.svg' /></button>
                  </div>
                </td>

              </tr>

            ))}




          </tbody>
        </table>
        {data && data.results && data.results.length > 0 &&
          <Pagination
            totalItems={data.count}
            pageSize={pageSize}
            selectPageSize={selectPageSize}
            currentPage={page}
            setCurrentPage={setPage}>
          </Pagination>
        }
      </div>

      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={loadTableData} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={loadTableData} />}
      {isDeleteConfModalOpen && <DeleteConfirmModal resourceName={'product'} setterFunction={setIsDeleteConfModalOpen} onDeleteFunction={deleteItem}></DeleteConfirmModal>}
      {isLoading && <FixedOverlayLoadingSpinner />}


    </div>

  )

}

export default Catalog