import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import API from "../../../API"
import { convertTo12HourTime } from '../../../GeneralFunctions'
import { useNavigate } from 'react-router-dom';

import './UpcomingDeliveries.css'
import $ from 'jquery';

const View = ({ setTripwiseAvailability }) => {
  $(function () {
    $(window).scrollTop(0);
  });

  let navigate = useNavigate();


  const [upcomingTrips, setUpcomingTrips] = useState(null)
  useEffect(() => {
    loadUpcomingTrips();

  }, [])

  const loadUpcomingTrips = () => {
    setUpcomingTrips([])
    let url;

    API.get('/upcoming_trips/')
      .then(response => {
        setUpcomingTrips(response.data)
      })
      .catch(error => {
        console.error(error);
      });
  }

  return (











    <div className='container privacy-policy-sec-1'>
      <div className="row">
        <div className="col-12 col-lg-3">
          <div className="text-start fw-600 f-22"><Link to="/"></Link>Upcoming Deliveries</div>
        </div>
      </div>



      <div className=" f-xs">

        {upcomingTrips && upcomingTrips.results && upcomingTrips.results.length > 0 ? upcomingTrips.results.map((item, index) => {
          return (
            <div key={index} className={'p-4 box-shadow bg-lightbrick radius-11 mb-3 mt-4 d-block d-md-flex'}>


              <div className="d-flex flex-column align-items-center w-100 w-md-40 lhs py-3" >
                <div className='bg-lightbrick location mb-4'>
                  <span className="material-symbols-outlined white-clr" style={{ fontSize: "50px" }}>
                    distance
                  </span>
                </div>

                <div className='fw-500 f-22 w-500 white-clr mb-1'>
                  {item.route_name}
                </div>
                <div className='fw-500 f-14 w-500 white-clr mb-1'>
                  County:  {item.location_name}
                </div>
                <div className='fw-500 f-14 white-clr mb-5'>
                  {item.delivery_date}
                </div>
                <div className="d-flex f-14 white-clr">Delivery Time:&nbsp; {convertTo12HourTime(item.trip_start_time)}&nbsp;to&nbsp;{convertTo12HourTime(item.trip_end_time)}</div>



              </div>

              <div className='w-100 w-md-60 px-5 py-3   d-flex flex-column justify-content-between'>

                <div className='fw-500 f-20 white-clr mb-1'>
                  Post Codes List
                </div>
                <div className="postcodes-container d-flex justify-content-start px-3 mt-5" style={{ height: "150px", flexWrap: "wrap", overflowY: "scroll" }}>
                  {item && item.postcodes.map((postcode, index) => {
                    return (
                      <div className='fw-500 f-14 black-clr  mb-1 me-3 px-2 py-1 radius-7' style={{ height: "fit-content", backgroundColor: "#FFDEE1" }}>{postcode}&nbsp;</div>
                    )
                  })}
                </div>

                <button type='button' className='lightbrick bg-white radius-7 px-3 py-2 mt-4 fw-500 ' style={{ width: "fit-content" }} onClick={() => { setTripwiseAvailability(item.trip_id); navigate('/shop') }}>Book Your Items</button>

              </div>

            </div>
          );
        })

          :
          <div className="w-100 py-5 px-5 d-flex justify-content-center align-items-center">
            <div className="black-clr f-lg text-center">No upcoming Deliveries Currently.<br/>
            We are working to list out the best deliveries for you.</div>
          </div>
        }










      </div>




    </div>
  )
}

export default View;